import { ComponentDefinition } from "grapesjs";

export const readOnlyComponentDefinition: ComponentDefinition = {
  removable: false,
  draggable: false,
  copyable: false,
};

export const fixedComponentDefinition: ComponentDefinition = {
  ...readOnlyComponentDefinition,
  droppable: false,
};

export const staticBlockConfig: ComponentDefinition = {
  ...fixedComponentDefinition,
  editable: false,
};

export const noToolbarBlockConfig: ComponentDefinition = {
  ...readOnlyComponentDefinition,
  toolbar: false, // TODO Check if it works
};

const smartComponentsClass = "sc";
export const smartComponentFormClass = `${smartComponentsClass}__form`;
export const smartComponentFormItemClass = `${smartComponentFormClass}-item`;

export const smartComponentNavbarClass = `${smartComponentsClass}__navbar`;
export const smartComponentNavbarContentClass = `${smartComponentNavbarClass}-content`;
export const smartComponentNavbarWrapperClass = `${smartComponentNavbarClass}-wrapper`;
export const smartComponentNavbarLogoWrapperClass = `${smartComponentNavbarClass}-logo-wrapper`;
export const smartComponentNavbarLogoClass = `${smartComponentNavbarClass}-logo`;
export const smartComponentNavbarLinkClass = `${smartComponentNavbarClass}-link`;

export const smartComponentNavbarMobileCloseClass = `${smartComponentNavbarClass}-mobile-close`;
export const smartComponentNavbarMobileOpenClass = `${smartComponentNavbarClass}-mobile-open`;

export const smartComponentNavbarNavClass = `${smartComponentNavbarClass}-nav`;

export const smartComponentNavbarMenuListClass = `${smartComponentNavbarClass}-menu-list`;
export const smartComponentNavbarMenuItemClass = `${smartComponentNavbarClass}-menu-item`;
export const smartComponentNavbarMenuLinkClass = `${smartComponentNavbarClass}-menu-link`;

export const smartComponentNavbarCTAClass = `${smartComponentNavbarClass}-cta`;
export const smartComponentNavbarCTAContactUSClass = `${smartComponentNavbarCTAClass}-contact-us`;
export const smartComponentNavbarCTAContactUSTitleClass = `${smartComponentNavbarCTAContactUSClass}-title`;
export const smartComponentNavbarCTAContactUSLinkClass = `${smartComponentNavbarCTAContactUSClass}-link`;
