import { BlockProperties } from "grapesjs";

export const smartComponentGallery: BlockProperties = {
  id: "SmartGallery",
  media: `<svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M0 30C0 28.8954 0.895431 28 2 28H38C39.1046 28 40 28.8954 40 30V34C40 35.1046 39.1046 36 38 36H2C0.895431 36 0 35.1046 0 34V30Z" fill="#D4D4D8"/><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V8C39.5 8.82843 38.8284 9.5 38 9.5H2C1.17157 9.5 0.5 8.82843 0.5 8V2Z" fill="#27272A" stroke="#71717A"/><path d="M0.5 16C0.5 15.1716 1.17157 14.5 2 14.5H38C38.8284 14.5 39.5 15.1716 39.5 16V22C39.5 22.8284 38.8284 23.5 38 23.5H2C1.17157 23.5 0.5 22.8284 0.5 22V16Z" fill="#27272A" stroke="#71717A"/></svg>`,
  label: "Gallery",
  category: "Layout",
  content: {
    type: "smartGallery",
    style: {
      display: "flex",
      "flex-direction": "column",
      gap: "24px",
      width: "576px",
      backgroundColor: "#F7F7F8",
    },
    components: [],
  },
  select: true,
};
