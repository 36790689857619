import { useEffect } from "react";

const META_NAME = "viewport";

/**
 * Custom hook to manage the viewport meta tag in the document head.
 *
 * This hook updates the content of the viewport meta tag with the provided content
 * and restores the previous content when the component unmounts.
 *
 * @param {string} content - The content to set for the viewport meta tag.
 *
 * @example
 * useViewportMeta("width=device-width, initial-scale=1");
 */
export const useViewportMeta = (content: string) => {
  useEffect(() => {
    const metaSelector = `meta[name="${META_NAME}"]`;
    let meta = document.querySelector<HTMLMetaElement>(metaSelector);
    const previousContent = meta?.getAttribute("content") || "";

    if (meta) {
      meta.setAttribute("content", content);
    } else {
      meta = document.createElement("meta");
      meta.setAttribute("name", META_NAME);
      meta.setAttribute("content", content);
      document.head.appendChild(meta);
    }

    return () => {
      if (previousContent) {
        meta.setAttribute("content", previousContent);
      } else {
        document.head.removeChild(meta);
      }
    };
  }, [content]);
};
