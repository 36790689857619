import { BlockProperties } from "grapesjs";

export const link: BlockProperties = {
  id: "link",
  media: `<svg width="31" height="15" viewBox="0 0 31 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6667 1H23.6667C24.5421 1 25.4091 1.17244 26.2179 1.50747C27.0267 1.8425 27.7617 2.33356 28.3807 2.95262C28.9998 3.57168 29.4908 4.30661 29.8259 5.11544C30.1609 5.92428 30.3333 6.79119 30.3333 7.66667C30.3333 8.54215 30.1609 9.40905 29.8259 10.2179C29.4908 11.0267 28.9998 11.7617 28.3807 12.3807C27.7617 12.9998 27.0267 13.4908 26.2179 13.8259C25.4091 14.1609 24.5421 14.3333 23.6667 14.3333H19.6667M11.6667 14.3333H7.66667C6.79119 14.3333 5.92428 14.1609 5.11544 13.8259C4.30661 13.4908 3.57168 12.9998 2.95262 12.3807C1.70238 11.1305 1 9.43478 1 7.66667C1 5.89856 1.70238 4.20286 2.95262 2.95262C4.20286 1.70238 5.89856 1 7.66667 1H11.6667M10.334 7.6665H21.0007" stroke="#D4D4D8" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  label: "Link",
  category: "Layout",
  content: {
    type: "link",
    traits: ["id"],
    attributes: { href: "https://www.codejet.ai/" },
  },
  select: true,
};
