export const extractAllIdStyles = (css: string) => {
  let idSelectors = "";
  let styles = css;
  const mediaQueries: Record<string, string> = {};

  // Extract media queries
  const mediaQueryRegex = /@media\s*[^{]*\{([^}]*\{[^}]*\})*[^}]*\}/g;
  let match;
  while ((match = mediaQueryRegex.exec(styles)) !== null) {
    const key = match[0].match(/@media[^{]+/)?.[0];
    if (!key) continue;

    // Store the entire content inside the media query
    mediaQueries[key] = match[0].replace(key + "{", "").slice(0, -1);
    styles = styles.replace(match[0], "");
    mediaQueryRegex.lastIndex = 0; // Reset regex index to handle overlapping matches
  }

  // Extract id selectors
  idSelectors = extractIdStyles(styles);
  // Extract id selectors from media queries
  Object.entries(mediaQueries).forEach(([key, value]) => {
    const mediaIdSelectors = extractIdStyles(value);
    if (mediaIdSelectors === "") return;
    idSelectors += `${key}{${mediaIdSelectors}}`;
  });

  return idSelectors.trim();
};

const extractIdStyles = (css: string) => {
  let idSelectors = "";
  let styles = css;

  // Extract id selectors
  const idRegex = /#[a-zA-Z0-9_-]+\s*\{[^{}]*\}/g;
  let idMatch;
  while ((idMatch = idRegex.exec(styles)) !== null) {
    idSelectors += idMatch[0] + "\n";
    styles = styles.replace(idMatch[0], "");
    idRegex.lastIndex = 0; // Reset regex index to handle overlapping matches
  }

  return idSelectors;
};
