import { templatesService } from "../../../../../../../machines/templates/templates.instance";
import { Figma } from "../../../../../../../services/Figma";

import { Button } from "../../../../../../AlphaO/atoms/Button";

import { GoogleLogo } from "../../../../../../../../assets/svg/google_logo";
import { FigmaGray } from "../../../../../../../assets/svg/FigmaGray";

import { TSignUpContent } from "./SignUpContent.types";

import "./SignUpContent.css";

export const SignUpContent: TSignUpContent = () => {
  const handleSignUpButton = (type: "Google" | "Figma") => {
    try {
      const activeTemplateId = templatesService.getActiveProjectId();
      localStorage.setItem("silentRegisterTemplate", activeTemplateId);
    } catch {
      console.error("No active project or session storage is disabled");
    }

    if (type === "Google") {
      window.location.href = "/api/auth/google/login";
    }
    if (type === "Figma") {
      window.location.href = Figma.getCallbackUrl();
    }
  };
  return (
    <div className="sign-up__body">
      <Button type="big/primary" iconStart={<GoogleLogo />} onClick={() => handleSignUpButton("Google")}>
        Sign up with Google
      </Button>
      <div className="sign-up__separator">
        <div className="sign-up__separator-line"></div>
        <span className="sign-up__separator-or">or</span>
        <div className="sign-up__separator-line"></div>
      </div>
      <Button type="big/stroke" iconStart={<FigmaGray />} onClick={() => handleSignUpButton("Figma")}>
        Sign up with Figma
      </Button>
    </div>
  );
};
