import { fixedComponentDefinition } from "../block-helpers";

import type { CSSStyle } from "../../../../../../../types";
import type { ComponentDefinition } from "grapesjs";

type CrateInputComponentAttrs = {
  id: string;
  name: string;
  value?: string;
  required?: boolean;
  styles?: CSSStyle;
  attributes?: ComponentDefinition["attributes"];
};

type CreateInputComponent = (attrs: CrateInputComponentAttrs) => ComponentDefinition;

export const createCheckboxComponent: CreateInputComponent = ({
  id,
  name,
  value = "true",
  required = false,
  styles = {},
  attributes = {},
}) => {
  return {
    type: "checkbox",
    ...fixedComponentDefinition,
    attributes: {
      id,
      name,
      value,
      ...(required ? { required: "true" } : {}),
      ...attributes,
    },
    style: {
      ...styles,
    },
  };
};
