/**
 * Detects if the current operating system is macOS.
 *
 * This function checks the user agent string in a browser environment
 * or the platform property in a Node.js environment to determine if
 * the operating system is macOS.
 *
 * @returns {boolean} `true` if the operating system is macOS, otherwise `false`.
 */
export function detectMacOS(): boolean {
  if (typeof navigator !== "undefined" && navigator.userAgent) {
    // Środowisko przeglądarki
    return /Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.userAgent);
  } else if (typeof process !== "undefined" && process.platform) {
    // Środowisko Node.js
    return process.platform === "darwin";
  }
  // Inne środowiska
  return false;
}
