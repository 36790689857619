import { StrictMode, useEffect, useState, type ReactNode } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
// import * as Sentry from "@sentry/react"; // TODO - Uncomment this line when Sentry is enabled
//import anayltics posthog
import "./services/analytics";

import { Seo } from "./components/atoms/Seo/Seo";

import { UserService } from "./services/UserService/UserService.ts";

import { unauthorizedTemplatesInstance } from "./machines/unauthorized-templates/unauthorized-templates.instance.ts";
import { templatesInstance } from "./machines/templates/templates.instance.ts";
import { productsInstance } from "./machines/products/products.instance.ts";
import { authInstance } from "./machines/auth/auth.instance.ts";
import { routerInstance } from "./machines/router/router.instance.ts";
import { projectsInstance } from "./machines/projects/projects.instance.ts";
import { dashboardInstance } from "./machines/dashboard/dashboard.instance";
import { createUserFormInstance } from "./machines/create-user-form/create-user-form.instance.ts";

import "./index.css";
import "./reset.css";
import "react-toastify/dist/ReactToastify.css";
import { sendPageViewNotification } from "./services/analytics";
// import { debuggerInstance } from "./services/Debbuger";

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,

//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,

//   integrations: [
//     new Sentry.Replay({
//       // Additional SDK configuration goes in here, for example:
//       maskAllInputs: true,
//       blockAllMedia: true,
//     }),
//   ],
// }); // TODO - Uncomment this line when Sentry is enabled

dashboardInstance.start();
routerInstance.start();
authInstance.start();
projectsInstance.start();
templatesInstance.start();
unauthorizedTemplatesInstance.start();
productsInstance.start();

export const Log = () => {
  const [info, setInfo] = useState<Record<string, string>>({});
  useEffect(() => {
    dashboardInstance.onTransition((state) => {
      setInfo((prev) => ({ ...prev, dashboard: JSON.stringify(state.value) }));
    });
    routerInstance.onTransition((state) => {
      setInfo((prev) => ({ ...prev, router: JSON.stringify(state.value) }));
    });
    authInstance.onTransition((state) => {
      setInfo((prev) => ({ ...prev, auth: JSON.stringify(state.value) }));
    });
    createUserFormInstance.onTransition((state) => {
      setInfo((prev) => ({ ...prev, createUserForm: JSON.stringify(state.value) }));
    });
    projectsInstance.onTransition((state) => {
      setInfo((prev) => ({ ...prev, projects: JSON.stringify(state.value) }));
    });
    templatesInstance.onTransition((state) => {
      setInfo((prev) => ({ ...prev, templates: JSON.stringify(state.value) }));
    });

    // assetManagerInstance.onTransition((state) => {
    //   setInfo((prev) => ({ ...prev, projects: JSON.stringify(state.value) }));
    // });
    // modalMachineInstance.onTransition((state) => {
    //   setInfo((prev) => ({ ...prev, projects: JSON.stringify(state.value) }));
    // });
  }, []);

  return (
    <>
      {Object.entries(info).map(([name, el]) => (
        <div style={{ color: "black" }} key={name}>
          {name} {el}
        </div>
      ))}
    </>
  );
};

export type RouterMeta = {
  page: {
    component: ReactNode;
    path: string;
  };
};

const userService = UserService.getInstance();

const MyRouter = () => {
  const [component, setComponent] = useState<RouterMeta>();

  useEffect(() => {
    // debuggerInstance.init();
  }, []);

  useEffect(() => {
    routerInstance.onTransition((state) => {
      const values = Object.values(state.meta);
      if (values.length > 0) {
        setComponent(values[0] as RouterMeta);
      }
    });

    if (window.location.pathname.includes("assign-figma-id")) {
      const urlParams = new URLSearchParams(window.location.search);
      const figmaId = urlParams.get("figmaId");
      const figmaEmail = urlParams.get("figmaEmail");

      if (figmaId && figmaEmail) {
        userService.setFigmaInfo({ figmaId, figmaEmail });
      }
    }

    if (window.location.pathname.includes("figma-plugin")) {
      localStorage.setItem("figmaFlow", "true");
      authInstance.send("UNAUTHENTICATED");
    }

    if (window.location.pathname.includes("verify")) {
      authInstance.send("OAUTH");
    }

    if (
      !(
        location.pathname.includes("figma-plugin") ||
        location.pathname.includes("verify") ||
        location.pathname.includes("loading") ||
        location.pathname.includes("createAccount")
      )
    ) {
      localStorage.removeItem("figmaFlow");
    }
  }, []);

  if (component) {
    history.pushState(null, "", component.page.path);
    sendPageViewNotification();
    return component.page.component;
  }

  return null;
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <HelmetProvider>
      <Seo title="Codejet" />
      {/* <Log /> */}
      <MyRouter />
      <ToastContainer />
    </HelmetProvider>
  </StrictMode>
);
