export const componentsSet = [
  "Anchor",
  "Button",
  "Checkbox",
  "H1",
  "H2",
  "H3",
  "H4",
  "H5",
  "H6",
  "Input",
  "Label",
  "Paragraph",
  "Radio",
  "Range",
  "SimpleInput",
  "TABLE",
  "TBODY",
  "TD",
  "TEXT",
  "TFOOT",
  "TH",
  "THEAD",
  "TR",
  // "Table",
  "icon",
];

export const htmlCodejetComponents = [
  "WISE_H1",
  "WISE_H2",
  "WISE_H3",
  "WISE_H4",
  "WISE_H5",
  "WISE_H6",
  "WISE_Subtitle_L",
  "WISE_Subtitle_S",
  "WISE_Body_XL",
  "WISE_Body_L",
  "WISE_Body_M",
  "WISE_Body_S",
  "WISE_Label_L",
  "WISE_Label_S",
  "WISE_Caption",
  "WISE_Checkbox",
  "WISE_Radio",
  "WISE_Button",
  "WISE_Tab_Label",
  "WISE_Input_text_filled",
  "WISE_Input",
  "WISE_Input_Label_text",
  "WISE_Input_Status",
  "WISE_Hint_text_L",
  "WISE_Hint_text_S ",
  "WISE_H1_MOBILE",
  "WISE_H2_MOBILE",
  "WISE_H3_MOBILE",
  "WISE_H4_MOBILE",
  "WISE_H5_MOBILE",
  "WISE_H6_MOBILE",
  "WISE_Subtitle_L_MOBILE",
  "WISE_Subtitle_S_MOBILE",
  "WISE_Body_XL_MOBILE",
  "WISE_Body_L_MOBILE",
  "WISE_Body_M_MOBILE",
  "WISE_Body_S_MOBILE",
  "WISE_Label_L_MOBILE",
  "WISE_Label_S_MOBILE",
  "WISE_Caption_MOBILE",
  "WISE_Button_text",
  "WISE_Button_text_MOBILE",
  "ELLIPSE",
  "RECTANGLE",
  "REGULAR_POLYGON",
  "STAR",
  "LINE",
  "VECTOR",
  "H1",
  "H2",
  "H3",
  "H4",
  "H5",
  "H6",
  "Anchor",
  "Button",
  "Checkbox",
  "Input",
  "Radio",
  "Range",
  "Label",
  "Paragraph",
  "TEXT",
  "TH",
  "TD",
  "WISE_Cell",
];

export const htmlNamedFrames = [
  "AccordionContent",
  "AccordionHeader",
  "WISE_Accordion",
  "WISE_Checkbox_Label",
  "WISE_Radio_Label",
  "WISE_Tag",
  "TABLE",
  "THEAD",
  "TBODY",
  "TFOOT",
  "TR",
  // "GROUP", // TODO commented out because it is broking HTML structure
  "WISE_Alert",
  "WISE_BUTTON",
  "WISE_BUTTON_MOBILE",
  "WISE_Tooltip",
  "WISE_Chips",
  "WISE_Toggle",
  "WISE_Table",
  "WISE_Row",
];
