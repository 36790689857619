import { useState, type MouseEvent } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";
import { TemplateCardActions } from "./components/TemplateCardActions";
import { Modal } from "./components/Modal";

import type { TTemplateCard } from "./TemplateCard.types";

import "./TemplateCard.css";

export const TemplateCard: TTemplateCard = ({
  name,
  thumbnail,
  onClick,
  onEdit,
  onDuplicate,
  isLoading: isLoadingProp = false,
  skeleton = false,
  style,
}) => {
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(isLoadingProp);

  const menuRef = useOutsideClick<HTMLDivElement>(() => {
    setIsMenuOpen(false);
  });

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick && onClick();
  };

  const handleOnClickKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // need here so confirm modal doesn't try open project
    if (isDuplicateModalOpen) return;
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onClick && onClick();
    }
  };

  const handleOpenDuplicateModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDuplicateModalOpen(true);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsMenuOpen((prev) => !prev);
  };

  const handleOpenMenuKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setIsMenuOpen((prev) => !prev);
    }
  };

  const handleOnDuplicate = async () => {
    if (onDuplicate !== undefined) {
      setIsLoading(true);
      await onDuplicate();
      // setIsLoading(false);
      // setIsDuplicateModalOpen(false);
    }
  };

  const handleOnEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEdit && onEdit();
  };

  const handleCloseModal = () => {
    setIsDuplicateModalOpen(false);
  };

  return (
    <>
      <div
        className={"template-card" + (isMenuOpen ? " template-card--menu-open" : "")}
        tabIndex={0}
        role="button"
        onClick={handleOnClick}
        onKeyDown={handleOnClickKey}
        style={style}
      >
        {skeleton ? (
          <div className="template-card__thumbnail skeleton-thumbnail skeleton" />
        ) : (
          <div className="template-card__thumbnail">
            <img className="template-card__thumbnail-img" src={thumbnail} loading="lazy" alt="project thumbnail" />
          </div>
        )}
        <div className="template-card__info">
          {skeleton ? (
            <div className="template-card__typography">
              <div className="skeleton skeleton-text" />
              <div className="skeleton skeleton-text" />
            </div>
          ) : (
            <div className="template-card__typography">
              <div className="template-card__title">{name}</div>
            </div>
          )}
          <TemplateCardActions
            isLoading={isLoading}
            isMenuOpen={isMenuOpen}
            isDuplicateModalOpen={isDuplicateModalOpen}
            handleOpenMenuKey={handleOpenMenuKey}
            handleOpenMenu={handleOpenMenu}
            handleOnEdit={handleOnEdit}
            handleOpenDuplicateModal={handleOpenDuplicateModal}
            ref={isMenuOpen ? menuRef : null}
          />
        </div>
      </div>
      {isDuplicateModalOpen && (
        <Modal
          title='Add this template to "My Projects"?'
          description='Are you sure you want to add this template to "My Projects"?'
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Add template"
          onClose={handleCloseModal}
          onClick={handleOnDuplicate}
          loaderText="We are adding your template to My Projects"
          isLoading={isLoading}
        />
      )}
    </>
  );
};
