import { useRef, useState } from "react";

import type { ReactNode } from "react";
import { PropertyLabel, SelectButton } from "../../styles-manager/components";
import { Button } from "../../../../../AlphaO/atoms/Button";

import { Composition } from "../../../../../../assets/svg/Composition";
import { Composition2 } from "../../../../../../assets/svg/Composition2";
import { sectorsConfig } from "../../styles-manager/sectors.config";
import { styleManagerService } from "../../styles-manager/StylesManager.service";

const options = [
  { id: "auto", value: "remove", name: "remove", label: "Auto" },
  { id: "none", value: "none", name: "None", label: "None" },
  { id: "solid", value: "solid", name: "Solid", label: "Solid" },
  { id: "dotted", value: "dotted", name: "Dotted", label: "Dotted" },
  { id: "dashed", value: "dashed", name: "Dashed", label: "Dashed" },
  { id: "double", value: "double", name: "Double", label: "Double" },
  { id: "groove", value: "groove", name: "Groove", label: "Groove" },
  { id: "ridge", value: "ridge", name: "Ridge", label: "Ridge" },
  { id: "inset", value: "inset", name: "Inset", label: "Inset" },
  { id: "outset", value: "outset", name: "Outset", label: "Outset" },
];

//find property named border-style in sectorsConfig
export const getConfigByProperty = (propertyName: string) => {
  for (const sector of sectorsConfig) {
    if (sector.properties) {
      for (const property of sector.properties) {
        if (typeof property !== "string" && property.property === propertyName) {
          return property;
        }
        if (typeof property !== "string" && property.type === "composite" && property.properties) {
          const nestedProperty = property.properties.find((nested) => nested.property === propertyName);
          if (nestedProperty) {
            return nestedProperty;
          }
        }
      }
    }
  }
  return null; // Jeśli nie znaleziono konfiguracji
};

const propertyName = "border-style";

const moreIcon = [
  "border-width",
  "border-color",
  "border-style",
  "transform-origin",
  "overflow",
  "perspective-origin",
].includes(propertyName) ? (
  <Composition2 />
) : (
  <Composition />
);

type BorderStyleProps = {
  el: ReactNode;
  property: string;
};

type TBorderStyle = (props: BorderStyleProps) => ReactNode;

export const BorderStyle: TBorderStyle = () => {
  // export const BorderStyle: TBorderStyle = ({ el, property }) => {
  const propertyRef = useRef<HTMLDivElement>(null);
  const [canClear, setCanClear] = useState(false);
  const [globalValue, setGlobalValue] = useState(options[0].value);
  const [isCustomVisible, setCustomVisible] = useState(false);
  const allValueEqual = true;

  const handleClearValue = () => {};

  const handleChangeValue = () => {};

  const handleChange = () => {};

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    if (options.length > 0) {
      const myTarget = propertyRef.current?.querySelector(".select-button") || propertyRef.current;

      const coordinates = myTarget?.getBoundingClientRect();
      if (!coordinates) return;

      // TODO maybe move to machine
      styleManagerService.openUnitDropdown(options, globalValue, coordinates, handleChange);
    }
  };

  const normalizePropertyName = (input: string): string => {
    return input
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <div className="property-composite" ref={propertyRef}>
        <PropertyLabel
          text={normalizePropertyName(propertyName)}
          modifier={canClear ? "edited" : "primary"}
          clearValue={canClear ? () => handleChangeValue() : undefined}
        />
        <div className="property-composite__custom">
          <div style={{ display: "grid", gridTemplateColumns: "minmax(0, 1fr) auto" }}>
            <SelectButton currentOption={globalValue} options={options} onClick={handleUnitDropdown} />
          </div>
          <Button
            type="icon"
            iconStart={moreIcon}
            onClick={() => setCustomVisible((prev) => !prev)}
            className={`${!allValueEqual || isCustomVisible ? "active" : ""}`}
          />
        </div>
      </div>
      {isCustomVisible && (
        <div className="property-composite__properties">
          <div className={`property-select vertical property-select--placeholder`} ref={propertyRef}>
            <PropertyLabel
              text={"Top"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
              // info={info}
            />
            <SelectButton currentOption={globalValue} options={options} onClick={handleUnitDropdown} />
          </div>
          <div className={`property-select vertical property-select--placeholder`} ref={propertyRef}>
            <PropertyLabel
              text={"Right"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
              // info={info}
            />
            <SelectButton currentOption={globalValue} options={options} onClick={handleUnitDropdown} />
          </div>
          <div className={`property-select vertical property-select--placeholder`} ref={propertyRef}>
            <PropertyLabel
              text={"Bottom"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
              // info={info}
            />
            <SelectButton currentOption={globalValue} options={options} onClick={handleUnitDropdown} />
          </div>
          <div className={`property-select vertical property-select--placeholder`} ref={propertyRef}>
            <PropertyLabel
              text={"Left"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
              // info={info}
            />
            <SelectButton currentOption={globalValue} options={options} onClick={handleUnitDropdown} />
          </div>
        </div>
      )}
    </>
  );
};
