import { createMachine, type EventObject } from "xstate";
import { dashboardInstance } from "../dashboard/dashboard.instance.ts";
import { Templates } from "../../services/Templates/Templates.ts";
import { projectsService } from "../projects/projects.instance.ts";

export interface CustomEvent<T> extends EventObject {
  data: T;
}

export const templatesMachineInit = (service: Templates) =>
  createMachine(
    {
      predictableActionArguments: true,
      context: {
        service,
        isProjectSettings: false,
      },
      id: "TEMPLATES",
      initial: "START",
      states: {
        START: {
          on: {
            FETCH_TEMPLATES: "FETCH_TEMPLATES",
          },
        },

        FETCH_TEMPLATES: {
          invoke: {
            src: "fetchTemplates",
            onDone: {
              target: "NOTIFY_COMPONENT",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        NOTIFY_COMPONENT: {
          invoke: {
            src: "notifyComponent",
            onDone: {
              target: "AWAITING_TEMPLATE_SELECTION",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        AWAITING_TEMPLATE_SELECTION: {
          on: {
            SELECT_TEMPLATE: "SELECT_TEMPLATE",
          },
        },

        SELECT_TEMPLATE: {
          invoke: {
            src: "selectProject",
            onDone: [{ target: "REDIRECT_TO_ALPHAO_TEMPLATES" }],
            onError: {
              target: "ERROR",
            },
          },
        },

        REDIRECT_TO_ALPHAO_TEMPLATES: {
          invoke: {
            src: "redirectToAlphaOTemplates",
            onDone: {
              target: "FINISH",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        FINISH: {
          on: {
            FETCH_TEMPLATES: "FETCH_TEMPLATES",
            SELECT_TEMPLATE: "SELECT_TEMPLATE",
          },
        },

        ERROR: {
          entry: () => console.log("ERROR"),
        },
      },
    },
    {
      services: {
        selectProject: async (_, event) => {
          return await new Promise<void>((resolve) => {
            service.setActiveProject(event.projectId);
            projectsService.clearActiveProject();
            resolve();
          });
        },

        notifyComponent: async () => {
          return await new Promise<void>((resolve) => {
            service.notifySubscribers();
            resolve();
          });
        },

        fetchTemplates: () => {
          return new Promise<void>((resolve, reject) => {
            service
              .getTemplates()
              .then((data) => {
                service.state = data;
                resolve();
              })
              .catch((error) => reject(new Error(error)));
          });
        },

        redirectToAlphaOTemplates: async () => {
          return await new Promise<void>((resolve) => {
            dashboardInstance.send("ALPHA_O_TEMPLATES");
            resolve();
          });
        },
      },
    }
  );
