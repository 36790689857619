import { useRef, useState } from "react";
import { styleManagerService } from "../../styles-manager/StylesManager.service";

import { PropertyLabel, SelectButton } from "../../styles-manager/components";
import { Button } from "../../../../../AlphaO/atoms/Button";

import { Composition } from "../../../../../../assets/svg/Composition";
import { Composition2 } from "../../../../../../assets/svg/Composition2";

import type { ReactNode } from "react";

type ClipProps = {
  el: ReactNode;
  property: string;
};

type TClip = (props: ClipProps) => ReactNode;

const options = [
  { id: "auto", value: "auto", name: "Auto", label: "Auto" },
  { id: "border-box", value: "border-box", name: "Border Box", label: "Border Box" },
  { id: "padding-box", value: "padding-box", name: "Padding Box", label: "Padding Box" },
  { id: "content-box", value: "content-box", name: "Content Box", label: "Content Box" },
];

export const Clip: TClip = ({ el, property }) => {
  const propertyRef = useRef<HTMLDivElement>(null);
  const [canClear, setCanClear] = useState(false);
  const [isCustomVisible, setCustomVisible] = useState(false);
  const globalValue = options[0].value;
  const allValueEqual = false;

  const normalizePropertyName = (input: string): string => {
    return input
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChangeValue = () => {};
  const handleChange = () => {};

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    if (options.length > 0) {
      const myTarget = propertyRef.current?.querySelector(".select-button") || propertyRef.current;

      const coordinates = myTarget?.getBoundingClientRect();
      if (!coordinates) return;

      // TODO maybe move to machine
      styleManagerService.openUnitDropdown(options, globalValue, coordinates, handleChange);
    }
  };

  const moreIcon = [
    "border-width",
    "border-color",
    "border-style",
    "transform-origin",
    "overflow",
    "perspective-origin",
  ].includes(property) ? (
    <Composition2 />
  ) : (
    <Composition />
  );

  return (
    <>
      <div className="property-composite" ref={propertyRef}>
        <PropertyLabel
          text={normalizePropertyName(property)}
          modifier={canClear ? "edited" : "primary"}
          clearValue={canClear ? () => handleChangeValue() : undefined}
        />
        <div className="property-composite__custom">
          <div style={{ display: "grid", gridTemplateColumns: "minmax(0, 1fr) auto" }}>
            <SelectButton currentOption={globalValue} options={options} onClick={handleUnitDropdown} />
          </div>
          <Button
            type="icon"
            iconStart={moreIcon}
            onClick={() => setCustomVisible((prev) => !prev)}
            className={`${!allValueEqual || isCustomVisible ? "active" : ""}`}
          />
        </div>
      </div>
    </>
  );
};
