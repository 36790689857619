import { Button } from "../../../../../../AlphaO/atoms/Button";

import { TModalFooter } from "./ModalFooter.types";

import "./ModalFooter.css";

export const ModalFooter: TModalFooter = ({ onBack, onCancel }) => {
  return (
    <footer className="modal-footer">
      <div className="modal-footer__cta-start">{onBack && <Button type="primary" onClick={onBack} />}</div>
      <div className="modal-footer__cta-end">
        {onCancel && (
          <Button type="big/second" size="small" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </div>
    </footer>
  );
};
