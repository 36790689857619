import React, { useCallback, useEffect, useRef, useState } from "react";

import { Button } from "../../../../../../../AlphaO/atoms/Button";
import { PropertyLabel } from "../../../../styles-manager/components";
import { Add } from "../../../../../../../../assets/svg/Add";
import { PropertyComponent } from "./PropertyComponent";
import { PropertySelect } from "./PropertySelect";
import { ListItem } from "../../partials/ListItem";

import { smartComponentFormItemClass } from "../../../../editor/block-definitions/block-helpers";

import { createInputComponent } from "../../../../editor/block-definitions/smart-component-form/createInputComponent.ts";
import { createFormItem } from "../../../../editor/block-definitions/smart-component-form/createFormItem.ts";
import { normalizeLabel } from "../../../../../../../../utils/index.ts";
import { createTextareaComponent } from "../../../../editor/block-definitions/smart-component-form/createTextareaComponent.ts";
import { createCheckboxComponent } from "../../../../editor/block-definitions/smart-component-form/createCheckboxComponent.ts";
import { createButtonComponent } from "../../../../editor/block-definitions/smart-component-form/createButtonComponent.ts";

import type { Option } from "../../../../trait-manager/traits/SmartComponents/Form/PropertySelect.tsx";
import type { Trait, Component, ComponentDefinition } from "grapesjs";

// Constant containing available form options
const FORM_ELEMENT_OPTIONS: Option[] = [
  { id: "1", value: "Input", label: "Input" },
  { id: "2", value: "Text Area", label: "Text Area" },
  { id: "5", value: "Checkbox", label: "Checkbox" },
  { id: "6", value: "Button", label: "Button" },
];

// Map of functions creating component definitions based on the selected option
const createComponentDefinitionMap: Record<string, (placeholder: string) => ComponentDefinition> = {
  Input: (placeholder: string) => {
    const id = normalizeLabel("New Input");
    return createFormItem({
      labelText: "New Input",
      labelFor: id,
      inputComponent: createInputComponent({
        id,
        name: id,
        placeholder,
        required: true,
      }),
    });
  },
  "Text Area": (placeholder: string) => {
    const id = normalizeLabel("New Text Area");
    return createFormItem({
      labelText: "New Text Area",
      labelFor: id,
      inputComponent: createTextareaComponent({
        id,
        name: id,
        placeholder,
        required: true,
      }),
    });
  },
  Checkbox: () => {
    const id = "new-checkbox";
    return createFormItem({
      labelText: "New Checkbox",
      labelFor: id,
      inputComponent: createCheckboxComponent({
        id,
        name: id,
        value: "true",
      }),
      labelPosition: "after",
      wrapperStyles: { "flex-direction": "row" },
    });
  },
  Button: () => createButtonComponent({ content: "New Button" }),
};

export const SCFormTrait: React.FC<{ trait: Trait }> = ({ trait }) => {
  const optionsRef = useRef<HTMLUListElement>(null);
  // const [showPropertySelect, setShowPropertySelect] = useState<boolean>(false);
  const [items, setItems] = useState<Component[]>([]);
  const [selectedItem, setSelectedItem] = useState<Component | null>(null);

  const scFormComponent = trait.target;
  const boundingClientRect = optionsRef.current?.getBoundingClientRect();
  const totalFormItems = scFormComponent.find(`.${smartComponentFormItemClass}`).length;

  // Update the list of form items
  useEffect(() => {
    const smartComponentFormItems = scFormComponent.find(`.${smartComponentFormItemClass}`);
    setItems(smartComponentFormItems);
    return () => setItems([]);
  }, [totalFormItems, scFormComponent]);

  // Removing form item
  const removeItem = (model: Component) => {
    model.remove();
    setItems((prevItems) => prevItems.filter((item) => item.cid !== model.cid));
  };

  // Toggle visibility of the property selector
  // const togglePropertySelect = () => setShowPropertySelect((prev) => !prev);

  // Form element selection
  const selectItem = (model: Component) => {
    setSelectedItem((prevSelected) => (prevSelected?.cid !== model.cid ? model : prevSelected));
  };

  // Refetch data
  const refetchData = () => {
    setItems(scFormComponent.find(`.${smartComponentFormItemClass}`));
  };

  // Closing the properties popup
  const handleClosePopup = () => setSelectedItem(null);

  // Drag and drop items
  const reorderItems = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);
      return updatedItems;
    });
  }, []);

  // Synchronize the order of items with the editor
  const syncFormItems = useCallback(() => {
    const currentOrder = scFormComponent.find(`.${smartComponentFormItemClass}`);
    const shouldUpdate = !currentOrder.every((item, index) => item.cid === items[index]?.cid);

    if (shouldUpdate) {
      scFormComponent.components().reset(items);
    }
  }, [items, scFormComponent]);

  // Handle selection of new form element
  const handleSelectNewItem = (selectedOption: Option) => {
    const placeholder = "Type Here";
    const createDefinition = createComponentDefinitionMap[selectedOption.value];

    if (createDefinition) {
      const newComponentDefinition = createDefinition(placeholder);
      const newComponents = scFormComponent.components().add([newComponentDefinition]) as Component[];
      if (newComponents[0]) {
        setItems((prev) => [...prev, ...newComponents]);
      }
    }

    // setShowPropertySelect(false);
  };

  // Generating label for the element
  const getLabel = (model: Component): string => {
    const tagName = model.get("tagName");
    if (tagName === "button") return "button";
    if (tagName === "div") {
      const childComponent = model.components().find((child) => child.get("type") !== "label");
      const labelComponent = model.components().find((child) => child.get("type") === "label");
      const labelContent = labelComponent?.get("content");
      const childTagName = childComponent?.get("tagName");
      const childTagType = childComponent?.getAttributes().type;

      if (childTagName === "button") {
        const buttonContent = childComponent?.get("content");
        return buttonContent || "";
      }

      if (childTagName === "input" && childTagType === "checkbox") {
        const inputFor = labelComponent.getAttributes().for;
        return inputFor || "";
      }

      return labelContent || "";
    }
    return "";
  };

  return (
    <li className="trait-manager__trait trait-manager__trait--option">
      <h3>
        <PropertyLabel text="Select Element To Add" />
      </h3>
      <PropertySelect
        options={FORM_ELEMENT_OPTIONS}
        placeholder="Select form element"
        onOptionSelect={handleSelectNewItem}
      />
      <h3>
        <PropertyLabel text="Form Parts" />
      </h3>
      {items.length > 0 && (
        <ul className="trait-manager__options-list" ref={optionsRef}>
          {items.map((model, index) => (
            <ListItem
              key={model.cid}
              id={model.cid}
              index={index}
              model={model}
              label={getLabel(model)}
              removeModel={removeItem}
              selectModel={selectItem}
              moveModel={reorderItems}
              syncEditor={syncFormItems}
            />
          ))}
        </ul>
      )}
      {selectedItem && (
        <PropertyComponent
          key={selectedItem.cid}
          selectedItem={selectedItem}
          boundingClientRect={boundingClientRect}
          handleClosePopup={handleClosePopup}
          refetchData={refetchData}
        />
      )}
    </li>
  );
};
