import njk from "nunjucks";
import type { Imports } from "../../types/types";
import { componentsSet } from "../../services/AppService/components";
import { string } from "prop-types";

export const nunjucks = new njk.Environment(null, {
  autoescape: false,
  lstripBlocks: true,
  trimBlocks: true,
});

// Custom Filters
// JSON -Transpiles JSON to an Object and removes the quotes from the object's keys
nunjucks.addFilter("json", function (obj) {
  const json = JSON.stringify(obj, null, "\t");
  // This regex removes quotes from keys in an object
  const cleanedJSON = json.replace(/^[\t ]*"([^:\n\r]+)":/gm, function (match) {
    return match.replace(/"/g, "");
  });

  return new njk.runtime.SafeString(cleanedJSON);
});

nunjucks.addFilter("kebab", (string: string) =>
  string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase()
);

nunjucks.addFilter("className", (string) => {
  return string.replace(/(;|:)/g, "_");
});

nunjucks.addFilter("addIdAndFor", (string: string, attr: string) => {
  //regex to string match "Checkbox"
  if (/^Checkbox_/.test(string)) {
    return `${string} id={"${attr}"}`;
  }
  if (/^Label_/.test(string)) {
    return `${string} htmlFor={"${attr}"}`;
  }
  return string;
});

nunjucks.addFilter("separate", (string: string) =>
  string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, " ")
    .toLowerCase()
);
export const uniqImports = (array: Imports[]) => {
  return array.reduce<Imports[]>((acc, current) => {
    //if is not in componentsSet then just push
    if (!componentsSet.includes(current.componentName)) {
      acc.push(current);

      return acc;
    }

    if (
      !acc.some((element) => {
        return element.folder === current.folder;
      })
    ) {
      acc.push(current);
    }

    return acc;
  }, []);
};

nunjucks.addFilter("unique", uniqImports);

nunjucks.addFilter("hasKey", (array: Record<string, string>[], attr: string) => {
  if (!Array.isArray(array)) {
    throw new TypeError(`hasKey: provided data is not an array`);
  }

  return array.some((element) => element[attr]);
});

export const props = (obj: Record<string, string | Record<string, string>>) => {
  //TODO implement changes of props
  return obj;
};

export const transformCamelToKebabCase = (str: string) => {
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
};

nunjucks.addFilter("camelToKebab", transformCamelToKebabCase);

nunjucks.addFilter("props", props);
nunjucks.addFilter("extract", (obj: Record<string, string | Record<string, string>>, attr: string) => {
  const toReturn = {
    ...obj,
    ...(obj[attr] as Record<string, string>),
  };

  delete toReturn[attr];

  return toReturn;
});

nunjucks.addFilter("removeKey", (obj: Record<string, string>, attrs: string[]) => {
  attrs.forEach((key) => {
    delete obj[key];
  });
  return obj;
});
