import { Subscriber } from "./../abstract/Subscriber/Subscriber";
import { ActiveProject, ProjectHistory, Page, SubscriptionTypes } from "./../../types/types";
import { projectsAxios } from "./ProjectsAxiosInstance.ts";
import { ISubscriber } from "../abstract/Subscriber/Subscriber.types.ts";
import { actionProxyService } from "../../components/pages/Alpha_O/modules/action-proxy/ActionProxy.service.ts";

import { cleanUrl } from "../../components/pages/Alpha_O/utils/clean-url.ts";

import { Project } from "../../types/types.ts";

export type Route = {
  frameId: string;
  name: string;
  slug: string;
};

type DeletePagePayload = {
  projectId: string;
  mainFrameId: string;
  newMainPageId?: string;
};
export class Projects extends Subscriber<Project[]> {
  state = [] as Project[];
  //store scripts like resetCss to add to head when project is loaded/page changed
  scripts = [] as string[];
  // store js scripts to add this between change pages
  jsScripts = [] as string[];
  async getProjectsBy() {
    const { data } = await projectsAxios.get<[]>(`/api/projects/user`, {}); // we don't need to pass user id
    this.notifySubscribers();
    return data;
  }

  setActiveProject = (projectId: string) => {
    this.state = this.state.map((project) => ({
      ...project,
      active: project.projectId === projectId,
    }));

    const activeProject = this.getActiveProject();
    if (activeProject) {
      actionProxyService.loadPolicy(activeProject.policy);
    }
  };

  clearActiveProject = () => {
    this.state = this.state.map((project) => {
      return {
        ...project,
        active: false,
      };
    });
  };

  saveHistory = async (projectId: string, data: ProjectHistory) => {
    return await projectsAxios.post<{ status: string }>(
      `/api/project-history/${this.transformProjectId(projectId)}`,
      data
    );
  };

  getHistory = async (projectId: string) => {
    const { data } = await projectsAxios.get<ProjectHistory>(
      `/api/project-history/${this.transformProjectId(projectId)}`,
      {}
    );
    return data;
  };
  getProjectsWithPublishedCustomDomain = () => {
    return this.state.filter((project) => project.customDomain.published);
  };

  getProjectRouting = async (projectId: string) => {
    const { data } = await projectsAxios.get<{ slug: string; name: string; frameId: string }[]>(
      `/api/projects/routing/${this.transformProjectId(projectId)}`,
      {}
    );

    return data;
  };

  getPagesByProjectId = async (projectId: string) => {
    const { data } = await projectsAxios.get<{ pages: Page[]; reset: string; variables: string }>(
      `/api/project-data/${this.transformProjectId(projectId)}`
    );

    const figmaDataComponents = await this.getFigmaDataComponents(projectId);

    const { pages, variables } = data;

    // TODO - maybe is other way to add styles to editor globally
    return pages.map((page, idx) => {
      if (idx === 0) {
        return {
          ...page,
          styles: `${variables ?? ""} ${figmaDataComponents} ${page.styles}`,
          // styles: `${components ?? ""} ${variables ?? ""} ${page.styles}`,
        };
      }

      return page;
    });
  };

  // when update project dont whanna gather styles in first page
  getOvhPagesByProjectId = async (projectId: string) => {
    const { data } = await projectsAxios.get<{ pages: Page[]; reset: string; variables: string; components: string }>(
      `/api/project-data/${this.transformProjectId(projectId)}`
    );

    return data.pages;
  };

  getFigmaDataComponents = async (projectId: string) => {
    const { data } = await projectsAxios.get<{ data: string }>(
      `/api/project-data/components/${this.transformProjectId(projectId)}`
    );
    return data;
  };

  // TODO maybe use spread if other properties can by passed?
  updateActiveProjectPages = async (pages: ActiveProject["pages"]) => {
    const activeProject = this.getActiveProject();
    if (activeProject) {
      activeProject.pages = pages;
    }
  };

  projectFetched = async (projectId: string) => {
    const { data } = await projectsAxios.patch<Project["pages"]>(
      `/api/project-data/${this.transformProjectId(projectId)}/set-need-update-false`
    );

    this.updateActiveProjectPages(data);
  };

  updateProjectData = async (
    projectId: string,
    updatedProjectData: {
      customDomain?: Project["customDomain"];
      subdomain?: Project["subdomain"];
      name?: string;
      description?: string;
      customHead?: string;
      customCode?: string;
    }
  ) => {
    type PatchData = {
      // backend return this key
      $setOnInsert?: any;
      "customDomain.address"?: string;
      "customDomain.published"?: string;
      "subdomain.address"?: string;
      "subdomain.published"?: string;
      name?: string;
      description?: string;
      customHead?: string;
      customCode?: string;
    };

    const transformedProjectId = this.transformProjectId(projectId);
    const { data } = await projectsAxios.patch<PatchData>(`/api/projects/${transformedProjectId}`, updatedProjectData);

    const activeProject = this.getActiveProject();

    if (!activeProject) return;

    let newData = {};

    // because backend return { customDomain.address or customDomain.published }
    // instead of customDomain: { address or published } if has nested object

    Object.keys(data).map((key) => {
      const insideKey = key as keyof PatchData;
      if (insideKey === "$setOnInsert") return;
      if (insideKey.includes(".")) {
        const [firstKey, secondKey] = insideKey.split(".") as ["customDomain" | "subdomain", "address" | "published"];
        newData = {
          ...newData,
          [firstKey]: {
            ...activeProject[firstKey],
            [secondKey]: data[insideKey],
          },
        };
      } else {
        newData = {
          ...newData,
          [key]: data[insideKey],
        };
      }
    });

    const updatedProject = {
      ...activeProject,
      ...newData,
    };

    this.updateProject(updatedProject);
  };

  publishProject = async (projectData: { projectId: string; customDomain?: string }) => {
    const { data } = await projectsAxios.patch<Project>(`/api/projects/publish`, projectData);

    this.updateProject(data);
  };

  unpublishProject = async (projectData: { projectId: string; customDomain?: string }) => {
    const { data } = await projectsAxios.patch<Project>(`/api/projects/unpublish`, projectData);

    this.updateProject(data);
  };

  updateProject = (project: Project) => {
    this.state = this.state.map((p) => {
      if (p.projectId === project?.projectId) {
        return {
          ...p,
          ...project,
        };
      }
      return p;
    });
    this.notifySubscribers();
  };

  deleteProject = async (projectId: string) => {
    let data;
    try {
      const { data: message } = await projectsAxios.delete<[]>(`/api/projects/${this.transformProjectId(projectId)}`);
      data = message;
    } catch (error) {
      console.error(error);
    }
    this.state = this.state.filter((project) => project.projectId !== projectId);
    this.notifySubscribers();
    return data;
  };

  duplicateProject = async (projectId: string) => {
    try {
      const { data } = await projectsAxios.post<Project>(`/api/projects/clone`, {
        projectId: projectId,
      });
      this.state.push(data);
    } catch (error) {
      console.error(error);
    }

    this.notifySubscribers();
  };

  addPage = async (projectId: string, page: { name: string; frameId: string }) => {
    const { data } = await projectsAxios.patch<ActiveProject["pages"]>(`/api/projects/add-page`, {
      projectId: projectId,
      data: page,
    });

    this.updateActiveProjectPages(data);
  };

  renamePage = async (projectId: string, pageId: string, name: string) => {
    try {
      const { data } = await projectsAxios.patch(`/api/projects/update-page`, {
        projectId: projectId,
        mainFrameId: pageId,
        data: {
          name,
        },
      });
      this.updateActiveProjectPages(data);
    } catch (error) {
      throw new Error("Can't rename page");
    }
  };

  deletePage = async (projectId: string, pageId: string, newMainPageId?: string | null) => {
    const payload: DeletePagePayload = {
      projectId: projectId,
      mainFrameId: pageId,
    };

    if (newMainPageId) {
      payload["newMainPageId"] = newMainPageId;
    }
    try {
      const { data } = await projectsAxios.patch(`/api/projects/delete-page`, payload);

      this.updateActiveProjectPages(data);
    } catch (error) {
      throw new Error("Can't delete page");
    }
  };

  checkCustomDomainHealth = async () => {
    const activeProject = this.getActiveProject();
    const formattedCustomDomain = cleanUrl(activeProject?.customDomain?.address || "");

    if (activeProject) {
      const response = await projectsAxios.get(`/api/domain/healthy-check/${formattedCustomDomain}`);

      return response;
    }

    return null;
  };

  downloadActiveProject = async () => {
    const activeProject = this.getActiveProject();

    if (!activeProject) {
      return null;
    }

    const projectId = this.transformProjectId(activeProject.projectId);
    const response = await projectsAxios.get(`/api/projects/${projectId}`);

    return response;
  };

  invalidateDomain = async (projectId: string) => {
    await projectsAxios.get(`/api/projects/invalidate/${this.transformProjectId(projectId)}`);
  };

  setSynced = async (projectId: string, state: boolean) => {
    const activeProject = this.getActiveProject();

    if (!activeProject) return;

    try {
      const updatedProject = await projectsAxios.patch(`/api/projects/${this.transformProjectId(projectId)}`, {
        isSynced: state,
      });

      const newState = {
        ...activeProject,
        isSynced: updatedProject.data.isSynced,
      };

      this.updateProject(newState);
    } catch (error) {
      console.error(error);
    }
  };

  getActiveProject = () => this.state.find((project) => project.active);
  sendStateToSubscriber = (sb: ISubscriber<Project[]>) => sb([...this.state]);
  transformProjectId = (projectId: string) => projectId.replace("/", "+");

  checkSubdomainAvailability = async (value: string, signal: AbortSignal) => {
    const response = await projectsAxios.get(`/api/projects/check-subdomain/${value}`, {
      signal,
    });

    return response;
  };

  addPageRouting = async (projectId: string, route: Route) => {
    return projectsAxios.post<{ slug: string; name: string; frameId: string }[]>(
      `/api/projects/routing/${this.transformProjectId(projectId)}`,
      {
        route,
      }
    );
  };
  removePageRouting = async (projectId: string, route: Route) => {
    return await projectsAxios.delete<{ slug: string; name: string; frameId: string }[]>(
      `/api/projects/routing/${this.transformProjectId(projectId)}`,
      {
        data: { route },
      }
    );
  };
  updatePageRouting = async (projectId: string, route: Route) => {
    return projectsAxios.patch<{ slug: string; name: string; frameId: string }[]>(
      `/api/projects/routing/${this.transformProjectId(projectId)}`,
      {
        route,
      }
    );
  };

  downgradeProjectSubscription = async (projectId: string, newPriceId: string, type: SubscriptionTypes) => {
    const { data } = await projectsAxios.patch<Project["subscriptions"]>(`/api/stripe/subscription-downgrade`, {
      type,
      projectId: projectId,
      newPriceId: newPriceId,
    });

    this.updateActiveProjectSubscriptionData(projectId, data);
  };

  updateActiveProjectSubscriptionData = (projectId: string, updatedSubscription: Project["subscriptions"]) => {
    const project = this.state.find((project) => {
      if (project.projectId === projectId) {
        return project;
      }
    });

    if (project) {
      project.subscriptions = updatedSubscription;
    }
  };

  getActiveProjectId = () => {
    return this.getActiveProject()?.projectId || "no active project";
  };
}
