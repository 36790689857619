import { BlockProperties } from "grapesjs";

export const map = {
  id: "map",
  media: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="dark-fill" d="M1.33398 8.00002V29.3334L10.6673 24L21.334 29.3334L30.6673 24V2.66669L21.334 8.00002L10.6673 2.66669L1.33398 8.00002Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path class="dark-fill"  d="M10.668 2.6665V23.9998" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.334 8V29.3333" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  label: "Map",
  category: "Layout",
  content: {
    type: "map",
  },
  select: true,
};
