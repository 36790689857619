import { useRef, useState } from "react";

import type { ReactNode } from "react";
import { PropertyLabel, StyleManagerInput } from "../../styles-manager/components";
import { Button } from "../../../../../AlphaO/atoms/Button";

import { Composition } from "../../../../../../assets/svg/Composition";
import { Composition2 } from "../../../../../../assets/svg/Composition2";
import { propertyIconMap } from "../../styles-manager/helpers/propertyIconMap";

const propertyName = "Corner";
const usedUnit = "px";

const moreIcon = [
  "border-width",
  "border-color",
  "border-style",
  "transform-origin",
  "overflow",
  "perspective-origin",
].includes(propertyName) ? (
  <Composition2 />
) : (
  <Composition />
);

type CornerProps = {
  el: ReactNode;
  property: string;
};

type TCorner = (props: CornerProps) => ReactNode;

export const Corner: TCorner = () => {
  // export const Corner: TCorner = ({ el, property }) => {
  const propertyRef = useRef<HTMLDivElement>(null);
  const [canClear, setCanClear] = useState(false);
  const [isCustomVisible, setCustomVisible] = useState(false);
  const value = 0;
  const allValueEqual = true;

  const handleClearValue = () => {};

  const handleChangeValue = () => {};

  const handleChange = () => {};

  const handleOpenUnitsDropdown = () => {};

  const onChange = () => {};

  return (
    <>
      <div className="property-composite" ref={propertyRef}>
        <PropertyLabel
          text={propertyName}
          modifier={canClear ? "edited" : "primary"}
          clearValue={canClear ? () => handleChangeValue() : undefined}
        />
        <div className="property-composite__custom">
          <div style={{ display: "grid", gridTemplateColumns: "minmax(0, 1fr) auto" }}>
            <StyleManagerInput
              handleChange={handleChange}
              placeholder={propertyName}
              type="number"
              value={value ?? ""}
              hasUnits={true}
              unit={usedUnit}
              handleOpenUnitsDropdown={handleOpenUnitsDropdown}
            />
          </div>
          <Button
            type="icon"
            iconStart={moreIcon}
            onClick={() => setCustomVisible((prev) => !prev)}
            className={`${!allValueEqual || isCustomVisible ? "active" : ""}`}
          />
        </div>
      </div>
      {isCustomVisible && (
        <div className="property-composite__properties">
          <div className={`property top-left`} ref={propertyRef}>
            <PropertyLabel
              text={"Top Left"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
            />
            <StyleManagerInput
              handleChange={onChange}
              value={value}
              placeholder={value.toString()}
              type="number"
              min={0}
              max={100}
              step={0.1}
              unit={"px"}
              hasUnits={true}
              handleOpenUnitsDropdown={handleOpenUnitsDropdown}
              modifier
              iconStart={propertyIconMap[`${propertyName}TopLeft`]}
            />
          </div>
          <div className={`property top-right`} ref={propertyRef}>
            <PropertyLabel
              text={"Top Right"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
            />
            <StyleManagerInput
              handleChange={onChange}
              value={value}
              placeholder={value.toString()}
              type="number"
              min={0}
              max={100}
              step={0.1}
              unit={"px"}
              hasUnits={true}
              handleOpenUnitsDropdown={handleOpenUnitsDropdown}
              modifier
              iconStart={propertyIconMap[`${propertyName}TopRight`]}
            />
          </div>
          <div className={`property bottom-left`} ref={propertyRef}>
            <PropertyLabel
              text={"Bottom Left"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
            />
            <StyleManagerInput
              handleChange={onChange}
              value={value}
              placeholder={value.toString()}
              type="number"
              min={0}
              max={100}
              step={0.1}
              unit={"px"}
              hasUnits={true}
              handleOpenUnitsDropdown={handleOpenUnitsDropdown}
              modifier
              iconStart={propertyIconMap[`${propertyName}BottomLeft`]}
            />
          </div>
          <div className={`property bottom-right`} ref={propertyRef}>
            <PropertyLabel
              text={"Bottom Right"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
            />
            <StyleManagerInput
              handleChange={onChange}
              value={value}
              placeholder={value.toString()}
              type="number"
              min={0}
              max={100}
              step={0.1}
              unit={"px"}
              hasUnits={true}
              handleOpenUnitsDropdown={handleOpenUnitsDropdown}
              modifier
              iconStart={propertyIconMap[`${propertyName}BottomRight`]}
            />
          </div>
        </div>
      )}
    </>
  );
};
