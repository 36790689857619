import { useEffect, useRef, useState } from "react";

import { styleManagerService } from "../../StylesManager.service";
import { colorPickerServiceInstance } from "../../../../../../AlphaO/atoms/ColorPicker/ColorPicker.service";

import { PropertyLabel } from "../PropertyLabel";
import { NumberProperty } from "../NumberProperty";
import { SelectProperty } from "../SelectProperty";
import { ColorProperty } from "../ColorProperty/ColorProperty";
import { StyleManagerInput } from "../StyleManagerInput";
import { SelectButton } from "../SelectButton";
import { Button } from "../../../../../../AlphaO/atoms/Button";
import { Composition } from "../../../../../../../assets/svg/Composition";
import { Composition2 } from "../../../../../../../assets/svg/Composition2";

import { getValueFromVariable } from "../../helpers/getValueFromVariable";

import { SelectOption, type Property, type PropertyNumberProps } from "grapesjs";
import { type TCompositeProperty } from "./CompositeProperty.types";

import "./CompositeProperty.css";
import { CustomSlider } from "../../../../../../AlphaO/atoms/CustomSlider/CustomSlider";

export const CompositeProperty: TCompositeProperty = ({ property, allVariables, withSlider }) => {
  const [isCustomVisible, setCustomVisible] = useState(false);
  const [globalValue, setGlobalValue] = useState("");
  const propertyRef = useRef<HTMLDivElement>(null);
  const properties = property.getProperties() || [];
  const canClear = property.canClear();
  const firstProperty = property.getPropertyAt(0);
  const allValueEqual = properties?.every((val) => val.getValue() === firstProperty?.getValue());
  const allUnitEqual = properties?.every((prop) => prop.get("unit") === firstProperty.get("unit"));

  const placeholder = allValueEqual && allUnitEqual ? property.getDefaultValue() : "Custom";
  const usedUnit = allUnitEqual && allValueEqual ? firstProperty.get("unit") : "";
  const units = firstProperty?.get("units") || [];
  const options: SelectOption[] = firstProperty?.get("options") || [];
  const hasOptions = options.length > 0;

  useEffect(() => {
    if (!allValueEqual || !allUnitEqual) {
      setGlobalValue("");
      setCustomVisible(true);
    } else {
      const firstValue = firstProperty.getValue({ noDefault: true });
      const finalValue =
        typeof firstValue === "string" && firstValue.startsWith("var(")
          ? getValueFromVariable(allVariables, firstValue)
          : firstValue;
      setCustomVisible(false);
      setGlobalValue(finalValue);
    }
  }, [allValueEqual, allUnitEqual, firstProperty.getValue({ noDefault: true })]);

  const handleChange = (e: string) => {
    if (globalValue === e) return;
    setGlobalValue(e);
    properties.forEach((prop) => {
      if (prop.getValue() !== e) {
        prop.upValue(e || "");
      }

      if (units.length > 0 && usedUnit === "") {
        prop.set("unit", "px");
      }
    });
  };

  const changeUnit = (unit: string) => {
    properties.forEach((prop) => {
      const numberProp = prop as Property<PropertyNumberProps>;
      if (numberProp.get("unit") === unit) return;
      numberProp.set("unit", unit);
    });
  };

  const handleOpenUnitsDropdown = () => {
    if (units && units.length > 0) {
      const target = propertyRef.current?.querySelector(".property-composite__custom") || propertyRef.current;
      const coordinates = target?.getBoundingClientRect();
      if (!coordinates) return;

      // TODO maybe move to machine
      styleManagerService.openUnitDropdown(units, usedUnit, coordinates, changeUnit);
    }
  };

  const moreIcon = [
    "border-width",
    "border-color",
    "border-style",
    "transform-origin",
    "overflow",
    "perspective-origin",
  ].includes(property.getName()) ? (
    <Composition2 />
  ) : (
    <Composition />
  );

  // for color type
  const onClickHandler = () => {
    if (!propertyRef.current) return;

    const target = propertyRef.current.querySelector(".property-select__button") || propertyRef.current;

    const position = target.getBoundingClientRect();
    colorPickerServiceInstance.setupColorPicker(globalValue, handleChange, {
      x: position.left,
      y: position.top,
    });
  };

  // for select type

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    if (hasOptions) {
      const myTarget = propertyRef.current?.querySelector(".select-button") || propertyRef.current;

      const coordinates = myTarget?.getBoundingClientRect();
      if (!coordinates) return;

      // TODO maybe move to machine
      styleManagerService.openUnitDropdown(options, globalValue, coordinates, handleChange);
    }
  };

  return (
    <>
      <div className="property-composite" ref={propertyRef}>
        <PropertyLabel
          text={property.getLabel()}
          modifier={canClear ? "edited" : "primary"}
          clearValue={canClear ? () => property.clear() : undefined}
        />
        <div className="property-composite__custom">
          <div style={{ display: "grid", gridTemplateColumns: "minmax(0, 1fr) auto" }}>
            {properties[0].get("type") === "number" && (
              <StyleManagerInput
                handleChange={handleChange}
                placeholder={placeholder}
                type="number"
                value={globalValue ?? ""}
                hasUnits={true}
                unit={usedUnit}
                handleOpenUnitsDropdown={handleOpenUnitsDropdown}
              />
            )}
            {properties[0].get("type") === "color" && (
              <StyleManagerInput
                type="text"
                value={globalValue ?? "transparent"}
                handleChange={handleChange}
                hasUnits={false}
                placeholder={"#000"}
                color={true}
                handleColorPicker={onClickHandler}
              />
            )}
            {properties[0].get("type") === "select" && (
              <SelectButton currentOption={globalValue} options={options} onClick={handleUnitDropdown} />
            )}
          </div>
          <Button
            type="icon"
            iconStart={moreIcon}
            onClick={() => setCustomVisible((prev) => !prev)}
            className={`${!allValueEqual || isCustomVisible ? "active" : ""}`}
          />
        </div>
      </div>
      {isCustomVisible && (
        <div className="property-composite__properties">
          {properties.map((prop) => {
            /* TODO change for property type => Component map */
            if (prop.get("type") === "number") {
              return <NumberProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            }

            if (prop.get("type") === "select") {
              return <SelectProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            }
            if (prop.get("type") === "color") {
              return <ColorProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            }

            return (
              <span key={prop.getId()}>
                {prop.get("name")} - not supported type {prop.get("type")}
              </span>
            );
          })}
        </div>
      )}
      {withSlider && <CustomSlider rangeMax={20} changeProps={handleChange} value={globalValue ?? "0"} />}
    </>
  );
};
