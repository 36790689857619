import { fixedComponentDefinition, smartComponentFormItemClass } from "../block-helpers";

import type { CSSStyle } from "../../../../../../../types";
import type { ComponentDefinition } from "grapesjs";

type CreateFormItemsAttrs = {
  labelText?: string;
  labelFor?: string;
  labelStyles?: CSSStyle;
  wrapperClass?: string;
  wrapperStyles?: CSSStyle;
  inputComponent: ComponentDefinition;
  labelPosition?: "before" | "after";
};

type CreateFormItem = (attrs: CreateFormItemsAttrs) => ComponentDefinition;

export const createFormItem: CreateFormItem = ({
  labelText = "",
  labelFor = "",
  labelStyles = {},
  wrapperClass = "",
  wrapperStyles = {},
  inputComponent = {},
  labelPosition = "before",
}) => {
  // Tworzymy labelComponent tylko, gdy labelText nie jest pusty
  const labelComponent = labelText
    ? {
        type: "label",
        content: labelText,
        ...fixedComponentDefinition,
        style: {
          ...labelStyles,
        },
        attributes: {
          for: labelFor,
        },
      }
    : null;

  // Kolejność komponentów, ale usuwamy null przez filter(Boolean).
  // Następnie rzutujemy tablicę na (ComponentDefinition[]),
  // bo filter(Boolean) wciąż powoduje w TS, że "coś może być undefined/null"
  const components = (
    labelPosition === "before" ? [labelComponent, inputComponent] : [inputComponent, labelComponent]
  ).filter(Boolean) as ComponentDefinition[]; // RZUTOWANIE!

  return {
    tag: "div",
    removable: false,
    dropable: false,
    copyable: false,
    classes: wrapperClass ? [smartComponentFormItemClass, wrapperClass] : [smartComponentFormItemClass],
    style: {
      ...wrapperStyles,
    },
    // TS będzie wiedział, że mamy wyłącznie ComponentDefinition[]
    components,
  };
};
