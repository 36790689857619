import { useEffect, useState } from "react";

import { projectsInstance } from "../../../machines/projects/projects.instance";
import { productsService } from "../../../machines/products/products.instance";

import { ButtonNew } from "../../atoms/ButtonNew";
import { WeirdIconWrapper } from "../../atoms/WeirdIconWrapper";
import MobileLoader from "./atoms/MobileLoader/MobileLoader";

import { UnauthorizedNavbar } from "../../molecules/UnauthorizedNavbar";

import { Logotype } from "../../../../assets/svg/logotype";
import { Checked } from "../../../../assets/svg/checked";
import { MonitorIcon } from "../../../../assets/svg/monitor_icon";
import { DiscordIconNew } from "../../../../assets/svg/discord_icon_new";

import type { TMobileBrickwall } from "./MobileBrickwall.types";

import "./MobileBrickwall.css";
import Success from "./atoms/Success/Success";

export const MobileBrickwall: TMobileBrickwall = () => {
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [showSuccessComponent, setShowSuccessComponent] = useState(true);

  const projectService = projectsInstance.getSnapshot().context.service;
  const productsTemplate = productsService.getTemplate();
  const hasTemplateToAdd = productsTemplate && Object.keys(productsTemplate).length > 0;

  useEffect(() => {
    const isOnboarding = JSON.parse(sessionStorage.getItem("isOnboarding") || "false");
    if (isOnboarding) setIsOnboarding(true);
  }, []);

  useEffect(() => {
    const projectState = projectService.state;

    if (projectState.length === 0 || hasTemplateToAdd) {
      if (isFetching === false) {
        setIsFetching(true);
      }

      projectsInstance.send("FETCH_PROJECTS");

      projectsInstance.onTransition((state) => {
        if (state.value === "AWAITING_PROJECT_SELECTION") {
          setIsFetching(false);
        }
      });
    } else {
      setIsFetching(false);
    }
  }, []);

  return (
    <>
      <UnauthorizedNavbar />
      {isFetching ? (
        <MobileLoader />
      ) : showSuccessComponent ? (
        <Success setShowSuccessComponent={setShowSuccessComponent} showSuccessComponent={showSuccessComponent} />
      ) : (
        <div className="mobile-brickwall">
          <div className="mobile-brickwall__wrapper">
            <div className="mobile-brickwall__content">
              <WeirdIconWrapper>
                <Checked />
              </WeirdIconWrapper>
              <div className="mobile-brickwall__introduction">
                <h1 className="mobile-brickwall__title">{isOnboarding ? "Sign up Complete!" : "Sign in Complete!"}</h1>
                <div className="mobile-brickwall__options">
                  <div className="mobile-brickwall__option">
                    <i>
                      <MonitorIcon />
                    </i>
                    <p>
                      To begin using the site, you will
                      <br />
                      need to launch <span>Codejet</span> from a PC or Mac
                    </p>
                  </div>
                  <div className="mobile-brickwall__option">
                    <i>
                      <DiscordIconNew />
                    </i>
                    <p>
                      Feel free to join our <span>{"Discord"}</span>
                      <br />
                      community for help and updates
                    </p>
                  </div>
                </div>
              </div>
              <ButtonNew
                orange
                className="mobile-brickwall__discord-btn"
                href="https://discord.gg/XHwhKVAQM9"
                target="_blank"
                rel="noreferrer noopener"
              >
                Join Discord
              </ButtonNew>
            </div>
            <div className="mobile-brickwall__navigation"></div>
          </div>
        </div>
      )}
    </>
  );
};
