import { useState } from "react";
import { productsInstance, productsService } from "../../../machines/products/products.instance";
import { PaymentsService } from "../../../services/Payments/Payments";
import { UserService } from "../../../services/UserService/UserService";
import { templatesAxios } from "../../../services/Templates/TemplatesAxiosInstance";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { routerInstance } from "../../../machines/router/router.instance";
import { dashboardInstance } from "../../../machines/dashboard/dashboard.instance";

import { LoginSuccessful } from "../../molecules/CreateAccount/atoms/LoginSuccessful";
import { Cart } from "../../molecules/Cart";
import { CreateAccount } from "../../molecules/CreateAccount";
import { OnboardingLayout } from "../../molecules/OnboardingLayout";

import type { TWrapUp } from "./WrapUp.types";
import { Project } from "../../../types/types";

import "./WrapUp.css";
import useMobileViewport from "../../../hooks/useMobileViewport/useMobileViewport";
import { PageHeader } from "../../atoms/PageHeader";

export const WrapUp: TWrapUp = ({ isAuthorized = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { products, totalPrice } = useProducts();
  const cartItemList = products.map((product) => ({
    heading: product.metadata.name,
    subHeading: product.metadata.billingPeriodDescription,
    price: product.price,
    billingPeriod: product.metadata.billingPeriod,
    priceId: product.priceId,
    metadata: product.metadata,
  }));
  const header = "Wrap up";
  const isMobile = useMobileViewport();

  const removeProduct = (priceId: string) => {
    productsInstance.send({ type: "UNSELECT_PLAN", payload: { priceId } });
  };

  const handlePay = async () => {
    const templateId = productsService.getTemplate()?.projectId;

    if (!templateId) {
      return;
    }

    try {
      const userId = UserService.getInstance().getData()?.USER_ID;
      setIsLoading(true);
      const { data } = await templatesAxios.post<Project>(`/api/project-templates/clone`, {
        templateId,
        userId,
      });

      const items = products.map((product) => ({
        priceId: product.priceId,
        type: product.type,
      }));

      await PaymentsService.createCheckoutSession(items, data.projectId);

      const expertAssistance = productsService.getExpertAssistance();

      if (expertAssistance) {
        await templatesAxios.post(`/api/service-order`, { ...expertAssistance, projectId: data.projectId });
      }

      dashboardInstance.send("PROJECTS");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    productsService.clear();
  };

  return (
    <>
      <OnboardingLayout
        onBack={() => routerInstance.send("SELECT_MARKETING_PLAN")}
        seoTitle="Wrap up - Codejet"
        breadcrumbStage={4}
        header={header}
        isTotalSectionVisible={false}
      >
        {isMobile ? <PageHeader header={header} /> : null}
        <main className="wrap-up__main">
          {!isAuthorized ? <CreateAccount /> : <LoginSuccessful />}
          <div>
            <Cart
              totalAmount={totalPrice}
              isPaySectionVisible={totalPrice > 0}
              cartItemList={cartItemList}
              onProductDelete={removeProduct}
              onPay={handlePay}
              isAuthorized={isAuthorized}
              isLoading={isLoading}
            />
          </div>
        </main>
      </OnboardingLayout>
    </>
  );
};
