import { BlockProperties } from "grapesjs";

export const separator: BlockProperties = {
  id: "separator",
  media: `<svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.381 23.8571L20.6667 28.1428L24.9524 23.8571" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><rect x="1.16669" y="12.2143" width="39" height="4.71429" rx="2.35714" fill="#27272A" stroke="#71717A"/><path d="M16.381 5.28571L20.6667 0.999992L24.9524 5.28571" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  label: "Separator",
  category: "Layout",
  content: {
    type: "Separator",
  },
  select: true,
};
