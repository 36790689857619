import { type CSSProperties } from "react";

type TFigmaGray = {
  className?: string;
  style?: CSSProperties;
};

export const FigmaGray = ({ className, style }: TFigmaGray) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1.8C2 0.80589 2.80589 0 3.8 0H8C8.99414 0 9.8 0.80589 9.8 1.8C9.8 2.79411 8.99414 3.6 8 3.6H3.8C2.80589 3.6 2 2.79411 2 1.8ZM6.2 6C6.2 5.00586 7.00586 4.2 8 4.2C8.99414 4.2 9.8 5.00586 9.8 6C9.8 6.99414 8.99414 7.8 8 7.8C7.00586 7.8 6.2 6.99414 6.2 6ZM2 6C2 5.00586 2.80589 4.2 3.8 4.2H5.6V7.8H3.8C2.80589 7.8 2 6.99414 2 6ZM2 10.2C2 9.20586 2.80589 8.4 3.8 8.4H5.6V10.2C5.6 11.1941 4.79414 12 3.8 12C2.80589 12 2 11.1941 2 10.2Z"
      fill="#7F7F94"
    />
  </svg>
);
