import { BlockProperties } from "grapesjs";

export const linkBlock: BlockProperties = {
  id: "link-block",
  media: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="#27272A"/><rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#71717A"/><path class="light-stroke" d="M23 15H26C26.6566 15 27.3068 15.1293 27.9134 15.3806C28.52 15.6319 29.0712 16.0002 29.5355 16.4645C29.9998 16.9288 30.3681 17.48 30.6194 18.0866C30.8707 18.6932 31 19.3434 31 20C31 20.6566 30.8707 21.3068 30.6194 21.9134C30.3681 22.52 29.9998 23.0712 29.5355 23.5355C29.0712 23.9998 28.52 24.3681 27.9134 24.6194C27.3068 24.8707 26.6566 25 26 25H23M17 25H14C13.3434 25 12.6932 24.8707 12.0866 24.6194C11.48 24.3681 10.9288 23.9998 10.4645 23.5355C9.52678 22.5979 9 21.3261 9 20C9 18.6739 9.52678 17.4021 10.4645 16.4645C11.4021 15.5268 12.6739 15 14 15H17M16 20H24" stroke="#D4D4D8" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  label: "Link",
  category: "Layout",
  content: {
    type: "link-box",
    components: [{ type: "text", tagName: "span", content: "Link Box" }],
  },
  select: true,
};
