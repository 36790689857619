import { BlockProperties } from "grapesjs";

export const text: BlockProperties = {
  id: "text",
  media: `<svg width="42" height="23" viewBox="0 0 42 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M5.48441 22H1.26566L8.94677 0.181818H13.826L21.5178 22H17.299L11.4716 4.65625H11.3012L5.48441 22ZM5.62291 13.4453H17.1286V16.62H5.62291V13.4453ZM24.3916 22V0.181818H28.2481V8.34233H28.4079C28.6068 7.9446 28.8873 7.52202 29.2495 7.07457C29.6117 6.62003 30.1018 6.23295 30.7197 5.91335C31.3376 5.58665 32.1259 5.4233 33.0847 5.4233C34.3489 5.4233 35.4889 5.74645 36.5045 6.39276C37.5272 7.03196 38.3369 7.98011 38.9335 9.23722C39.5371 10.4872 39.839 12.0213 39.839 13.8395C39.839 15.6364 39.5443 17.1634 38.9548 18.4205C38.3653 19.6776 37.5627 20.6364 36.5471 21.2969C35.5315 21.9574 34.3809 22.2876 33.0954 22.2876C32.1579 22.2876 31.3802 22.1314 30.7623 21.8189C30.1444 21.5064 29.6472 21.13 29.2708 20.6896C28.9015 20.2422 28.6139 19.8196 28.4079 19.4219H28.1842V22H24.3916ZM28.1735 13.8182C28.1735 14.8764 28.3227 15.8033 28.621 16.5987C28.9264 17.3942 29.3631 18.0156 29.9313 18.4631C30.5066 18.9034 31.2026 19.1236 32.0194 19.1236C32.8717 19.1236 33.5854 18.8963 34.1607 18.4418C34.736 17.9801 35.1693 17.3516 35.4604 16.5561C35.7587 15.7536 35.9079 14.8409 35.9079 13.8182C35.9079 12.8026 35.7623 11.9006 35.4711 11.1122C35.1799 10.3239 34.7467 9.70597 34.1714 9.25852C33.5961 8.81108 32.8788 8.58736 32.0194 8.58736C31.1955 8.58736 30.496 8.80398 29.9207 9.23722C29.3454 9.67045 28.9086 10.2777 28.6103 11.0589C28.3191 11.8402 28.1735 12.7599 28.1735 13.8182Z" fill="#D4D4D8"/></svg>`,
  label: "Text block",
  category: "Layout",
  content: {
    type: "text",
    tagName: "span",
    content: "Insert your text here",
  },
  select: true,
};
