import posthog from "posthog-js";

if (import.meta.env.VITE_POST_HOG_KEY) {
  //Create a new instance of PostHog with your API key
  posthog.init(import.meta.env.VITE_POST_HOG_KEY, {
    api_host: "https://eu.i.posthog.com",
    person_profiles: "always", // or 'always' to create profiles for anonymous users as well
  });
} else {
  console.error("[VALID ONLY ON PROD] Analytics key is not provided");
}

// Send a page view notification to analytics service
export const sendPageViewNotification = () => {
  posthog.capture("$pageview");
};

export const getBlacklistedComponents = async (): Promise<string[]> => {
  return new Promise((res, rej) => {
    try {
      posthog.onFeatureFlags(function () {
        // feature flags should be available at this point
        if (posthog.isFeatureEnabled("disabled-components")) {
          // do something
          const payload = posthog.getFeatureFlagPayload("disabled-components") as { blackList: string[] };
          res(payload.blackList);
        }
        rej([]);
      });
    } catch (e) {
      rej([]);
    }
  });
};
