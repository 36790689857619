// MobileLoader.tsx
import React from "react";
import "./MobileLoader.css";

const MobileLoader: React.FC = () => {
  return (
    <div className="mobile-loader">
      <div className="mobile-loader__spinner"></div>
      <p className="mobile-loader__message">Copying template to projects...</p>
    </div>
  );
};

export default MobileLoader;
