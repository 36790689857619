const codejetComponents = [
  ".codejet_accordion",
  ".codejet_alert",
  ".codejet_button",
  ".codejet_checkbox",
  ".codejet_chips",
  ".codejet_input",
  ".codejet_pagination-",
  ".codejet_pagination_button",
  ".codejet_pagination_separator",
  ".codejet_radio",
  ".codejet_switch",
  ".codejet_tags",
  ".codejet_textfield",
];

// Helper function to extract and remove blocks matching the regex
const extractBlocks = (regex: RegExp, styles: string) => {
  let match;
  let innerStyle = styles;
  let innerComponents = "";
  while ((match = regex.exec(styles)) !== null) {
    innerComponents += match[0] + "\n";
    innerStyle = innerStyle.replace(match[0], "");
  }

  if (innerComponents === "") return { styles: styles, components: "" };

  return {
    styles: innerStyle,
    components: innerComponents,
  };
};

export function extractComponents(css: string) {
  let components = "";
  let styles = css;
  const mediaQueries: Record<string, string> = {};

  const mediaQueryRegex = /@media\s*[^{]*\{([^}]*\{[^}]*\})*[^}]*\}/g;
  let match;
  while ((match = mediaQueryRegex.exec(styles)) !== null) {
    const key = match[0].match(/@media[^{]+/)?.[0];
    if (!key) continue;

    // Store the entire content inside the media query
    mediaQueries[key] = match[0].replace(key + "{", "").slice(0, -1);
    styles = styles.replace(match[0], "");
    mediaQueryRegex.lastIndex = 0; // Reset regex index to handle overlapping matches
  }

  // Create and apply regex for each class in classList
  codejetComponents?.forEach((cls) => {
    const classRegex = new RegExp(`\\${cls}[^{]*\{[^}]*\}`, "g");
    const { styles: woComponents, components: singleComponentStyles } = extractBlocks(classRegex, styles);
    components += singleComponentStyles;
    styles = woComponents;
  });

  // used styles removed on begin of the function, so @media classes are not removed from @media block
  Object.entries(mediaQueries).forEach(([key, value]) => {
    let mediaComponents = "";
    let mediaStyles = value;
    // Create and apply regex for each class in classList
    codejetComponents?.forEach((cls) => {
      const classRegex = new RegExp(`\\${cls}[^{]*\{[^}]*\}`, "g");
      const { styles: woMediaComponents, components: mediaComponent } = extractBlocks(classRegex, mediaStyles);
      mediaComponents += mediaComponent;
      mediaStyles = woMediaComponents;
    });
    // added media components to the components
    components += `${key}{${mediaComponents}}`;

    if (mediaStyles !== "") {
      // add media styles without components back to the styles
      styles += `${key}{${mediaStyles}}`;
    }
  });

  return {
    styles: styles,
    components: components,
  };
}
