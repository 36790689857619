import { BlockProperties } from "grapesjs";

export const block2Rows: BlockProperties = {
  id: "block-2-rows",
  media: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.16675" y="0.5" width="39" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="1.16675" y="21.5" width="39" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>`,
  label: "2 rows",
  category: "Layout",
  content: {
    type: "cjRow",
    components: [
      {
        type: "cjRow",
      },
      {
        type: "cjRow",
      },
    ],
  },
  select: true,
};
