import { ComponentDefinition } from "grapesjs";

import {
  staticBlockConfig,
  smartComponentNavbarMenuItemClass,
  smartComponentNavbarMenuLinkClass,
} from "../block-helpers";

/**
 * Generates a component definition for a navbar link item.
 *
 * @param {string} content - The text content for the navbar link.
 * @returns {ComponentDefinition} The component definition object for the navbar link item.
 */
export const blockPartialSCNavbarLink = (content: string): ComponentDefinition => {
  return {
    tagName: "li",
    layername: "Menu part",
    classes: [smartComponentNavbarMenuItemClass],
    ...staticBlockConfig,
    components: [
      {
        type: "link",
        content: content,
        classes: [smartComponentNavbarMenuLinkClass],
        contentEditable: false,
        ...staticBlockConfig,
      },
    ],
  };
};
