import { BlockProperties } from "grapesjs";

import {
  block1Col,
  block2ColEven,
  block3ColEven,
  blockGrid,
  block2Rows,
  block3Rows,
  separator,
  image,
  imageBlock,
  CODEJETVideo,
  map,
  icon,
  heading,
  text,
  link,
  linkBlock,
  CJInput,
  textArea,
  select,
  button,
  label,
  checkbox,
  radio,
  smartComponentForm,
  smartComponentNavbar,
  smartComponentGallery,
} from "./block-definitions";

export const blocksProperties: BlockProperties[] = [
  block1Col,
  block2ColEven,
  block3ColEven,
  blockGrid,
  block2Rows,
  block3Rows,
  separator,
  image,
  imageBlock,
  CODEJETVideo,
  map,
  icon,
  heading,
  text,
  link,
  linkBlock,
  smartComponentForm,
  CJInput,
  textArea,
  select,
  button,
  label,
  checkbox,
  radio,
  smartComponentNavbar,
  smartComponentGallery,
];
