import { BlockProperties } from "grapesjs";

export const block3Rows: BlockProperties = {
  id: "block-3-rows",
  media: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="39" height="11" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="0.5" y="14.5" width="39" height="11" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="0.5" y="28.5" width="39" height="11" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>`,
  label: "3 rows",
  category: "Layout",
  content: {
    type: "cjRow",
    components: [
      {
        type: "cjRow",
      },
      {
        type: "cjRow",
      },
      {
        type: "cjRow",
      },
    ],
  },
  select: true,
};
