import { useCallback, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { projectsService } from "../../../../../../../machines/projects/projects.instance.ts";
import { modalMachineInstance } from "../../index.ts";
import { assetManagerInstance } from "../../../asset-manager";
import { stylesManagerInstance } from "../../../styles-manager/index.ts";

export const MyDropzone = ({
  autoFilePickerOpen = true,
  acceptedTypes,
}: {
  autoFilePickerOpen?: boolean;
  acceptedTypes: Record<string, string[]>;
}) => {
  const isOpenFlag = useRef<boolean>(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("files", file);
    });
    const activeProject = projectsService.getActiveProject();
    const projectId = activeProject?.projectId;

    if (!projectId) return;

    //TODO move to state machine and service
    const data = await axios.post(`/api/project-assets/upload/${projectId.replace("/", "%2F")}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    assetManagerInstance.send("SET_CURRENT_COMPONENT_SRC", { files: data.data.newFile });
    modalMachineInstance.send("CLOSE");
    isOpenFlag.current = false;
  }, []);

  const { getRootProps, getInputProps, isDragActive, open, rootRef } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    maxFiles: 5,
    onFileDialogCancel: () => {
      isOpenFlag.current = false;
      assetManagerInstance.send("CLOSE_MODAL");
      stylesManagerInstance.send("CANCEL_BG_CHANGE");
    },
  });

  useEffect(() => {
    if (!rootRef.current) return;

    if (rootRef.current.role === "presentation") {
      rootRef.current?.focus();
    }
  }, [rootRef.current]);

  useEffect(() => {
    if (autoFilePickerOpen) {
      if (isOpenFlag.current === true) return;
      open();
      isOpenFlag.current = true;
    }
  }, [autoFilePickerOpen]);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
    </div>
  );
};
