import { BlockProperties } from "grapesjs";

export const imageBlock: BlockProperties = {
  id: "image-block",
  media: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.833252" y="0.5" width="39" height="39" rx="3.5" fill="#27272A"/><rect x="0.833252" y="0.5" width="39" height="39" rx="3.5" stroke="#71717A"/><path class="light-stroke" d="M13.3333 29H27.3333C28.4378 29 29.3333 28.1046 29.3333 27V13C29.3333 11.8954 28.4378 11 27.3333 11H13.3333C12.2287 11 11.3333 11.8954 11.3333 13V27C11.3333 28.1046 12.2287 29 13.3333 29ZM13.3333 29L24.3333 18L29.3333 23M18.3333 16.5C18.3333 17.3284 17.6617 18 16.8333 18C16.0048 18 15.3333 17.3284 15.3333 16.5C15.3333 15.6716 16.0048 15 16.8333 15C17.6617 15 18.3333 15.6716 18.3333 16.5Z" stroke="#D4D4D8" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  label: "Image block",
  category: "Layout",
  content: {
    type: "cjRow",
    components: [{ type: "image" }],
  },
  select: true,
};
