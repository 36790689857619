import { modalMachineInstance } from "../../components/pages/Alpha_O/modules/modal";

type TriggerTypes =
  | "publish"
  | "closingTab"
  | "openPreview"
  | "changeContent"
  | "addPage"
  | "deletePage"
  | "addComponents"
  | "deleteComponents"
  | "setDesktopView";

type EditorComponentsTriggers = "changeContent" | "addComponents" | "deleteComponents";

const initialState: Map<TriggerTypes, number> = new Map([
  ["publish", 0],
  ["closingTab", 0],
  ["openPreview", 0],
  ["changeContent", 0],
  ["addPage", 0],
  ["deletePage", 0],
  ["addComponents", 0],
  ["deleteComponents", 0],
  ["setDesktopView", 0],
]);

type TTriggerContentMap = {
  [key in TriggerTypes]: { title: string; description: string };
};

const triggerModalContentMap: TTriggerContentMap = {
  publish: {
    title: "Want to publish your website?",
    description: "To publish your website, you need to create an account in our service",
  },
  closingTab: {
    title: "Do you want to leave our editor?",
    description: "To ensure you don't lose your project, create an account in our service",
  },
  openPreview: {
    title: "Did you like our editor?",
    description: "Would you like to create an account to come back to your project later?",
  },
  changeContent: {
    title: "Would you like to save your modifications?",
    description: "To ensure you don't lose your changes, create an account in our service",
  },
  addComponents: {
    title: "Would you like to save your modifications?",
    description: "To ensure you don't lose your changes, create an account in our service",
  },
  deleteComponents: {
    title: "Did you like our editor?",
    description: "Would you like to create an account to come back to your project later?",
  },
  addPage: {
    title: "Would you like to save your modifications?",
    description: "To ensure you don't lose your changes, create an account in our service",
  },
  deletePage: {
    title: "Would you like to save your modifications?",
    description: "To ensure you don't lose your changes, create an account in our service",
  },
  setDesktopView: {
    title: "Did you like our editor?",
    description: "Would you like to create an account to come back to your project later?",
  },
};

export class TriggerService {
  private static instance: TriggerService;
  private static trigger: TriggerTypes | undefined = undefined;
  private static modalContent: { title: string; description: string } | undefined = undefined;
  private triggersMap: Map<TriggerTypes, number>;
  private eachTimeTrigger = ["publish", "closingTab", "addPage", "deletePage"];
  private everyThirdTrigger = ["addComponents", "changeContent"];
  private firstOrThirdTrigger = ["openPreview", "deleteComponents", "setDesktopView"];

  private constructor() {
    this.triggersMap = new Map(initialState);
  }

  public static getInstance(): TriggerService {
    if (!TriggerService.instance) {
      TriggerService.instance = new TriggerService();
    }
    return TriggerService.instance;
  }

  firstOrThird = (newCount: number): boolean => {
    return newCount === 1 || (newCount - 1) % 3 === 0;
  };

  public setTrigger = (trigger: EditorComponentsTriggers): void => {
    TriggerService.trigger = trigger;
  };

  public omitChangeTrigger = (): boolean => {
    return TriggerService.trigger === "addComponents";
  };

  public increment(trigger: TriggerTypes): void {
    const currentCount = this.triggersMap.get(trigger) || 0;
    const newCount = currentCount + 1;
    this.triggersMap.set(trigger, currentCount + 1);

    TriggerService.modalContent = triggerModalContentMap[trigger];

    if (this.firstOrThirdTrigger.includes(trigger) && this.firstOrThird(newCount)) {
      modalMachineInstance.send("OPEN", { modalId: "signUp" });
      return;
    }

    if (this.everyThirdTrigger.includes(trigger) && newCount % 3 === 0) {
      modalMachineInstance.send("OPEN", { modalId: "signUp" });
      return;
    }

    if (this.eachTimeTrigger.includes(trigger)) {
      modalMachineInstance.send("OPEN", { modalId: "signUp" });
    }
  }

  public onStoreChange = (): void => {
    if (!TriggerService.trigger) return;

    this.increment(TriggerService.trigger);
    TriggerService.trigger = undefined;
  };

  public getCount(trigger: TriggerTypes): number {
    return this.triggersMap.get(trigger) || 0;
  }

  public getModalContent(): { title: string; description: string } | undefined {
    return TriggerService.modalContent;
  }

  public resetTriggersMap(): void {
    TriggerService.trigger = undefined;
    this.triggersMap = new Map(initialState);
  }
}
