import { BlockProperties } from "grapesjs";

export const CODEJETVideo: BlockProperties = {
  id: "CODEJET-video",
  media: `<svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_23268_5589)"><path class="dark-fill" d="M30.6654 3.3335L21.332 10.0002L30.6654 16.6668V3.3335Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path class="dark-fill" d="M18.6654 0.666504H3.9987C2.52594 0.666504 1.33203 1.86041 1.33203 3.33317V16.6665C1.33203 18.1393 2.52594 19.3332 3.9987 19.3332H18.6654C20.1381 19.3332 21.332 18.1393 21.332 16.6665V3.33317C21.332 1.86041 20.1381 0.666504 18.6654 0.666504Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_23268_5589"><rect width="32" height="20" fill="white"/></clipPath></defs></svg>`,
  label: "Video",
  category: "Layout",
  content: {
    type: "codejetVideo",
    label: "Video",
  },
  select: true,
};
