import { PropertyNumber, PropertySelect, PropertyTypes, SectorProperties } from "grapesjs";
import { getValidDir, parseGradient, toGradient } from "grapesjs-style-gradient";

import { safeFonts } from "../../../../AlphaO/molecules/FontMode/constants";

const capitalize = (str: string) => {
  return str && str.charAt(0).toUpperCase() + str.substring(1);
};

const processBackgroundImage = (
  input: string
): { type: string; value: string; direction?: string; gradientType?: string } => {
  const trimmed = input.trim().replace(/,$/, "");
  if (trimmed.startsWith("url") || trimmed === "image") {
    return { type: "image", value: trimmed };
  } else if (trimmed.indexOf("gradient(") > -1) {
    const parsed = parseGradient(trimmed);
    const { stops } = parsed;

    if (stops.length === 2 && parsed.type === "linear" && stops[0].color === stops[1].color) {
      return { type: "color", value: stops[0].color };
    }

    const direction = getValidDir(parsed.direction) || "right";

    return { type: "gradient", value: trimmed, direction: direction, gradientType: parsed.type };
  }

  return { type: "color", value: "rgba(0, 255, 0, 1)" };
};

export type ExtendedPropertyTypes = PropertyTypes & {
  properties?: PropertyTypes[];
};

type CustomSectorProperties = Omit<SectorProperties, "properties"> & {
  properties?: ExtendedPropertyTypes[];
};

export const sectorsConfig: CustomSectorProperties[] = [
  {
    name: "Layout",
    id: "codejet-layout",
    open: false,
    properties: [
      {
        id: "display",
        name: "Display",
        property: "display",
        type: "select",
        defaults: "block",
        className: "full-width",
        options: [
          { id: "block", value: "block", name: "Block", label: "Block" },
          { id: "inline", value: "inline", name: "Inline", label: "Inline" },
          { id: "inline-block", value: "inline-block", name: "Inline Block", label: "Inline Block" },
          { id: "flex", value: "flex", name: "Flex", label: "Flex" },
          { id: "none", value: "none", name: "None", label: "None" },
        ],
      },
      {
        id: "flex-direction",
        name: "Directions",
        property: "flex-direction",
        type: "radio",
        defaults: "row",
        className: "full-width",
        options: [
          { id: "row", value: "row", name: "DirectionRow", label: "Row" },
          {
            id: "row-reverse",
            value: "row-reverse",
            name: "DirectionRowReverse",
            label: "Row Reverse",
          },
          { id: "column", value: "column", name: "DirectionColumn", label: "Column" },
          {
            id: "column-reverse",
            value: "column-reverse",
            name: "DirectionColumnReverse",
            label: "Column Reverse",
          },
        ],
        requires: { display: "flex" },
      },
      {
        name: "Justify",
        property: "justify-content",
        type: "radio",
        defaults: "flex-start",
        className: "full-width",
        options: [
          { id: "flex-start", value: "flex-start", name: "JustifyStart", label: "Flex Start" },
          { id: "center", value: "center", name: "JustifyCenter", label: "Center" },
          { id: "flex-end", value: "flex-end", name: "JustifyEnd", label: "Flex End" },
          { id: "space-between", value: "space-between", name: "JustifyBetween", label: "Space Between" },
          { id: "space-around", value: "space-around", name: "JustifyAround", label: "Space Around" },
          { id: "space-evenly", value: "space-evenly", name: "JustifyEvenly", label: "Space Evenly" },
        ],
        requires: { display: "flex" },
      },
      {
        name: "Align",
        property: "align-items",
        type: "radio",
        defaults: "stretch",
        className: "full-width",
        options: [
          { id: "stretch", value: "stretch", name: "AlignStretch", label: "Stretch" },
          { id: "flex-start", value: "flex-start", name: "AlignStart", label: "Flex Start" },
          { id: "center", value: "center", name: "AlignCenter", label: "Center" },
          { id: "flex-end", value: "flex-end", name: "AlignEnd", label: "Flex End" },
        ],
        requires: { display: "flex" },
      },
      {
        name: "Gap",
        property: "gap",
        type: "composite",
        defaults: "",
        className: "full-width",
        units: ["px", "%", "em", "rem", "vh", "vw"],
        properties: [
          {
            name: "Row",
            property: "row-gap",
            type: "number",
            defaults: "0",
            unit: "px",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Column",
            property: "column-gap",
            type: "number",
            defaults: "0",
            unit: "px",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
        ],
        requires: { display: "flex" },
      },
      {
        name: "Wrap",
        property: "flex-wrap",
        type: "select",
        defaults: "nowrap",
        className: "full-width",
        options: [
          { id: "nowrap", value: "nowrap", name: "WrapNo", label: "No Wrap" },
          { id: "wrap", value: "wrap", name: "WrapYes", label: "Wrap" },
          { id: "wrap-reverse", value: "wrap-reverse", name: "WrapReverse", label: "Wrap Reverse" },
        ],
        requires: { display: "flex" },
      },
      {
        name: "Align content",
        property: "align-content",
        type: "radio",
        defaults: "",
        className: "rotate-90 full-width",
        options: [
          { id: "flex-start", value: "flex-start", name: "JustifyStart", label: "Flex Start" },
          { id: "center", value: "center", name: "JustifyCenter", label: "Center" },
          { id: "flex-end", value: "flex-end", name: "JustifyEnd", label: "Flex End" },
          { id: "space-between", value: "space-between", name: "JustifyBetween", label: "Space Between" },
          { id: "space-around", value: "space-around", name: "JustifyAround", label: "Space Around" },
          { id: "stretch", value: "stretch", name: "AlignContentStretch", label: "Stretch" },
        ],
        requires: { display: "flex", "flex-wrap": "wrap" },
      },
      {
        name: "Align",
        property: "align-self",
        type: "radio",
        defaults: "",
        className: "full-width",
        options: [
          { id: "auto", value: "auto", name: "Auto", label: "Auto" },
          { id: "flex-start", value: "flex-start", name: "AlignStart", label: "Flex Start" },
          { id: "center", value: "center", name: "AlignCenter", label: "Center" },
          { id: "flex-end", value: "flex-end", name: "AlignEnd", label: "Flex End" },
          { id: "stretch", value: "stretch", name: "AlignStretch", label: "Stretch" },
        ],
      },
      {
        name: "Order",
        property: "order",
        type: "number",
        defaults: "0",
        className: "full-width horizontal",
      },
    ],
  },
  {
    name: "Size",
    id: "codejet-sector",
    open: false,
    buildProps: ["width", "height", "max-width", "max-height", "min-width", "min-height"],
  },
  {
    name: "Space",
    id: "codejet-space",
    open: false,
    properties: [
      {
        property: "padding",
        name: "Padding",
        type: "composite",
        defaults: "0",
        className: "full-width",
        detached: true,
        properties: [
          {
            name: "Top",
            property: "padding-top",
            type: "number",
            icon: "SpaceTop",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Right",
            property: "padding-right",
            type: "number",
            icon: "SpaceRight",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Bottom",
            property: "padding-bottom",
            type: "number",
            icon: "SpaceBottom",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Left",
            property: "padding-left",
            type: "number",
            icon: "SpaceLeft",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
        ],
      },
      {
        property: "margin",
        name: "Margin",
        type: "composite",
        defaults: "0",
        className: "full-width",
        detached: true,
        properties: [
          {
            name: "Top",
            property: "margin-top",
            type: "number",
            icon: "SpaceTop",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Right",
            property: "margin-right",
            type: "number",
            icon: "SpaceRight",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Bottom",
            property: "margin-bottom",
            type: "number",
            icon: "SpaceBottom",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Left",
            property: "margin-left",
            type: "number",
            icon: "SpaceLeft",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
        ],
      },
    ],
  },
  {
    name: "Position",
    id: "codejet-position",
    open: false,
    properties: [
      {
        name: "Position",
        property: "position",
        info: "Select the position type",
        type: "select",
        defaults: "static",
        className: "full-width",
        options: [
          { id: "static", value: "static", name: "Static", label: "Static" },
          { id: "relative", value: "relative", name: "Relative", label: "Relative" },
          { id: "absolute", value: "absolute", name: "Absolute", label: "Absolute" },
          { id: "fixed", value: "fixed", name: "Fixed", label: "Fixed" },
          { id: "sticky", value: "sticky", name: "Sticky", label: "Sticky" },
        ],
        onChange: (property) => {
          // Retrieve the new position value
          const selectedValue = property.to.value;

          // Update the info based on the position value
          let newInfo = "";
          switch (selectedValue) {
            case "static":
              newInfo = "Default position";
              break;
            case "relative":
              newInfo = "Similar to Static but with the ability to be moved relative to itself";
              break;
            case "absolute":
              newInfo = "Absolute position, relative to its closest non-static parent";
              break;
            case "fixed":
              newInfo = "Fixed position (even when the page is scrolled), relative to the browser viewport";
              break;
            case "sticky":
              newInfo = "Elements are keept in place but moved when the page is scrolled, based on indicated distance";
              break;
            default:
              newInfo = "Select the position type";
          }

          // Set the new info value
          property.property.set("info", newInfo);
        },
      },
      {
        name: "Left",
        property: "left",
        type: "number",
        defaults: "auto",
        units: ["px", "%", "em", "rem", "vh", "vw"],
        requires: { position: ["relative", "absolute", "fixed", "sticky"] },
      },
      {
        name: "Top",
        property: "top",
        type: "number",
        defaults: "auto",
        units: ["px", "%", "em", "rem", "vh", "vw"],
        requires: { position: ["relative", "absolute", "fixed", "sticky"] },
      },
      {
        name: "Right",
        property: "right",
        type: "number",
        defaults: "auto",
        units: ["px", "%", "em", "rem", "vh", "vw"],
        requires: { position: ["relative", "absolute", "fixed", "sticky"] },
      },
      {
        name: "Bottom",
        property: "bottom",
        type: "number",
        defaults: "auto",
        units: ["px", "%", "em", "rem", "vh", "vw"],
        requires: { position: ["relative", "absolute", "fixed", "sticky"] },
      },
      {
        name: "Z-Index",
        property: "z-index",
        type: "number",
        defaults: "auto",
        className: "full-width",
        requires: { position: ["relative", "absolute", "fixed", "sticky"] },
      },
    ],
  },
  {
    name: "Typography",
    id: "codejet-typography",
    open: false,
    properties: [
      {
        name: "Font",
        property: "font-family",
        type: "select",
        defaults: "",
        className: "vertical full-width",
        options: safeFonts.map((font) => {
          return {
            id: font.label,
            value: font.label,
            name: font.label,
            label: font.label,
          };
        }),
      },
      {
        name: "Color",
        property: "color",
        type: "color",
        defaults: "",
        className: "full-width",
      },
      {
        name: "Size",
        property: "font-size",
        type: "number",
        defaults: "16",
        className: "full-width",
        units: ["px", "em", "rem"],
      },
      {
        name: "Weight",
        property: "font-weight",
        type: "select",
        defaults: "normal",
        className: "vertical full-width",
        options: [
          { id: "thin", value: "100", name: "Thin", label: "Thin" },
          { id: "extra-light", value: "200", name: "extra-light", label: "Extra-light" },
          { id: "light", value: "300", name: "light", label: "Light" },
          { id: "normal", value: "400", name: "Normal", label: "Normal" },
          { id: "medium", value: "500", name: "Medium", label: "Medium" },
          { id: "semi-bold", value: "600", name: "Semi-bold", label: "Semi-bold" },
          { id: "bold", value: "700", name: "bold", label: "Bold" },
          { id: "extra-bold", value: "800", name: "Extra-bold", label: "Extra-bold" },
          { id: "ultra-bold", value: "900", name: "Ultra-bold", label: "Ultra-bold" },
        ],
      },
      {
        name: "Line Height",
        property: "line-height",
        type: "number",
        defaults: "1.5",
        unit: "px",
        units: ["px", "em", "rem", "%"],
      },
      {
        name: "Spacing",
        property: "letter-spacing",
        type: "number",
        defaults: "auto",
        unit: "px",
        units: ["px", "em", "rem"],
      },
      {
        name: "Align",
        property: "text-align",
        type: "select",
        defaults: "",
        className: "vertical",
        options: [
          { id: "none", value: "remove", name: "remove", label: "Auto" },
          { id: "left", value: "left", name: "Left", label: "Left" },
          { id: "center", value: "center", name: "Center", label: "Center" },
          { id: "right", value: "right", name: "Right", label: "Right" },
          { id: "justify", value: "justify", name: "Justify", label: "Justify" },
        ],
      },
      {
        name: "Decoration",
        property: "text-decoration",
        type: "select",
        defaults: "",
        className: "vertical",
        options: [
          { id: "none", value: "remove", name: "remove", label: "Auto" },
          { id: "none", value: "none", name: "None", label: "None" },
          { id: "underline", value: "underline", name: "Underline", label: "Underline" },
          { id: "overline", value: "overline", name: "Overline", label: "Overline" },
          { id: "line-through", value: "line-through", name: "LineThrough", label: "Line Through" },
        ],
      },
      {
        name: "Transform",
        property: "text-transform",
        type: "select",
        defaults: "",
        className: "vertical",
        options: [
          { id: "none", value: "remove", name: "remove", label: "Auto" },
          { id: "none", value: "none", name: "None", label: "None" },
          { id: "capitalize", value: "capitalize", name: "Capitalize", label: "Capitalize" },
          { id: "uppercase", value: "uppercase", name: "Uppercase", label: "Uppercase" },
          { id: "lowercase", value: "lowercase", name: "Lowercase", label: "Lowercase" },
        ],
      },
      {
        name: "Direction",
        property: "direction",
        type: "select",
        defaults: "",
        className: "vertical",
        options: [
          { id: "none", value: "remove", name: "remove", label: "Auto" },
          { id: "ltr", value: "ltr", name: "LTR", label: "LTR" },
          { id: "rtl", value: "rtl", name: "RTL", label: "RTL" },
        ],
      },
      {
        name: "Breaking",
        property: "word-break",
        type: "select",
        defaults: "",
        className: "full-width vertical",
        options: [
          { id: "none", value: "remove", name: "remove", label: "Auto" },
          { id: "normal", value: "normal", name: "Normal", label: "Normal" },
          { id: "break-all", value: "break-all", name: "BreakAll", label: "Break All" },
          { id: "keep-all", value: "keep-all", name: "KeepAll", label: "Keep All" },
        ],
      },
    ],
  },
  {
    name: "Background",
    id: "codejet-background",
    open: false,
    properties: [
      {
        name: "Background color",
        property: "background-color",
        type: "color",
        defaults: "",
        className: "full-width",
      },
      {
        name: "Clip",
        property: "background-clip",
        type: "select",
        defaults: "",
        className: "vertical full-width",
        options: [
          { id: "none", value: "remove", name: "remove", label: "Auto" },
          { id: "border-box", value: "border-box", name: "BorderBox", label: "Border Box" },
          { id: "padding-box", value: "padding-box", name: "PaddingBox", label: "Padding Box" },
          { id: "content-box", value: "content-box", name: "ContentBox", label: "Content Box" },
        ],
      },
      {
        name: "Background",
        property: "background",
        type: "stack",
        detached: true,
        layerLabel: (l, { values }) => {
          return capitalize(values["background-type"]);
        },
        fromStyle: (style, { property }) => {
          type LayerProperties = {
            "background-type": string;
            "background-image"?: string;
            "background-position": string;
            "background-size": string;
            "background-repeat": string;
            "background-attachment": string;
            "background-origin": string;
            "background-image-gradient"?: string;
            "background-image-color"?: string;
            "background-gradient-direction"?: null | string;
            "background-gradient-type"?: null | string;
          };
          type LayerProperty = keyof LayerProperties;

          const createDefaultLayer = (): LayerProperties => ({
            "background-type": "image",
            "background-image": "url()",
            "background-position": "top left",
            "background-size": "cover",
            "background-repeat": "repeat",
            "background-attachment": "scroll",
            "background-origin": "padding-box",
            "background-image-gradient": "",
            "background-image-color": "",
            "background-gradient-direction": null,
            "background-gradient-type": null,
          });

          const backgroundProperties = property
            .getProperties()
            .map((prop) => prop.attributes.property || "") as LayerProperty[];

          let sep = property.getLayerSeparator();

          return backgroundProperties.reduce((layers: LayerProperties[], prop) => {
            const value = style[prop];

            if (typeof value !== "string") return layers;

            const separator = prop === "background-image" ? /(?<!\(.*[^)]),(?![^(]*\))/ : sep;

            value.split(separator).forEach((input, idx) => {
              if (input === "") return;

              if (!layers[idx]) {
                layers[idx] = createDefaultLayer();
              }

              if (prop === "background-image") {
                const { type, value, direction, gradientType } = processBackgroundImage(input);
                if (type === "image") {
                  layers[idx]["background-image"] = value;
                  layers[idx]["background-type"] = "image";
                } else if (type === "gradient") {
                  layers[idx]["background-image-gradient"] = value;
                  layers[idx]["background-type"] = "gradient";
                  if (direction) {
                    layers[idx]["background-gradient-direction"] = direction;
                  }
                  if (gradientType) {
                    layers[idx]["background-gradient-type"] = gradientType;
                  }
                } else {
                  layers[idx]["background-image-color"] = value;
                  layers[idx]["background-type"] = "color";
                  layers[idx]["background-image-gradient"] =
                    "linear-gradient(to right, rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 1) 100%)";
                }
              } else {
                layers[idx][prop] = input.trim();
              }

              // remove empty values
              Object.keys(layers[idx]).forEach((key) => {
                const layerKey = key as LayerProperty;
                if (layers[idx][layerKey] === "") {
                  delete layers[idx][layerKey];
                }
              });
            });

            return layers;
          }, []);
        },
        toStyle: (values, data) => {
          // This function converts the input values object into a style object
          // It takes the background-related properties from the values object
          // and returns them as a new object with CSS property names

          const style: Record<string, string> = {};
          const type = values["background-type"];

          if (values["background-image"]) {
            style["background-image"] = values["background-image"];
          }

          if (type === "color") {
            style["background-image"] = values["background-image-color"]
              ? `linear-gradient(to right, ${values["background-image-color"]} 0%, ${values["background-image-color"]} 100%)`
              : `linear-gradient(to right, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 1) 100%)`;
          }

          if (type === "gradient") {
            const isGradientPropertyEmpty =
              !("background-image-gradient" in values) || values["background-image-gradient"] === "";

            const parsed = isGradientPropertyEmpty
              ? parseGradient("")
              : parseGradient(values["background-image-gradient"]);
            const direction = values["background-gradient-direction"] || getValidDir(parsed.direction) || "to right";
            const gradientType = values["background-gradient-type"] || parsed.type;

            const isRadialToColor =
              !isGradientPropertyEmpty &&
              gradientType.includes("radial") &&
              parsed.type.includes("radial") &&
              parsed.stops.length > 0 &&
              !parsed.stops[0].position.includes("%");
            const isLinearToColor =
              !isGradientPropertyEmpty &&
              gradientType.includes("linear") &&
              parsed.type.includes("linear") &&
              parsed.stops.length > 0 &&
              !parsed.stops[0].position.includes("%");

            if (isGradientPropertyEmpty) {
              // if for eg we come from color to gradient
              style[
                "background-image"
              ] = `linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%)`;
            } else if (isLinearToColor || isRadialToColor) {
              style[
                "background-image"
              ] = `linear-gradient(${direction}, ${parsed.stops[1].color} 0%, ${parsed.stops[1].color} 100%)`;
            } else {
              style["background-image"] = toGradient(gradientType, direction, parsed.colors);
            }
          }

          if (values["background-position"]) {
            style["background-position"] = values["background-position"];
          }
          if (values["background-size"]) {
            style["background-size"] = values["background-size"];
          }
          if (values["background-repeat"]) {
            style["background-repeat"] = values["background-repeat"];
          }
          if (values["background-attachment"]) {
            style["background-attachment"] = values["background-attachment"];
          }

          delete style["background-gradient-type"];
          delete style["background-gradient-direction"];
          delete style["background-image-gradient"];
          delete style["background-image-color"];
          delete style["background-type"];

          return style;
        },
        onChange: (property) => {
          property;
        },
        defaults: "",
        properties: [
          {
            name: "Type",
            property: "background-type",
            type: "radio",
            defaults: "gradient",
            className: "full-width",
            options: [
              { id: "image", value: "image", name: "Image", label: "Image" },
              { id: "gradient", value: "gradient", name: "Gradient", label: "Gradient" },
              { id: "color", value: "color", name: "Color", label: "Color" },
            ],
          },
          {
            name: "Image",
            property: "background-image",
            type: "file",
            defaults: "url()",
            className: "full-width",
            requires: { "background-type": "image" },
          },
          {
            property: "background-position",
            name: "Position",
            type: "select",
            defaults: "left top",
            className: "vertical",
            options: [
              { id: "none", value: "remove", name: "remove", label: "Auto" },
              { id: "left top", value: "left top", name: "LeftTop", label: "Left Top" },
              { id: "left center", value: "left center", name: "LeftCenter", label: "Left Center" },
              { id: "left bottom", value: "left bottom", name: "LeftBottom", label: "Left Bottom" },
              { id: "center top", value: "center top", name: "CenterTop", label: "Center Top" },
              { id: "center center", value: "center center", name: "CenterCenter", label: "Center Center" },
              { id: "center bottom", value: "center bottom", name: "CenterBottom", label: "Center Bottom" },
              { id: "right top", value: "right top", name: "RightTop", label: "Right Top" },
              { id: "right center", value: "right center", name: "RightCenter", label: "Right Center" },
              { id: "right bottom", value: "right bottom", name: "RightBottom", label: "Right Bottom" },
            ],
            requires: { "background-type": "image" },
          },
          {
            property: "background-size",
            name: "Size",
            type: "select",
            defaults: "auto",
            className: "vertical",
            options: [
              { id: "none", value: "remove", name: "remove", label: "Auto" },
              { id: "auto", value: "auto", name: "Auto", label: "Auto" },
              { id: "cover", value: "cover", name: "Cover", label: "Cover" },
              { id: "contain", value: "contain", name: "Contain", label: "Contain" },
            ],
            requires: { "background-type": "image" },
          },
          {
            property: "background-repeat",
            name: "Repeat",
            type: "select",
            defaults: "repeat",
            className: "vertical full-width",
            options: [
              { id: "none", value: "remove", name: "remove", label: "Auto" },
              { id: "repeat", value: "repeat", name: "Repeat", label: "Repeat" },
              { id: "repeat-x", value: "repeat-x", name: "RepeatX", label: "Repeat X" },
              { id: "repeat-y", value: "repeat-y", name: "RepeatY", label: "Repeat Y" },
              { id: "no-repeat", value: "no-repeat", name: "NoRepeat", label: "No Repeat" },
            ],
            requires: { "background-type": "image" },
          },
          {
            property: "background-attachment",
            name: "Attachment",
            type: "select",
            defaults: "scroll",
            className: "vertical full-width",
            options: [
              { id: "none", value: "remove", name: "remove", label: "Auto" },
              { id: "scroll", value: "scroll", name: "Scroll", label: "Scroll" },
              { id: "fixed", value: "fixed", name: "Fixed", label: "Fixed" },
              { id: "local", value: "local", name: "Local", label: "Local" },
            ],
            requires: { "background-type": "image" },
          },
          {
            property: "background-origin",
            name: "Origin",
            type: "select",
            defaults: "padding-box",
            className: "vertical full-width",
            options: [
              { id: "none", value: "remove", name: "remove", label: "Auto" },
              { id: "border-box", value: "border-box", name: "BorderBox", label: "Border Box" },
              { id: "padding-box", value: "padding-box", name: "PaddingBox", label: "Padding Box" },
              { id: "content-box", value: "content-box", name: "ContentBox", label: "Content Box" },
            ],
            requires: { "background-type": "image" },
          },
          {
            property: "background-image-gradient",
            name: "Gradient",
            type: "gradient",
            defaults: "linear-gradient(135deg, rgba(255, 75, 0, 0.5) 0%, rgba(255, 75, 75, 1) 100%)",
            className: "full-width",
            requires: { "background-type": "gradient" },
          },
          {
            property: "background-gradient-direction",
            name: "Direction",
            type: "select",
            defaults: "right",
            className: "vertical",
            options: [
              {
                id: "right",
                value: "right",
                name: "DirectionRight",
                label: "Right",
              },
              {
                id: "left",
                value: "left",
                name: "DirectionLeft",
                label: "Left",
              },
              {
                id: "top",
                value: "top",
                name: "DirectionTop",
                label: "Top",
              },
              {
                id: "bottom",
                value: "bottom",
                name: "DirectionBottom",
                label: "Bottom",
              },
            ],
            requires: { "background-type": "gradient" },
          },
          {
            property: "background-gradient-type",
            name: "Type",
            type: "select",
            defaults: "linear",
            className: "vertical",
            options: [
              {
                id: "linear",
                value: "linear",
                name: "Linear",
                label: "Linear",
              },
              {
                id: "radial",
                value: "radial",
                name: "Radial",
                label: "Radial",
              },
              {
                id: "repeating-linear",
                value: "repeating-linear",
                name: "RepeatingLinear",
                label: "Repeating Linear",
              },
              {
                id: "repeating-radial",
                value: "repeating-radial",
                name: "RepeatingRadial",
                label: "Repeating Radial",
              },
            ],
            requires: { "background-type": "gradient" },
          },
          {
            property: "background-image-color",
            name: "Color",
            type: "color",
            defaults: "rgba(0, 0, 0, 1)",
            className: "full-width",
            requires: { "background-type": "color" },
          },
        ],
      },
    ],
  },
  {
    name: "Border",
    id: "codejet-border",
    open: false,
    properties: [
      {
        property: "border-radius",
        name: "Corner",
        type: "composite",
        defaults: "0",
        className: "full-width",
        detached: true,
        properties: [
          {
            name: "Top Left",
            property: "border-top-left-radius",
            icon: "CornerTopLeft",
            type: "number",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Top Right",
            property: "border-top-right-radius",
            type: "number",
            icon: "CornerTopRight",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Bottom Left",
            property: "border-bottom-left-radius",
            type: "number",
            icon: "CornerBottomLeft",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Bottom Right",
            property: "border-bottom-right-radius",
            type: "number",
            icon: "CornerBottomRight",
            defaults: "0",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
        ],
      },
      {
        property: "border-width",
        name: "Width",
        type: "composite",
        defaults: "0",
        className: "full-width",
        detached: true,
        properties: [
          {
            name: "Top",
            property: "border-top-width",
            type: "number",
            icon: "BorderTopWidth",
            defaults: "0",
            units: ["px", "em", "rem"],
          },
          {
            name: "Right",
            property: "border-right-width",
            type: "number",
            icon: "BorderRightWidth",
            defaults: "0",
            units: ["px", "em", "rem"],
          },
          {
            name: "Bottom",
            property: "border-bottom-width",
            type: "number",
            icon: "BorderBottomWidth",
            defaults: "0",
            units: ["px", "em", "rem"],
          },
          {
            name: "Left",
            property: "border-left-width",
            type: "number",
            icon: "BorderLeftWidth",
            defaults: "0",
            units: ["px", "em", "rem"],
          },
        ],
      },
      {
        property: "border-style",
        name: "Style",
        type: "composite",
        defaults: "solid",
        className: "full-width",
        detached: true,
        properties: [
          {
            name: "Top",
            property: "border-top-style",
            type: "select",
            defaults: "",
            className: "vertical",
            options: [
              { id: "auto", value: "remove", name: "remove", label: "Auto" },
              { id: "none", value: "none", name: "None", label: "None" },
              { id: "solid", value: "solid", name: "Solid", label: "Solid" },
              { id: "dotted", value: "dotted", name: "Dotted", label: "Dotted" },
              { id: "dashed", value: "dashed", name: "Dashed", label: "Dashed" },
              { id: "double", value: "double", name: "Double", label: "Double" },
              { id: "groove", value: "groove", name: "Groove", label: "Groove" },
              { id: "ridge", value: "ridge", name: "Ridge", label: "Ridge" },
              { id: "inset", value: "inset", name: "Inset", label: "Inset" },
              { id: "outset", value: "outset", name: "Outset", label: "Outset" },
            ],
          },
          {
            name: "Right",
            property: "border-right-style",
            type: "select",
            defaults: "",
            className: "vertical",
            options: [
              { id: "auto", value: "remove", name: "remove", label: "Auto" },
              { id: "none", value: "none", name: "None", label: "None" },
              { id: "solid", value: "solid", name: "Solid", label: "Solid" },
              { id: "dotted", value: "dotted", name: "Dotted", label: "Dotted" },
              { id: "dashed", value: "dashed", name: "Dashed", label: "Dashed" },
              { id: "double", value: "double", name: "Double", label: "Double" },
              { id: "groove", value: "groove", name: "Groove", label: "Groove" },
              { id: "ridge", value: "ridge", name: "Ridge", label: "Ridge" },
              { id: "inset", value: "inset", name: "Inset", label: "Inset" },
            ],
          },
          {
            name: "Bottom",
            property: "border-bottom-style",
            type: "select",
            defaults: "",
            className: "vertical",
            options: [
              { id: "auto", value: "remove", name: "remove", label: "Auto" },
              { id: "none", value: "none", name: "None", label: "None" },
              { id: "solid", value: "solid", name: "Solid", label: "Solid" },
              { id: "dotted", value: "dotted", name: "Dotted", label: "Dotted" },
              { id: "dashed", value: "dashed", name: "Dashed", label: "Dashed" },
              { id: "double", value: "double", name: "Double", label: "Double" },
              { id: "groove", value: "groove", name: "Groove", label: "Groove" },
              { id: "ridge", value: "ridge", name: "Ridge", label: "Ridge" },
              { id: "inset", value: "inset", name: "Inset", label: "Inset" },
            ],
          },
          {
            name: "Left",
            property: "border-left-style",
            type: "select",
            defaults: "",
            className: "vertical",
            options: [
              { id: "auto", value: "remove", name: "remove", label: "Auto" },
              { id: "none", value: "none", name: "None", label: "None" },
              { id: "solid", value: "solid", name: "Solid", label: "Solid" },
              { id: "dotted", value: "dotted", name: "Dotted", label: "Dotted" },
              { id: "dashed", value: "dashed", name: "Dashed", label: "Dashed" },
              { id: "double", value: "double", name: "Double", label: "Double" },
              { id: "groove", value: "groove", name: "Groove", label: "Groove" },
              { id: "ridge", value: "ridge", name: "Ridge", label: "Ridge" },
              { id: "inset", value: "inset", name: "Inset", label: "Inset" },
            ],
          },
        ],
      },
      {
        property: "border-color",
        name: "Color",
        type: "composite",
        defaults: "",
        className: "full-width",
        detached: true,
        properties: [
          {
            name: "Top",
            property: "border-top-color",
            type: "color",
            defaults: "",
            className: "full-width",
          },
          {
            name: "Right",
            property: "border-right-color",
            type: "color",
            defaults: "",
            className: "full-width",
          },
          {
            name: "Bottom",
            property: "border-bottom-color",
            type: "color",
            defaults: "",
            className: "full-width",
          },
          {
            name: "Left",
            property: "border-left-color",
            type: "color",
            defaults: "",
            className: "full-width",
          },
        ],
      },
    ],
  },
  {
    name: "Effect",
    id: "codejet-effect",
    open: false,
    properties: [
      {
        name: "Opacity",
        property: "opacity",
        type: "number",
        defaults: "1",
        step: 0.1,
        max: 100,
        min: 0,
        unit: "%",
        units: ["%"],
      },
      {
        name: "Blend Mode",
        property: "mix-blend-mode",
        type: "select",
        defaults: "",
        className: "vertical",
        options: [
          { id: "none", value: "remove", name: "remove", label: "Auto" },
          { id: "normal", value: "normal", name: "Normal", label: "Normal" },
          { id: "multiply", value: "multiply", name: "Multiply", label: "Multiply" },
          { id: "screen", value: "screen", name: "Screen", label: "Screen" },
          { id: "overlay", value: "overlay", name: "Overlay", label: "Overlay" },
          { id: "darken", value: "darken", name: "Darken", label: "Darken" },
          { id: "lighten", value: "lighten", name: "Lighten", label: "Lighten" },
          { id: "color-dodge", value: "color-dodge", name: "ColorDodge", label: "Color Dodge" },
          { id: "color-burn", value: "color-burn", name: "ColorBurn", label: "Color Burn" },
          { id: "hard-light", value: "hard-light", name: "HardLight", label: "Hard Light" },
          { id: "soft-light", value: "soft-light", name: "SoftLight", label: "Soft Light" },
          { id: "difference", value: "difference", name: "Difference", label: "Difference" },
          { id: "exclusion", value: "exclusion", name: "Exclusion", label: "Exclusion" },
          { id: "hue", value: "hue", name: "Hue", label: "Hue" },
          { id: "saturation", value: "saturation", name: "Saturation", label: "Saturation" },
          { id: "color", value: "color", name: "Color", label: "Color" },
          { id: "luminosity", value: "luminosity", name: "Luminosity", label: "Luminosity" },
        ],
      },
      {
        name: "Cursor",
        property: "cursor",
        type: "select",
        defaults: "",
        className: " full-width vertical",
        options: [
          { id: "auto", value: "auto", name: "Auto", label: "Auto" },
          { id: "default", value: "default", name: "Default", label: "Default" },
          { id: "pointer", value: "pointer", name: "Pointer", label: "Pointer" },
          { id: "crosshair", value: "crosshair", name: "Crosshair", label: "Crosshair" },
          { id: "move", value: "move", name: "Move", label: "Move" },
          { id: "grab", value: "grab", name: "Grab", label: "Grab" },
          { id: "grabbing", value: "grabbing", name: "Grabbing", label: "Grabbing" },
          { id: "text", value: "text", name: "Text", label: "Text" },
          { id: "wait", value: "wait", name: "Wait", label: "Wait" },
          { id: "help", value: "help", name: "Help", label: "Help" },
          { id: "not-allowed", value: "not-allowed", name: "NotAllowed", label: "Not Allowed" },
        ],
      },
      {
        name: "Box Shadow",
        property: "box-shadow",
        type: "stack",
        defaults: "",
        className: "full-width",
        layerLabel: (l, { values }) => {
          const x = values["box-shadow-h"];
          const y = values["box-shadow-v"];
          const blur = values["box-shadow-blur"];
          const spread = values["box-shadow-spread"];
          return `${x} ${y} ${blur} ${spread}`;
        },
        preview: true,
        properties: [
          {
            name: "Color",
            property: "box-shadow-color",
            type: "color",
            defaults: "#000",
            className: "full-width",
          },
          {
            name: "X offset",
            property: "box-shadow-h",
            type: "number",
            defaults: "1px",
            units: ["px", "em", "rem"],
            label: "Przesunięcie w poziomie",
          },
          {
            name: "Y offset",
            property: "box-shadow-v",
            type: "number",
            defaults: "1px",
            units: ["px", "em", "rem"],
          },
          {
            name: "Blur",
            property: "box-shadow-blur",
            type: "number",
            defaults: "5px",
            units: ["px", "em", "rem"],
          },
          {
            name: "Spread",
            property: "box-shadow-spread",
            type: "number",
            defaults: "0",
            units: ["px", "em", "rem"],
          },
          {
            name: "Inset",
            property: "box-shadow-inset",
            type: "radio",
            defaults: "",
            className: "full-width",
            options: [
              { id: "inset", value: "inset", name: "Inset", label: "Inset" },
              { id: "outset", value: "outset", name: "Outset", label: "Outset" },
            ],
          },
        ],
      },
      {
        name: "Text Shadow",
        property: "text-shadow",
        type: "stack",
        defaults: "",
        className: "full-width",
        layerLabel: (l, { values }) => {
          const x = values["text-shadow-h"];
          const y = values["text-shadow-v"];
          const blur = values["text-shadow-blur"];
          return `${x} ${y} ${blur}`;
        },
        preview: true,
        properties: [
          {
            name: "Color",
            property: "text-shadow-color",
            type: "color",
            defaults: "#000",
            className: "full-width",
          },
          {
            name: "X offset",
            property: "text-shadow-h",
            type: "number",
            defaults: "1px",
            units: ["px", "em", "rem"],
          },
          {
            name: "Y offset",
            property: "text-shadow-v",
            type: "number",
            defaults: "1px",
            units: ["px", "em", "rem"],
          },
          {
            name: "Blur",
            property: "text-shadow-blur",
            type: "number",
            defaults: "5px",
            className: "full-width",
            units: ["px", "em", "rem"],
          },
        ],
      },
      {
        name: "Filter",
        property: "filter",
        type: "stack",
        defaults: "",
        className: "full-width",
        layerLabel: (layer, data) => {
          const values = data.values;
          return `${values["filter-type"] ? `${values["filter-type"]}: ${values["filter-value"]}` : ""}`;
        },
        fromStyle(style, { property, name }) {
          const filter = (style[name] || "") as string;
          const sep = property.getLayerSeparator();
          return filter
            ? filter.split(sep).map((input) => {
                const { name, value } = property.__parseFn(input);
                return {
                  "filter-type": name,
                  "filter-value": value,
                };
              })
            : [];
        },
        toStyle(values, { name }) {
          return { [name]: `${values["filter-type"]}(${values["filter-value"]})` };
        },
        properties: [
          {
            name: "Filter type",
            property: "filter-type",
            type: "select",
            defaults: "blur",
            className: "vertical",
            options: [
              { id: "none", value: "remove", name: "remove", label: "Auto" },
              {
                id: "blur",
                value: "blur",
                name: "Blur",
                label: "Blur",
                propValue: { units: ["px", "em", "rem", "vh", "vw"], step: 1 },
              },
              {
                id: "drop-shadow",
                value: "drop-shadow",
                name: "DropShadow",
                label: "Drop Shadow",
                propValue: { units: ["px", "em", "rem", "vh", "vw"], step: 1 },
              },
              {
                id: "brightness",
                value: "brightness",
                name: "Brightness",
                label: "Brightness",
                propValue: { units: ["%"], step: 1 },
              },
              {
                id: "contrast",
                value: "contrast",
                name: "Contrast",
                label: "Contrast",
                propValue: { units: ["%"], step: 1 },
              },
              {
                id: "grayscale",
                value: "grayscale",
                name: "Grayscale",
                label: "Grayscale",
                propValue: { units: ["%"], step: 1 },
              },
              {
                id: "hue-rotate",
                value: "hue-rotate",
                name: "HueRotate",
                label: "Hue Rotate",
                propValue: { units: ["deg", "rad", "grad"], step: 1 },
              },
              {
                id: "invert",
                value: "invert",
                name: "Invert",
                label: "Invert",
                propValue: { units: ["%"], step: 1 },
              },
              {
                id: "saturate",
                value: "saturate",
                name: "Saturate",
                label: "Saturate",
                propValue: { units: ["%"], step: 1 },
              },
              { id: "sepia", value: "sepia", name: "Sepia", label: "Sepia", propValue: { units: ["%"], step: 1 } },
            ],
            onChange({ property, to }) {
              if (to.value) {
                const option = (property as PropertySelect).getOption();
                const props = { ...(option.propValue || {}) };
                const propToUp = property.getParent().getProperty("filter-value") as PropertyNumber;
                const unit = propToUp.getUnit();
                if (!unit || props?.units.indexOf(unit) < 0) {
                  props.unit = props?.units[0] || "";
                }
                propToUp.up(props);
              }
            },
          },
          {
            name: "Filter value",
            property: "filter-value",
            type: "number",
            defaults: "3px",
            units: ["px", "em", "rem", "vh", "vw"],
          },
        ],
      },
      {
        name: "Backdrop Filter",
        property: "backdrop-filter",
        type: "stack",
        defaults: "",
        className: "full-width",
        layerLabel: (layer, data) => {
          const values = data.values;
          return `${values["backdrop-type"] ? `${values["backdrop-type"]}: ${values["backdrop-value"]}` : ""}`;
        },
        fromStyle(style, { property, name }) {
          const filter = (style[name] || "") as string;
          const sep = property.getLayerSeparator();
          return filter
            ? filter.split(sep).map((input) => {
                const { name, value } = property.__parseFn(input);
                return {
                  "backdrop-type": name,
                  "backdrop-value": value,
                };
              })
            : [];
        },
        toStyle(values, { name }) {
          return { [name]: `${values["backdrop-type"]}(${values["backdrop-value"]})` };
        },
        properties: [
          {
            name: "Type",
            property: "backdrop-type",
            type: "select",
            defaults: "blur",
            className: "vertical",
            options: [
              { id: "none", value: "remove", name: "remove", label: "Auto" },
              {
                id: "blur",
                value: "blur",
                name: "Blur",
                label: "Blur",
                propValue: { units: ["px", "em", "rem", "vh", "vw"], step: 1 },
              },
              {
                id: "drop-shadow",
                value: "drop-shadow",
                name: "DropShadow",
                label: "Drop Shadow",
                propValue: { units: ["px", "em", "rem", "vh", "vw"], step: 1 },
              },
              {
                id: "brightness",
                value: "brightness",
                name: "Brightness",
                label: "Brightness",
                propValue: { units: ["%"], step: 1 },
              },
              {
                id: "contrast",
                value: "contrast",
                name: "Contrast",
                label: "Contrast",
                propValue: { units: ["%"], step: 1 },
              },
              {
                id: "grayscale",
                value: "grayscale",
                name: "Grayscale",
                label: "Grayscale",
                propValue: { units: ["%"], step: 1 },
              },
              {
                id: "hue-rotate",
                value: "hue-rotate",
                name: "HueRotate",
                label: "Hue Rotate",
                propValue: { units: ["deg", "rad", "grad"], step: 1 },
              },
              {
                id: "invert",
                value: "invert",
                name: "Invert",
                label: "Invert",
                propValue: { units: ["%"], step: 1 },
              },
              {
                id: "saturate",
                value: "saturate",
                name: "Saturate",
                label: "Saturate",
                propValue: { units: ["%"], step: 1 },
              },
              { id: "sepia", value: "sepia", name: "Sepia", label: "Sepia", propValue: { units: ["%"], step: 1 } },
            ],
            onChange({ property, to }) {
              if (to.value) {
                const option = (property as PropertySelect).getOption();
                const props = { ...(option.propValue || {}) };
                const propToUp = property.getParent().getProperty("backdrop-value") as PropertyNumber;
                const unit = propToUp.getUnit();
                if (!unit || props?.units.indexOf(unit) < 0) {
                  props.unit = props?.units[0] || "";
                }
                propToUp.up(props);
              }
            },
          },
          {
            name: "Value",
            property: "backdrop-value",
            type: "number",
            defaults: "3px",
            units: ["px", "em", "rem", "vh", "vw"],
          },
        ],
      },
      {
        name: "Transition",
        property: "transition",
        type: "stack",
        defaults: "",
        className: "full-width",
        layerLabel: (layer, data) => {
          const values = data.values;
          return `${values["transition-property"] ? `${values["transition-property"]}: ` : ""}${
            values["transition-duration"] ? `${values["transition-duration"]}` : ""
          }`;
        },
        preview: true,
        properties: [
          {
            name: "Property",
            property: "transition-property",
            type: "select",
            defaults: "opacity",
            className: "full-width vertical",
            options: [
              { id: "width", value: "width", name: "Width", label: "Width" },
              {
                id: "height",
                value: "height",
                name: "Height",
                label: "Height",
              },
              {
                id: "min-width",
                value: "min-width",
                name: "MinWidth",
                label: "Min Width",
              },
              {
                id: "min-height",
                value: "min-height",
                name: "MinHeight",
                label: "Min Height",
              },
              {
                id: "max-width",
                value: "max-width",
                name: "MaxWidth",
                label: "Max Width",
              },
              {
                id: "max-height",
                value: "max-height",
                name: "MaxHeight",
                label: "Max Height",
              },
              {
                id: "padding",
                value: "padding",
                name: "Padding",
                label: "Padding",
              },
              {
                id: "margin",
                value: "margin",
                name: "Margin",
                label: "Margin",
              },
              {
                id: "color",
                value: "color",
                name: "Color",
                label: "Color",
              },
              { id: "font-size", value: "font-size", name: "FontSize", label: "Font Size" },
              { id: "line-height", value: "line-height", name: "LineHeight", label: "Line Height" },
              {
                id: "border",
                value: "border",
                name: "Border",
                label: "Border",
              },
              {
                id: "border-radius",
                value: "border-radius",
                name: "BorderRadius",
                label: "Border Radius",
              },
              {
                id: "background",
                value: "background",
                name: "Background",
                label: "Background",
              },
              {
                id: "box-shadow",
                value: "box-shadow",
                name: "BoxShadow",
                label: "Box Shadow",
              },
              {
                id: "text-shadow",
                value: "text-shadow",
                name: "TextShadow",
                label: "Text Shadow",
              },
              {
                id: "filter",
                value: "filter",
                name: "Filter",
                label: "Filter",
              },
              {
                id: "backdrop-filter",
                value: "backdrop-filter",
                name: "BackdropFilter",
                label: "Backdrop Filter",
              },
              {
                id: "transform",
                value: "transform",
                name: "Transform",
                label: "Transform",
              },
              {
                id: "opacity",
                value: "opacity",
                name: "Opacity",
                label: "Opacity",
              },
              {
                id: "all",
                value: "all",
                name: "All",
                label: "All",
              },
            ],
          },
          {
            name: "Duration",
            property: "transition-duration",
            type: "number",
            defaults: "0.25s",
            units: ["s", "ms"],
          },
          {
            name: "Delay",
            property: "transition-delay",
            type: "number",
            defaults: "0.25s",
            units: ["s", "ms"],
          },
          {
            name: "Timing Function",
            property: "transition-timing-function",
            type: "select",
            defaults: "ease",
            className: "full-width vertical",
            options: [
              { id: "ease", value: "ease", name: "Ease", label: "Ease" },
              { id: "linear", value: "linear", name: "Linear", label: "Linear" },
              { id: "ease-in", value: "ease-in", name: "EaseIn", label: "Ease In" },
              { id: "ease-out", value: "ease-out", name: "EaseOut", label: "Ease Out" },
              { id: "ease-in-out", value: "ease-in-out", name: "EaseInOut", label: "Ease In Out" },
            ],
          },
        ],
      },
      {
        name: "Transform",
        property: "transform",
        type: "stack",
        defaults: "translateX(0)",
        className: "full-width",
        layerSeparator: " ",
        layerLabel: (layer, data) => {
          const values = data.values;
          return `${values["transform-type"] ? `${values["transform-type"]}: ${values["transform-value"]}` : ""}`;
        },
        preview: true,
        fromStyle(style, { property, name }) {
          const filter = (style[name] || "") as string;
          const sep = property.getLayerSeparator();
          return filter
            ? filter.split(sep).map((input) => {
                const { name, value } = property.__parseFn(input);
                return {
                  "transform-type": name,
                  "transform-value": value,
                };
              })
            : [];
        },
        toStyle(values, { name }) {
          return { [name]: `${values["transform-type"]}(${values["transform-value"]})` };
        },
        properties: [
          {
            name: "type",
            property: "transform-type",
            type: "select",
            defaults: "rotateX",
            className: "full-width vertical",
            options: [
              { id: "none", value: "remove", name: "remove", label: "Auto" },
              {
                id: "rotateX",
                value: "rotateX",
                name: "RotateX",
                label: "RotateX",
                propValue: { units: ["deg", "rad", "grad"], step: 1 },
              },
              {
                id: "rotateY",
                value: "rotateY",
                name: "RotateY",
                label: "RotateY",
                propValue: { units: ["deg", "rad", "grad"], step: 1 },
              },
              {
                id: "rotateZ",
                value: "rotateZ",
                name: "RotateZ",
                label: "RotateZ",
                propValue: { units: ["deg", "rad", "grad"], step: 1 },
              },
              {
                id: "translateX",
                value: "translateX",
                name: "TranslateX",
                label: "TranslateX",
                propValue: { units: ["px", "%", "em", "rem", "vh", "vw"], step: 1 },
              },
              {
                id: "translateY",
                value: "translateY",
                name: "TranslateY",
                label: "TranslateY",
                propValue: { units: ["px", "%", "em", "rem", "vh", "vw"], step: 1 },
              },
              {
                id: "scaleX",
                value: "scaleX",
                name: "ScaleX",
                label: "ScaleX",
                propValue: { units: [""], step: 0.01 },
              },
              {
                id: "scaleY",
                value: "scaleY",
                name: "ScaleY",
                label: "ScaleY",
                propValue: { units: [""], step: 0.01 },
              },
              {
                id: "scaleZ",
                value: "scaleZ",
                name: "ScaleZ",
                label: "ScaleZ",
                propValue: { units: [""], step: 0.01 },
              },
            ],
            onChange({ property, to }) {
              if (to.value) {
                const option = (property as PropertySelect).getOption();
                const props = { ...(option.propValue || {}) };
                const propToUp = property.getParent().getProperty("transform-value") as PropertyNumber;
                const unit = propToUp.getUnit();
                if (!unit || props?.units.indexOf(unit) < 0) {
                  props.unit = props?.units[0] || "";
                }
                propToUp.up(props);
              }
            },
          },
          {
            name: "Value",
            property: "transform-value",
            type: "number",
            defaults: "1deg",
            unit: "deg",
            units: ["deg", "rad", "turn"],
          },
        ],
      },
      {
        name: "Backface",
        property: "backface-visibility",
        type: "radio",
        defaults: "",
        className: "full-width",
        options: [
          { id: "visible", value: "visible", name: "Visible", label: "Visible" },
          { id: "hidden", value: "hidden", name: "Hidden", label: "Hidden" },
        ],
      },
      {
        name: "Transform origin",
        property: "transform-origin",
        type: "composite",
        defaults: "",
        className: "full-width",
        properties: [
          {
            name: "Left",
            property: "transform-origin-x",
            type: "number",
            defaults: "50",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Top",
            property: "transform-origin-y",
            type: "number",
            defaults: "50",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
        ],
      },
      {
        name: "Overflow",
        property: "overflow",
        type: "composite",
        defaults: "visible",
        className: "full-width",
        properties: [
          {
            name: "X",
            property: "overflow-x",
            type: "select",
            defaults: "visible",
            className: "vertical",
            options: [
              { id: "visible", value: "visible", name: "Visible", label: "Visible" },
              { id: "hidden", value: "hidden", name: "Hidden", label: "Hidden" },
              { id: "scroll", value: "scroll", name: "Scroll", label: "Scroll" },
              { id: "auto", value: "auto", name: "Auto", label: "Auto" },
            ],
          },
          {
            name: "Y",
            property: "overflow-y",
            type: "select",
            defaults: "visible",
            className: "vertical",
            options: [
              { id: "visible", value: "visible", name: "Visible", label: "Visible" },
              { id: "hidden", value: "hidden", name: "Hidden", label: "Hidden" },
              { id: "scroll", value: "scroll", name: "Scroll", label: "Scroll" },
              { id: "auto", value: "auto", name: "Auto", label: "Auto" },
            ],
          },
        ],
      },
      {
        name: "Perspective",
        property: "perspective",
        type: "number",
        defaults: "",
        unit: "px",
        units: ["px", "em", "rem", "%"],
      },
      {
        name: "Type",
        property: "transform-style",
        type: "radio",
        defaults: "",
        options: [
          { id: "2d", value: "flat", name: "2D", label: "2D" },
          { id: "3d", value: "preserve-3d", name: "3D", label: "3D" },
        ],
      },
      {
        name: "Perspective origin",
        property: "perspective-origin",
        type: "composite",
        defaults: "",
        className: "full-width",
        properties: [
          {
            name: "Left",
            property: "perspective-origin-x",
            type: "number",
            defaults: "50",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
          {
            name: "Top",
            property: "perspective-origin-y",
            type: "number",
            defaults: "50",
            units: ["px", "%", "em", "rem", "vh", "vw"],
          },
        ],
      },
    ],
  },
];
