import { Subscriber } from "../../../../../services/abstract/Subscriber/Subscriber.ts";
import { AddComponentTypeOptions, Block, BlockProperties, Editor } from "grapesjs";
import { blocksProperties } from "./blocks.ts";
import { componentsDefinitions } from "./components.ts";

export type BlocksModel = {
  // The `props` will contain all the information you need in order to update your UI.
  // props.blocks (Array<Block>) - Array of all blocks
  // props.dragStart (Function<Block>) - A callback to trigger the start of block dragging.
  // props.dragStop (Function<Block>) - A callback to trigger the stop of block dragging.
  // props.container (HTMLElement) - The default element where you can append your UI
  blocks: Block[];
  dragStart: (bl: Block) => void;
  dragStop: (bl: Block) => void;
  container: HTMLElement;
};

export type EditorServiceState = {};

export class EditorService extends Subscriber<EditorServiceState> {
  state = {};

  addNewElementPanelVisible = false;
  previewCommandRun = false;

  editor: Editor | null = null;
  editorIsLoaded = false;

  isBlockBeingDragged = false;
  invalidImageFlag = false;

  blocks: BlockProperties[] = blocksProperties;

  componentsDef: { id: string; props: AddComponentTypeOptions }[] = componentsDefinitions;

  blocksModel = {} as BlocksModel;
  setBlocksModel(bm: BlocksModel) {
    this.blocksModel = bm;
  }

  setEditor(editor: Editor) {
    this.editor = editor;
  }

  setIsLoaded(state: boolean) {
    this.editorIsLoaded = state;
    this.notifySubscribers();
  }

  clearEditor() {
    this.editor = null;
    this.editorIsLoaded = false;
    // reset state to initial so after re-init BlocksMenu will not be open
    this.addNewElementPanelVisible = false;
  }

  getEditor() {
    return this.editor;
  }

  setPreviewCommandRun(state: boolean) {
    this.previewCommandRun = state;
  }

  setIsBlockBeingDragged(state: boolean) {
    this.isBlockBeingDragged = state;
  }

  setInvalidImageFlag(state: boolean) {
    this.invalidImageFlag = state;
  }
}
