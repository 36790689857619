import { BlockProperties } from "grapesjs";

export const block2ColEven: BlockProperties = {
  id: "block-2-col-even",
  media: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="18" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="21.5" y="0.5" width="18" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>
    `,
  label: "2 columns",
  category: "Layout",
  content: {
    type: "cjRow",
    components: [
      {
        type: "cjColumn",
        classes: ["cj-grid-column--2"],
      },
      {
        type: "cjColumn",
        classes: ["cj-grid-column--2"],
      },
    ],
  },
  select: true,
};
