import { smartComponentFormItemClass, staticBlockConfig } from "../block-helpers";

import type { CSSStyle } from "../../../../../../../types";
import type { ComponentDefinition } from "grapesjs";

type CrateButtonComponentAttrs = {
  content: string;
  type?: string;
  classes?: string[];
  styles?: CSSStyle;
  attributes?: ComponentDefinition["attributes"];
};

type CreateButtonComponent = (attrs: CrateButtonComponentAttrs) => ComponentDefinition;

export const createButtonComponent: CreateButtonComponent = ({
  content = "",
  type = "button",
  classes = [],
  styles = {},
  attributes = {},
}) => {
  return {
    tagName: "button",
    // ...staticBlockConfig,
    // removable: false,
    // draggable: false,
    // copyable: false,
    // droppable: false,
    // editable: false,
    removable: false,
    dropable: false,
    copyable: false,

    // layerable: false,
    style: {
      ...styles,
    },
    content: content,
    classes: ["cj-button", ...classes],
    attributes: {
      type,
      ...attributes,
    },
  };
};
