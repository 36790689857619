// MobileLoader.tsx
import { useEffect } from "react";

import { Check } from "../../../../../assets/svg/Check";

import { TSuccess } from "./Success.types";

import "./Success.css";

const MobileLoader: TSuccess = ({ setShowSuccessComponent, showSuccessComponent }) => {
  useEffect(() => {
    if (showSuccessComponent) {
      const timer = setTimeout(() => {
        setShowSuccessComponent(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [showSuccessComponent]);

  return (
    <div className="codejet__success-page">
      <div className="codejet__success-page__logo">
        <Check />
      </div>
      <p className="codejet__success-page__message">Successfully copied!</p>
    </div>
  );
};

export default MobileLoader;
