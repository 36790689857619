import type { SVGProps } from "react";

export const MobileConnectorNotActive = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="6" viewBox="0 0 40 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.667318 3C0.667318 4.47276 1.86123 5.66667 3.33398 5.66667C4.80674 5.66667 6.00065 4.47276 6.00065 3C6.00065 1.52724 4.80674 0.333333 3.33398 0.333333C1.86123 0.333333 0.667318 1.52724 0.667318 3ZM3.33398 3.5H39.6673V2.5H3.33398V3.5Z"
      fill="url(#paint0_linear_20634_6643)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_20634_6643"
        x1="3.33398"
        y1="3.5"
        x2="39.6673"
        y2="3.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4D4D8" />
        <stop offset="1" stopColor="#D4D4D8" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
