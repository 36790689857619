import { BlockProperties } from "grapesjs";

export const label: BlockProperties = {
  id: "Label",
  media: `<svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V4C24 5.10457 23.1046 6 22 6H2C0.89543 6 0 5.10457 0 4V2Z" fill="#D4D4D8"/><path class="dark-fill" d="M0.5 10C0.5 9.17157 1.17157 8.5 2 8.5H38C38.8284 8.5 39.5 9.17157 39.5 10V22C39.5 22.8284 38.8284 23.5 38 23.5H2C1.17157 23.5 0.5 22.8284 0.5 22V10Z" fill="#27272A"/><path d="M0.5 10C0.5 9.17157 1.17157 8.5 2 8.5H38C38.8284 8.5 39.5 9.17157 39.5 10V22C39.5 22.8284 38.8284 23.5 38 23.5H2C1.17157 23.5 0.5 22.8284 0.5 22V10Z" stroke="#71717A"/><line class="light-stroke" x1="4.5" y1="12.5" x2="4.5" y2="19.5" stroke="#D4D4D8" stroke-linecap="round"/></svg>`,
  label: "Label",
  category: "Forms",
  content: {
    type: "label",
    content: "Label",
  },
  select: true,
};
