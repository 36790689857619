import { useEffect, useState } from "react";

import { styleManagerService } from "../styles-manager/StylesManager.service";

import { FormAppearance } from "./sc-form/FormAppearance";
import { Accordion } from "../../../../AlphaO/atoms/Accordion/Accordion";

import type { ReactNode } from "react";

import "./Appearance.css";

type AppearanceProps = {
  customScrollbar?: boolean;
};

type TAppearance = (props: AppearanceProps) => ReactNode;

export const Appearance: TAppearance = ({ customScrollbar = false }) => {
  const classes = ["appearance-manager", customScrollbar && "custom-scrollbar"].filter(Boolean).join(" ");
  const [state, subscribeStylesService] = useState(styleManagerService.state);
  const traits = state.component?.getTraits();

  useEffect(() => {
    styleManagerService.subscribe(subscribeStylesService);

    return () => {
      styleManagerService.unsubscribe(subscribeStylesService);
    };
  }, []);

  if (!state.component || !state.sectors) return null;

  return (
    <div className={classes}>
      <Accordion label="Appearance" className="accordion--appearance" forceOpen>
        {traits?.find((trait) => trait.getType() === "smartComponentsForm") && (
          <FormAppearance component={state.component} sectors={state.sectors} />
        )}
      </Accordion>
    </div>
  );
};
