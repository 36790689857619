import { useEffect, useState } from "react";
import { Trait } from "grapesjs";

import { traitMachineInstance } from "./index.ts";

import { TypeManager } from "./traits/TypeManager";
import type { ReactNode } from "react";

import "./TraitManager.css";

export type UseStateMachine<M, S> = (machine: M) => S;
export const useStateMachine: UseStateMachine<any, any> = (machineInstance) => {
  const [state, setState] = useState(machineInstance.initialState.context.service.state);

  useEffect(() => {
    machineInstance.start();
    machineInstance.initialState.context.service.subscribeInUseEffect((st) => setState(st));
    return () => {
      machineInstance.stop();
      machineInstance.initialState.context.service.unsubscribe(setState);
    };
  }, []);

  return { service: machineInstance.initialState.context.service, state };
};

type TraitManagerProps = {
  customScrollbar?: boolean;
};

type TTraitManager = (props: TraitManagerProps) => ReactNode;

export const TraitManager: TTraitManager = ({ customScrollbar = true }) => {
  const classes = ["trait-manager", customScrollbar && "custom-scrollbar"].filter(Boolean).join(" ");
  const { state } = useStateMachine(traitMachineInstance);
  return (
    <div className={classes}>
      <ul className="trait-manager__traits">
        {state.traits.map((trait: Trait) => {
          return <TypeManager key={trait.cid} trait={trait} />;
        })}
      </ul>
    </div>
  );
};
