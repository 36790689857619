import "grapesjs/dist/css/grapes.min.css";
import { useEffect, useState } from "react";
import { editorInstance, editorService } from "./modules/editor/index.ts";

import { ModalContainer } from "./modules/modal/ModalContainer.tsx";
import { selectorManagerInstance } from "./modules/selector-manager/selector-manager.service.ts";
import { assetManagerInstance } from "./modules/asset-manager";
import { styleManagerService } from "./modules/styles-manager/StylesManager.service.ts";
import { TriggerService } from "../../../services/TriggerService/TriggerService.ts";
import { UserService } from "../../../services/UserService/UserService.ts";

import { Seo } from "../../atoms/Seo";
import { LoaderBackdrop } from "../../molecules/LoaderBackdrop";
import { TopBar } from "../../AlphaO/molecules/TopBar/TopBar.tsx";
import { LeftSidebar } from "../../AlphaO/molecules/LeftSidebar/LeftSidebar.tsx";
import { UpdateNotification } from "../../AlphaO/atoms/UpdateNotification/UpdateNotification.tsx";
import { ColorPickerModal } from "../../AlphaO/atoms/ColorPicker/ColorPicker.tsx";
import { RightSideBar } from "./modules/right-sidebar/RightSideBar.tsx";
import { useViewportMeta } from "../../../utils/useViewportMeta/useViewportMeta.tsx";

import "./style.css";

const triggerService = TriggerService.getInstance();
const userService = UserService.getInstance();

export const Alpha_O = () => {
  useViewportMeta("width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0");
  const [state, updateState] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const [_, updateSelectorManager] = useState(selectorManagerInstance.state);
  const isLoaded = editorService.editorIsLoaded;
  const editor = editorService.getEditor();
  const hasUserData = userService.getData()?.EMAIL;

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    triggerService.increment("closingTab");
    e.preventDefault();
  };

  useEffect(() => {
    editorInstance.start();
    editorService.subscribe((st) => updateState(st));
    selectorManagerInstance.subscribe((st) => updateSelectorManager(st));

    if (!hasUserData) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      editorInstance.stop();
      editorService.clearEditor();
      editorService.unsubscribe(updateState);
      assetManagerInstance.send("CLOSE");
      styleManagerService.clear();

      if (!hasUserData) {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      }
    };
  }, []);

  const togglePreview = () => {
    // its important to stop undo manager before preview, so we don't add empty action to UndoManager
    editor?.UndoManager.stop();
    editorInstance.send("TOGGLE_PREVIEW");
    setIsPreview((prev) => !prev);
    editor?.UndoManager.start();
  };

  return (
    <>
      <Seo title="Visual Editor - Codejet" />
      <div className={`alpha-o${isPreview ? " preview" : ""}`}>
        {!isLoaded && (
          <div className="alpha-o__veil">
            <LoaderBackdrop />
          </div>
        )}
        <ModalContainer />
        <TopBar togglePreview={togglePreview} isPreview={isPreview} />
        <div className={`main-section${isPreview ? " preview" : ""}`}>
          <LeftSidebar />
          <div className="main-section__editor">
            <div id={"gjs"}></div>
            {hasUserData && <UpdateNotification />}
          </div>
          <RightSideBar />
        </div>
        <ColorPickerModal />
      </div>
    </>
  );
};
