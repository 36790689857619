import { BlockProperties } from "grapesjs";

export const checkbox: BlockProperties = {
  id: "Checkbox",
  media: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="#27272A"/><rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#71717A"/><path class="light-stroke" d="M17.3334 8.33331L10.0001 15.6666L6.66675 12.3333" stroke="#D4D4D8" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  label: "Checkbox",
  category: "Forms",
  content: {
    tagName: "label",
    style: { display: "flex", "align-items": "center" },
    components: [
      {
        type: "checkbox",
      },
      {
        type: "text",
        tagName: "span",
        content: "Name",
      },
    ],
  },
  select: true,
};
