import { BlockProperties } from "grapesjs";

export const radio: BlockProperties = {
  id: "Radio",
  media: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#27272A"/><rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#71717A"/><circle class="light-fill" cx="12" cy="12" r="5" fill="#D4D4D8"/></svg>`,
  label: "Radio button",
  category: "Forms",
  content: {
    tagName: "label",
    style: { display: "flex", "align-items": "center" },
    components: [
      {
        type: "radio",
      },
      {
        type: "text",
        tagName: "span",
        content: "Name",
      },
    ],
  },
  select: true,
};
