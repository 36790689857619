import { useState, useEffect } from "react";
import { Component, Sectors } from "grapesjs";
import { editorService } from "../../editor";

import { Accordion } from "../../../../../AlphaO/atoms/Accordion/Accordion";

import { CompositeProperty, PropertyLabel } from "../../styles-manager/components";
import { PropertySelect } from "../../trait-manager/traits/SmartComponents/Form/PropertySelect";
import { Corner } from "./Corner";
import { Padding } from "./Padding";
import { BorderWidth } from "./BorderWidth";
import { BorderStyle } from "./BorderStyle";
import { BorderColor } from "./BorderColor";
import { BackgroundColor } from "./BackgroundColor";
import { Clip } from "./Clip";
import { Test } from "./Test";

import type { Option as BaseOption } from "../../trait-manager/traits/SmartComponents/Form/PropertySelect";

import type { PropertyComposite } from "grapesjs";

interface Option extends BaseOption {
  style?: Record<string, any>;
}

export const FormAppearance = ({ component, sectors }: { component: Component; sectors: Sectors }) => {
  //   const style = component.getStyle();
  const layoutSector = sectors.find((sector) => sector.getName() === "Layout");
  const spaceSector = sectors.find((sector) => sector.getName() === "Space");
  const gapProperty = layoutSector?.getProperty("gap");
  const paddingProperty = spaceSector?.getProperty("padding");

  return (
    <div className="appearance-manager__content">
      <div className="attribute-field">
        {gapProperty && (
          <CompositeProperty
            property={gapProperty as PropertyComposite}
            key={gapProperty.getId()}
            allVariables={{}}
            withSlider
          />
        )}
        {paddingProperty && (
          <CompositeProperty
            property={paddingProperty as PropertyComposite}
            key={paddingProperty.getId()}
            allVariables={{}}
          />
        )}
        <Accordion label="Input / Textfield / Select" variant={true} className="accordion--appearance">
          <FormAppearanceInput component={component} />
        </Accordion>
        <Accordion label="Checkbox / Radio" variant={true} className="accordion--appearance">
          <FormAppearanceCheckbox component={component} />
        </Accordion>
        <Accordion label="Form Button" variant={true} className="accordion--appearance">
          {/* <span style={{ color: "#fff" }}>Button</span> */}
          <FormAppearanceButton component={component} />
        </Accordion>
        {/* <Accordion label="Toast Messages" variant={true} className="accordion--appearance">
          <span style={{ color: "#fff" }}>Toast options</span>
        </Accordion> */}
      </div>
    </div>
  );
};

const FormAppearanceInput = ({ component }: { component: Component }) => {
  const editor = editorService.getEditor();
  const textInputArray = component.find(".sc__form-item > .cj-label + .cj-input");
  // console.log("textInputArray", textInputArray);

  // this gave mi only # style
  // const style = textInputArray[0].getStyle();
  // const inputSelectors = textInputArray[0].getSelectors();
  // this make error while rendering
  //   const sectors = editor?.StyleManager.select(".cj-input");

  // console.log("style", inputSelectors);

  //   editor?.SelectorManager.add("cj-input");

  if (!textInputArray) return <div></div>;

  const [prevSimulatedClass, setPrevSimulatedClass] = useState("");

  const defaultRule = editor?.Css.getRule(".cj-input");
  const hoverRule = editor?.Css.getRule(".cj-input:hover");
  const activeRule = editor?.Css.getRule(".cj-input:active");
  const disabledRule = editor?.Css.getRule(".cj-input:disabled");

  const defaultStyle = defaultRule?.attributes.style;
  const hoverStyle = hoverRule?.attributes.style;
  const activeStyle = activeRule?.attributes.style;
  const disabledStyle = disabledRule?.attributes.style;

  const sectors = editor?.StyleManager.getSectors();

  // console.log("sectors", sectors);

  const FORM_ELEMENT_OPTIONS: Option[] = [
    { id: "1", value: "Default", label: "Default", style: defaultStyle },
    { id: "2", value: "Hover", label: "Hover", style: hoverStyle },
    { id: "5", value: "Active/Click", label: "Active/Click", style: activeStyle },
    { id: "6", value: "Disabled", label: "Disabled", style: disabledStyle },
  ];

  useEffect(() => {
    return () => {
      if (prevSimulatedClass !== "") {
        editor?.SelectorManager.remove(prevSimulatedClass);
        editor?.CssComposer.remove(`.cj-input.${prevSimulatedClass}`);
      }
    };
  }, [prevSimulatedClass]);

  const handleSelectNewItem = (item: Option) => {
    // Get normalized label
    // Create simulated class name
    // Create simulated class selector
    // Create previous simulated class selector
    // Get simulated styles for the selected item
    const normalizedLabel = item.label.toLowerCase();
    const simulatedClass = `simulate-${normalizedLabel}`;

    const simulatedStyles = FORM_ELEMENT_OPTIONS.find((option) => option.id === item.id)?.style;

    // Remove previous class from the selector manager
    // and rule from the css composer
    if (prevSimulatedClass !== "" || !simulatedClass.includes("default")) {
      editor?.SelectorManager.remove(prevSimulatedClass);
      editor?.CssComposer.remove(`.cj-input.${prevSimulatedClass}`);
    }

    if (!simulatedClass.includes("default")) {
      const selector_one = editor?.SelectorManager.add(".cj-input");
      const selector_two = editor?.SelectorManager.add(simulatedClass);
      const rule = editor?.CssComposer.add([selector_one, selector_two]);
      rule?.set("style", simulatedStyles);
    }

    textInputArray.forEach((element) => {
      // Remove previous class from the element
      if (prevSimulatedClass !== "") {
        element.removeClass(prevSimulatedClass);
      }
      // Add new class to the element if it's not default
      if (!simulatedClass.includes("default")) {
        element.addClass(simulatedClass);
      }
    });

    // Set new simulated class as previous
    setPrevSimulatedClass(simulatedClass);
  };

  return (
    <div style={{ color: "#fff" }}>
      {/* Text inputs in form: {textInputArray.length} */}
      <div className="styles-manager__sector appearance-manager__sector">
        <div className="property-composite">
          <PropertyLabel text={"State"} modifier={"primary"} clearValue={undefined} />
          <PropertySelect
            options={FORM_ELEMENT_OPTIONS}
            placeholder="Choose component state"
            onOptionSelect={handleSelectNewItem}
          />
        </div>
        <Corner el={<div></div>} property="gap" />
        <Padding el={<div></div>} property="padding" />
        <Accordion
          label="Stroke"
          variant={true}
          className="accordion--appearance"
          position="left"
          wrapperClassName="property-composite delete_bottom_margin"
        >
          <div className="styles-manager__sector appearance-manager__sector">
            <BorderWidth el={<div></div>} property="border-width" />
            <BorderStyle el={<div></div>} property="border-style" />
            <BorderColor el={<div></div>} property="border-color" />
          </div>
        </Accordion>
        <Accordion
          label="Background"
          variant={true}
          className="accordion--appearance"
          position="left"
          wrapperClassName="property-composite delete_bottom_margin"
        >
          <div className="styles-manager__sector appearance-manager__sector">
            <BackgroundColor el={<div></div>} property="background-color" />
            <Clip el={<div></div>} property="clip" />
          </div>
        </Accordion>
      </div>
    </div>
  );
};

const FormAppearanceCheckbox = ({ component }: { component: Component }) => {
  const editor = editorService.getEditor();
  const checkboxInputArray = component.find(".sc__form-item > .cj-label + .cj-checkbox");

  if (!checkboxInputArray) return <div></div>;

  const [prevSimulatedClass, setPrevSimulatedClass] = useState("");

  const defaultRule = editor?.Css.getRule(".cj-checkbox");
  const hoverRule = editor?.Css.getRule(".cj-checkbox:hover");
  const activeRule = editor?.Css.getRule(".cj-checkbox:active");
  const disabledRule = editor?.Css.getRule(".cj-checkbox:disabled");

  const defaultStyle = defaultRule?.attributes.style;
  const hoverStyle = hoverRule?.attributes.style;
  const activeStyle = activeRule?.attributes.style;
  const disabledStyle = disabledRule?.attributes.style;

  const FORM_ELEMENT_OPTIONS: Option[] = [
    { id: "1", value: "Default", label: "Default", style: defaultStyle },
    { id: "2", value: "Hover", label: "Hover", style: hoverStyle },
    { id: "5", value: "Active/Click", label: "Active/Click", style: activeStyle },
    { id: "6", value: "Disabled", label: "Disabled", style: disabledStyle },
  ];

  useEffect(() => {
    return () => {
      if (prevSimulatedClass !== "") {
        editor?.SelectorManager.remove(prevSimulatedClass);
        editor?.CssComposer.remove(`.cj-checkbox.${prevSimulatedClass}`);
      }
    };
  }, [prevSimulatedClass]);

  const handleSelectNewItem = (item: Option) => {
    // Get normalized label
    // Create simulated class name
    // Create simulated class selector
    // Create previous simulated class selector
    // Get simulated styles for the selected item
    const normalizedLabel = item.label.toLowerCase();
    const simulatedClass = `simulate-${normalizedLabel}`;

    const simulatedStyles = FORM_ELEMENT_OPTIONS.find((option) => option.id === item.id)?.style;

    // Remove previous class from the selector manager
    // and rule from the css composer
    if (prevSimulatedClass !== "" || !simulatedClass.includes("default")) {
      editor?.SelectorManager.remove(prevSimulatedClass);
      editor?.CssComposer.remove(`.cj-checkbox.${prevSimulatedClass}`);
    }

    if (!simulatedClass.includes("default")) {
      const selector_one = editor?.SelectorManager.add(".cj-checkbox");
      const selector_two = editor?.SelectorManager.add(simulatedClass);
      const rule = editor?.CssComposer.add([selector_one, selector_two]);
      rule?.set("style", simulatedStyles);
    }

    checkboxInputArray.forEach((element) => {
      // Remove previous class from the element
      if (prevSimulatedClass !== "") {
        element.removeClass(prevSimulatedClass);
      }
      // Add new class to the element if it's not default
      if (!simulatedClass.includes("default")) {
        element.addClass(simulatedClass);
      }
    });

    // Set new simulated class as previous
    setPrevSimulatedClass(simulatedClass);
  };

  const [canClear, setCanClear] = useState(false);

  return (
    <div style={{ color: "#fff" }}>
      {/* Text inputs in form: {textInputArray.length} */}
      <div className="styles-manager__sector appearance-manager__sector">
        <div className="property-composite">
          <PropertyLabel text={"State"} modifier={"primary"} clearValue={undefined} />
          <PropertySelect
            options={FORM_ELEMENT_OPTIONS}
            placeholder="Choose component state"
            onOptionSelect={handleSelectNewItem}
          />
        </div>
        <Corner el={<div></div>} property="gap" />
        <Padding el={<div></div>} property="padding" />
        <Accordion
          label="Stroke"
          variant={true}
          className="accordion--appearance"
          position="left"
          wrapperClassName="property-composite delete_bottom_margin"
        >
          <div className="styles-manager__sector appearance-manager__sector">
            <BorderWidth el={<div></div>} property="border-width" />
            <BorderStyle el={<div></div>} property="border-style" />
            <BorderColor el={<div></div>} property="border-color" />
          </div>
        </Accordion>
        <Accordion
          label="Background"
          variant={true}
          className="accordion--appearance"
          position="left"
          wrapperClassName="property-composite delete_bottom_margin"
        >
          <div className="styles-manager__sector appearance-manager__sector">
            <BackgroundColor el={<div></div>} property="background-color" />
            <Clip el={<div></div>} property="clip" />
          </div>
        </Accordion>
      </div>
    </div>
  );
};

const FormAppearanceButton = ({ component }: { component: Component }) => {
  const editor = editorService.getEditor();
  const textInputArray = component.find(".sc__form-item > .cj-label + .cj-input");

  if (!textInputArray) return <div></div>;

  const [prevSimulatedClass, setPrevSimulatedClass] = useState("");

  const defaultRule = editor?.Css.getRule(".cj-input");
  const hoverRule = editor?.Css.getRule(".cj-input:hover");
  const activeRule = editor?.Css.getRule(".cj-input:active");
  const disabledRule = editor?.Css.getRule(".cj-input:disabled");

  const defaultStyle = defaultRule?.attributes.style;
  const hoverStyle = hoverRule?.attributes.style;
  const activeStyle = activeRule?.attributes.style;
  const disabledStyle = disabledRule?.attributes.style;

  const sectors = editor?.StyleManager.getSectors();

  // console.log("sectors", sectors);

  const FORM_ELEMENT_OPTIONS: Option[] = [
    { id: "1", value: "Default", label: "Default", style: defaultStyle },
    { id: "2", value: "Hover", label: "Hover", style: hoverStyle },
    { id: "5", value: "Active/Click", label: "Active/Click", style: activeStyle },
    { id: "6", value: "Disabled", label: "Disabled", style: disabledStyle },
  ];

  useEffect(() => {
    return () => {
      if (prevSimulatedClass !== "") {
        editor?.SelectorManager.remove(prevSimulatedClass);
        editor?.CssComposer.remove(`.cj-input.${prevSimulatedClass}`);
      }
    };
  }, [prevSimulatedClass]);

  const handleSelectNewItem = (item: Option) => {
    // Get normalized label
    // Create simulated class name
    // Create simulated class selector
    // Create previous simulated class selector
    // Get simulated styles for the selected item
    const normalizedLabel = item.label.toLowerCase();
    const simulatedClass = `simulate-${normalizedLabel}`;

    const simulatedStyles = FORM_ELEMENT_OPTIONS.find((option) => option.id === item.id)?.style;

    // Remove previous class from the selector manager
    // and rule from the css composer
    if (prevSimulatedClass !== "" || !simulatedClass.includes("default")) {
      editor?.SelectorManager.remove(prevSimulatedClass);
      editor?.CssComposer.remove(`.cj-input.${prevSimulatedClass}`);
    }

    if (!simulatedClass.includes("default")) {
      const selector_one = editor?.SelectorManager.add(".cj-input");
      const selector_two = editor?.SelectorManager.add(simulatedClass);
      const rule = editor?.CssComposer.add([selector_one, selector_two]);
      rule?.set("style", simulatedStyles);
    }

    textInputArray.forEach((element) => {
      // Remove previous class from the element
      if (prevSimulatedClass !== "") {
        element.removeClass(prevSimulatedClass);
      }
      // Add new class to the element if it's not default
      if (!simulatedClass.includes("default")) {
        element.addClass(simulatedClass);
      }
    });

    // Set new simulated class as previous
    setPrevSimulatedClass(simulatedClass);
  };

  return (
    <div style={{ color: "#fff" }}>
      {/* Text inputs in form: {textInputArray.length} */}
      <div className="styles-manager__sector appearance-manager__sector">
        <div className="property-composite">
          <PropertyLabel text={"State"} modifier={"primary"} clearValue={undefined} />
          <PropertySelect
            options={FORM_ELEMENT_OPTIONS}
            placeholder="Choose component state"
            onOptionSelect={handleSelectNewItem}
          />
        </div>
        <Corner el={<div></div>} property="gap" />
        <Padding el={<div></div>} property="padding" />
        <Accordion
          label="Stroke"
          variant={true}
          className="accordion--appearance"
          position="left"
          wrapperClassName="property-composite delete_bottom_margin"
        >
          <div className="styles-manager__sector appearance-manager__sector">
            <BorderWidth el={<div></div>} property="border-width" />
            <BorderStyle el={<div></div>} property="border-style" />
            <BorderColor el={<div></div>} property="border-color" />
          </div>
        </Accordion>
        <Accordion
          label="Background"
          variant={true}
          className="accordion--appearance"
          position="left"
          wrapperClassName="property-composite delete_bottom_margin"
        >
          <div className="styles-manager__sector appearance-manager__sector">
            <BackgroundColor el={<div></div>} property="background-color" />
            <Clip el={<div></div>} property="clip" />
          </div>
        </Accordion>
      </div>
    </div>
  );
};
