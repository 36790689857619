import { BlockProperties } from "grapesjs";

import { normalizeLabel } from "../../../../../../../utils";

import { createFormItem } from "./createFormItem";
import { createInputComponent } from "./createInputComponent";
import { createTextareaComponent } from "./createTextareaComponent";
import { createCheckboxComponent } from "./createCheckboxComponent";
import { createButtonComponent } from "./createButtonComponent";

const placeholder = "Type Here";

const nameAndSurnameLabel = "Name & Surname";
const nameAndSurnameID = normalizeLabel(nameAndSurnameLabel);

const nameSurnameInput = createInputComponent({
  id: nameAndSurnameID,
  name: nameAndSurnameID,
  placeholder: placeholder,
  required: true,
});

const nameAndSurnameFormItem = createFormItem({
  labelText: nameAndSurnameLabel,
  labelFor: nameAndSurnameID,
  inputComponent: nameSurnameInput,
});

const topicLabel = "Topic";
const topicID = normalizeLabel(topicLabel);

const topicInput = createInputComponent({
  id: topicID,
  name: topicID,
  placeholder: placeholder,
  required: true,
});

const topicFormItem = createFormItem({
  labelText: topicLabel,
  labelFor: topicID,
  inputComponent: topicInput,
});

const emailLabel = "Email";
const emailID = normalizeLabel(emailLabel);

const emailInput = createInputComponent({
  id: emailID,
  name: emailID,
  type: "email",
  placeholder: placeholder,
  required: true,
});

const emailFormItem = createFormItem({
  labelText: emailLabel,
  labelFor: emailID,
  inputComponent: emailInput,
});

const textLabel = "Text Message";
const textID = normalizeLabel(textLabel);

const textInput = createTextareaComponent({
  id: textID,
  name: textID,
  placeholder: placeholder,
  required: true,
});

const textFormItem = createFormItem({
  labelText: textLabel,
  labelFor: textID,
  inputComponent: textInput,
});

const termsLabel = "Yes, I accept the Terms";
const termsID = "terms";

const termsCheckbox = createCheckboxComponent({
  id: termsID,
  name: termsID,
  value: "true",
});

const termsFormItem = createFormItem({
  labelText: termsLabel,
  labelFor: termsID,
  inputComponent: termsCheckbox,
  labelPosition: "after",
  // wrapperStyles: {
  //   "flex-direction": "row",
  // },
});

const submitButton = createButtonComponent({
  content: "Submit form",
  type: "submit",
});

const submitButtonFormItem = createFormItem({
  inputComponent: submitButton,
});

export const smartComponentForm: BlockProperties = {
  id: "Form",
  media: `<svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M0 30C0 28.8954 0.895431 28 2 28H38C39.1046 28 40 28.8954 40 30V34C40 35.1046 39.1046 36 38 36H2C0.895431 36 0 35.1046 0 34V30Z" fill="#D4D4D8"/><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V8C39.5 8.82843 38.8284 9.5 38 9.5H2C1.17157 9.5 0.5 8.82843 0.5 8V2Z" fill="#27272A" stroke="#71717A"/><path d="M0.5 16C0.5 15.1716 1.17157 14.5 2 14.5H38C38.8284 14.5 39.5 15.1716 39.5 16V22C39.5 22.8284 38.8284 23.5 38 23.5H2C1.17157 23.5 0.5 22.8284 0.5 22V16Z" fill="#27272A" stroke="#71717A"/></svg>`,
  label: "Form",
  category: "Forms",
  content: {
    type: "form",
    styles: `
      .cj-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 576px;
      }
      .cj-input {
        color: "#3F3F46";
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;

        display: flex;
        gap: 12px;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid #D4D4D8;
        background: #FFF;
      }
      .cj-textfield {
        display: flex;
        height: 200px;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid #D4D4D8;
        background: #FFF;
        resize: none;
      }
      .sc__form-item > .cj-button {
        color: #FFF;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        border-radius: 12px;
        border: 1px solid #27272A;
        background: #27272A;
        padding: 1rem;
        width: fit-content;
      }
      .sc__form-item > .cj-checkbox {
        cursor: pointer;
      }
      .sc__form-item > .cj-checkbox + .cj-label {
        cursor: pointer;
      }
      .sc__form-item:has(.cj-checkbox + .cj-label) {
        flex-direction: row;
      }
      .sc__form-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
      }
      .sc__form-item > .cj-label {
        font-family: Arial;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    `,
    components: [
      nameAndSurnameFormItem,
      topicFormItem,
      emailFormItem,
      textFormItem,
      termsFormItem,
      submitButtonFormItem,
    ],
  },
  select: true,
};
