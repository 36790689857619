import { BlockProperties } from "grapesjs";

export const block3ColEven: BlockProperties = {
  id: "block-3-col-even",
  media: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="11" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="14.5" y="0.5" width="11" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="28.5" y="0.5" width="11" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>
    `,
  label: "3 columns",
  category: "Layout",
  content: {
    type: "cjRow",
    components: [
      {
        type: "cjColumn",
        classes: ["cj-grid-column--3"],
      },
      {
        type: "cjColumn",
        classes: ["cj-grid-column--3"],
      },
      {
        type: "cjColumn",
        classes: ["cj-grid-column--3"],
      },
    ],
  },
  select: true,
};
