import { Home } from "../../../assets/svg/Home";
// import { Rocket } from "../../../../../assets/svg/Rocket";
// import { MoneyWithdraw } from "../../../assets/svg/MoneyWithdraw";
// import { SearchAlt } from "../../../../../assets/svg/SearchAlt";
// import { Fonts } from "../../../../../assets/svg/Fonts";
// import { Backups } from "../../../../../assets/svg/Backups";
// import { Analytics } from "../../../../../assets/svg/Analytics";
// import { CustomCode } from "../../../../../assets/svg/CustomCode";

import type { TProjectSettingsNavigation } from "./ProjectSettingsNavigation.types";

import "./ProjectSettingsNavigation.css";
import { ChevronDown } from "../../../assets/svg/ChevronDown";
import { dashboardInstance } from "../../../machines/dashboard/dashboard.instance";
import { Starter } from "../../../assets/svg/Starter";
import { useEffect, useState } from "react";
import { projectsInstance } from "../../../machines/projects/projects.instance";
import { Project } from "../../../types/types";
import { ButtonNew } from "../../atoms/ButtonNew";
import { UserService } from "../../../services/UserService/UserService";
import { FigmaInfoModal } from "../../pages/MyProjects/atoms/FigmaInfoModal";
import { FigmaInfoModal as FigmaInfoModalService } from "../../pages/MyProjects/atoms/FigmaInfoModal/FigmaInfoModal.service";

import {
  FigmaInfoModalBtns,
  FigmaInfoModalIcons,
} from "../../pages/MyProjects/atoms/FigmaInfoModal/FigmaInfoModal.types";
import {
  ACCOUNT_DELETION_INFO,
  FAILURE_SENDING_DELETION_LINK,
  LINK_DELETION_REQ_LIMIT_REACHED,
  SUCCESS_SENDING_DELETION_LINK,
} from "../../../constants/account-deletion-messages";
import { BUTTONS_CONFIG } from "../../pages/MyProjects/atoms/FigmaInfoModal/constants/buttonsConfig";

const figmaInfoModalService = FigmaInfoModalService.getInstance();
const userService = UserService.getInstance();

export const ProjectSettingsNavigation: TProjectSettingsNavigation = ({ activeTab, setActiveTab }) => {
  const [activeProject, setActiveProject] = useState<Project>();
  const [modalIcon, setModalIcon] = useState<FigmaInfoModalIcons>();
  const [modalTitle, setModalTitle] = useState<string>();
  const [modalMessage, setModalMessage] = useState<string>("");
  const [modalButtons, setModalButtons] = useState<FigmaInfoModalBtns[]>([]);
  const [isModalBtnLoading, setModalButtonLoading] = useState<boolean>(false);
  const getAccountDeletionLink = async () => {
    const statusCode = await userService.getAccountDeletionLink();
    setModalButtonLoading(false); 
    if (statusCode === 200) {
      setModalIcon("email");
      setModalMessage(SUCCESS_SENDING_DELETION_LINK.desc);
      setModalTitle(SUCCESS_SENDING_DELETION_LINK.title);
      setModalButtons([BUTTONS_CONFIG.close()]);
    }

    if (statusCode === 429) {
      setModalIcon("warning");
      setModalTitle(LINK_DELETION_REQ_LIMIT_REACHED.title);
      setModalMessage(LINK_DELETION_REQ_LIMIT_REACHED.desc);
      setModalButtons([BUTTONS_CONFIG.close()]);
    }

    if (statusCode !== 200 && statusCode !== 429) {
      setModalIcon("warning");
      setModalTitle(FAILURE_SENDING_DELETION_LINK.title);
      setModalMessage(FAILURE_SENDING_DELETION_LINK.desc);
      setModalButtons([BUTTONS_CONFIG.close()]);
    }
    figmaInfoModalService.openModal();
  };
  const handleSendDeletionLink = async () => {
    setModalButtonLoading(true); 
    await getAccountDeletionLink() 
  }
  const handleDeleteAccount = () => {
    setModalIcon("warning");
    setModalTitle(ACCOUNT_DELETION_INFO.title);
    setModalMessage(ACCOUNT_DELETION_INFO.desc);
    setModalButtons([BUTTONS_CONFIG.cancel(), BUTTONS_CONFIG.sendLink(handleSendDeletionLink)]);
    figmaInfoModalService.openModal();
  };
  const { priceId = "" } = activeProject?.subscription || {};

  useEffect(() => {
    const project = projectsInstance.getSnapshot().context.service.getActiveProject();
    setActiveProject(project);
  }, []);

  return (
    <aside className="project-settings__navigation">
      <button className="project-settings__breadcrumb" onClick={() => dashboardInstance.send("PROJECTS")}>
        <ChevronDown />
        Back to my projects
      </button>
      <button
        className={`project-settings__navigation-button${
          activeTab === "GENERAL" ? " project-settings__navigation-button--active" : ""
        }`}
        onClick={() => {
          if (activeTab !== "GENERAL") setActiveTab("GENERAL");
        }}
      >
        <Home />
        <span className="project-settings__navigation-text">General</span>
      </button>
      {/* <button className="project-settings__navigation-button">
        <Rocket />
        <p className="project-settings__navigation-text">Publish</p>
      </button> */}
      <button
        className={`project-settings__navigation-button${
          activeTab === "PLANS" ? " project-settings__navigation-button--active" : ""
        }`}
        onClick={() => {
          if (activeTab !== "PLANS") setActiveTab("PLANS");
        }}
      >
        <Home />
        <p className="project-settings__navigation-text">Site plan</p>
      </button>
      {/* <button className="project-settings__navigation-button">
        <SearchAlt /> <p className="project-settings__navigation-text">SEO</p>
      </button>
      <button className="project-settings__navigation-button">
        <Fonts /> <p className="project-settings__navigation-text">Fonts</p>
      </button>
      <button className="project-settings__navigation-button">
        <Backups />
        <p className="project-settings__navigation-text">Backups</p>
      </button>
      <button className="project-settings__navigation-button">
        <Analytics />
        <p className="project-settings__navigation-text">Analytics</p>
      </button>
      <button className="project-settings__navigation-button">
        <CustomCode /> <p className="project-settings__navigation-text">Custom code</p>
      </button> */}

      {priceId === "" && (
        <div className="project-settings__info-card">
          <p className="project-settings__info-card-label">Current website plan</p>
          <p className="project-settings__info-card-plan">
            <Starter className="project-settings__info-card-svg" />
            Starter plan
          </p>
          <p className="project-settings__info-card-heading">Need more project pages or custom domain ?</p>
          <p className="project-settings__info-card-description">
            Upgrade to the Essential Plan for enhanced capabilities to create and publish up to 50 pages on your custom
            domains.
          </p>
          <ButtonNew orange onClick={() => setActiveTab("PLANS")}>
            View site plans
          </ButtonNew>
        </div>
      )}
     { import.meta.env.VITE_APP_BASE_URL !== "https://app.codejet.ai"&&<ButtonNew //TODO remove this flag when we are ready to release this feature
        danger
        onClick={() => {
          handleDeleteAccount();
        }}
      >
        Delete account
      </ButtonNew>}
      <FigmaInfoModal icon={modalIcon} title={modalTitle} message={modalMessage} buttons={modalButtons} isLoading={isModalBtnLoading}/>
    </aside>
  );
};
