import { type CSSProperties } from "react";

type TInfo2 = {
  className?: string;
  style?: CSSProperties;
};

export const Info2 = ({ className, style }: TInfo2) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14 27.5C6.54416 27.5 0.5 21.4558 0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14C27.5 21.4558 21.4558 27.5 14 27.5Z"
      fill="#092F7C"
    />
    <path
      d="M14 27.5C6.54416 27.5 0.5 21.4558 0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14C27.5 21.4558 21.4558 27.5 14 27.5Z"
      stroke="#1976D2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1098 8C14.6826 8.0011 15.1466 8.46576 15.1466 9.03879C15.1466 9.6125 14.6815 10.0776 14.1078 10.0776M14.1057 10.0776C13.533 10.0765 13.069 9.61182 13.069 9.03879C13.069 8.46509 13.5341 8 14.1078 8M12.4526 12.5517C12.1074 12.5517 11.8276 12.8315 11.8276 13.1767C11.8276 13.5219 12.1074 13.8017 12.4526 13.8017H13.4827V18.3448H11.625C11.2798 18.3448 11 18.6247 11 18.9698C11 19.315 11.2798 19.5948 11.625 19.5948H16.5905C16.9357 19.5948 17.2155 19.315 17.2155 18.9698C17.2155 18.6247 16.9357 18.3448 16.5905 18.3448H14.7327V13.1767C14.7327 12.8315 14.4529 12.5517 14.1077 12.5517H12.4526Z"
      fill="#BBDEFB"
    />
  </svg>
);
