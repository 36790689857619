import React, { useEffect, useState } from "react";

import { PropertiesPopup } from "./PropertiesPopup";
import { PropertiesPopupField } from "./PropertiesPopupField";
import { PropertiesPopupSwitch } from "./PropertiesPopupSwitch";
import { Select } from "../Select";

import { useDebounce } from "../../../../../../../hooks/useDebounce/useDebounce";
import { normalizeLabel } from "../../../../../../../utils";

import type { Component } from "grapesjs";

type TInputPropertyProperties = {
  comp: Component;
  domRect: DOMRect | undefined;
  closePopup: () => void;
  notifyParent: () => void;
  label?: string;
};

type TInputProperty = (props: TInputPropertyProperties) => React.ReactNode;

export const InputProperty: TInputProperty = ({
  comp,
  domRect,
  closePopup,
  notifyParent,
  label: labelProp = "Input Properties",
}) => {
  const labelComponent = comp.components().find((child) => child.get("type") === "label");
  const inputComponent = comp.components().find((child) => child.get("type") !== "label");

  const [label, setLabel] = useState(labelComponent.content || "");
  const [placeholder, setPlaceholder] = useState(inputComponent.getAttributes().placeholder || "");
  const [id, setID] = useState(inputComponent.getAttributes().id || "");
  const [required, setRequired] = useState(inputComponent.getAttributes().required || false);

  const debouncedLabel = useDebounce(label, 500);
  const debouncedPlaceholder = useDebounce(placeholder, 500);
  const debouncedID = useDebounce(id.replace(/^-|-$/g, ""), 500); // Trim leading and trailing dashes
  const debouncedRequired = useDebounce(required, 500);

  const XOffset = 16;

  useEffect(() => {
    setLabel(labelComponent.content || "");
    setPlaceholder(inputComponent.getAttributes().placeholder || "");
    setID(inputComponent.getAttributes().id || "");
    setRequired(inputComponent.getAttributes().required || false);
  }, [comp]);

  useEffect(() => {
    const currentLabel = labelComponent.content;

    if (currentLabel === debouncedLabel) return;
    const normalizedLabel = normalizeLabel(debouncedLabel);

    labelComponent.set("content", debouncedLabel);
    labelComponent.set("attributes", { for: normalizedLabel });

    const inputAttributes = inputComponent.getAttributes();

    inputComponent.set("attributes", { ...inputAttributes, id: normalizedLabel });

    notifyParent && notifyParent();
  }, [debouncedLabel]);

  useEffect(() => {
    const currentPlaceholder = inputComponent.getAttributes().placeholder;

    if (currentPlaceholder === debouncedPlaceholder) return;
    const inputAttributes = inputComponent.getAttributes();

    inputComponent.set("attributes", { ...inputAttributes, placeholder: debouncedPlaceholder });

    notifyParent && notifyParent();
  }, [debouncedPlaceholder]);

  useEffect(() => {
    const currentID = inputComponent.getAttributes().id;

    if (currentID === debouncedID) return;
    const inputAttributes = inputComponent.getAttributes();

    inputComponent.set("attributes", { ...inputAttributes, id: debouncedID });

    notifyParent && notifyParent();
  }, [debouncedID]);

  useEffect(() => {
    const currentRequired = inputComponent.getAttributes().required;

    if (currentRequired === debouncedRequired) return;
    const inputAttributes = inputComponent.getAttributes();

    inputComponent.set("attributes", { ...inputAttributes, required: debouncedRequired });

    notifyParent && notifyParent();
  }, [debouncedRequired]);

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (label === e.target.value) return;

    setLabel(e.target.value);
  };

  const handleChangePlaceholder = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (placeholder === e.target.value) return;

    setPlaceholder(e.target.value);
  };

  const handleChangeID = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (id === e.target.value) return;
    const normalizedID = normalizeLabel(e.target.value);
    setID(normalizedID);
  };

  const handleChangeRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (required === e.target.checked) return;

    setRequired(e.target.checked);
  };

  if (!domRect) return null;

  // popup position
  const sx = {
    top: domRect?.top,
    left: domRect?.left - XOffset,
  };

  const parsedRequiredStatus = required === "true" || required === "false" ? JSON.parse(required) : required;

  return (
    <PropertiesPopup sx={sx} closePopup={closePopup} title={labelProp}>
      {inputComponent.get("tagName") === "input" && inputComponent.getAttributes().type !== "checkbox" && (
        <Select className="option-popup__field" trait={inputComponent.getTrait("type")} />
      )}
      <PropertiesPopupField id={`${comp.cid}-1`} value={label} handleChangeValue={handleChangeLabel} title="Label" />
      {inputComponent.getAttributes().type !== "checkbox" && (
        <PropertiesPopupField
          id={`${comp.cid}-2`}
          value={placeholder}
          handleChangeValue={handleChangePlaceholder}
          title="Placeholder"
        />
      )}
      {inputComponent.getAttributes().type === "checkbox" && (
        <PropertiesPopupField id={`${comp.cid}-3`} value={id} handleChangeValue={handleChangeID} title="Name" />
      )}
      <PropertiesPopupSwitch
        id={`${comp.cid}-4`}
        value={parsedRequiredStatus}
        handleChangeValue={handleChangeRequired}
        title="Required"
      />
    </PropertiesPopup>
  );
};
