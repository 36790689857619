import { Switch } from "../../../../../../../AlphaO/atoms/Switch";

import { TPropertiesPopupSwitch } from "./PropertiesPopupSwitch.types";

export const PropertiesPopupSwitch: TPropertiesPopupSwitch = ({ id, value, handleChangeValue, title = "Value" }) => {
  return (
    <div className="option-popup__field option-popup__flex-space-between">
      <label htmlFor={`option-value-${id}`} className="trait-manager__checkbox-label option-popup__checkbox-label">
        {title}
      </label>
      <Switch id={`option-value-${id}`} isDisabled={false} onChange={handleChangeValue} isActive={value} />
    </div>
  );
};
