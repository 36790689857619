import { Editor } from "grapesjs";

/**
 * Prevents the default zoom behavior in the browser by adding event listeners
 * to the window and an iframe element with the class "gjs-frame".
 *
 * - Adds a 'wheel' event listener to the window to prevent zooming with the mouse wheel.
 * - Adds a 'keydown' event listener to the window to prevent zooming with keyboard shortcuts.
 * - Adds a 'load' event listener to the iframe to handle additional iframe-specific logic.
 *
 * @remarks
 * The event listeners are added with `{ passive: false, capture: true }` options to ensure
 * that the default behavior can be prevented and the events are captured during the capture phase.
 */
export const preventDefaultZoom = (editor: Editor | null) => {
  const iframe = document.querySelector("iframe.gjs-frame") as HTMLIFrameElement;
  const body = editor?.Canvas.getBody();

  if (iframe) {
    window.addEventListener("wheel", preventDefaultWheelEvent, { passive: false, capture: true });
    window.addEventListener("keydown", preventDefaultKeyEvent, { passive: false, capture: true });
    iframe.addEventListener("load", () => handleLoadIframe(iframe));
  }

  if (body) {
    body.addEventListener("wheel", preventDefaultWheelEvent, { passive: false });
  }
};

/**
 * Prevents the default zoom behavior when the wheel event is triggered with the Ctrl key pressed.
 *
 * @param e - The wheel event object.
 */
const preventDefaultWheelEvent = (e: WheelEvent) => {
  if (e.ctrlKey || e.metaKey) e.preventDefault();
};

/**
 * Prevents the default zooming behavior in the browser when the user presses
 * the Ctrl key along with the "+" (plus), "=" (equals), or "-" (minus) keys.
 *
 * @param e - The keyboard event to be handled.
 */
const preventDefaultKeyEvent = (e: KeyboardEvent) => {
  if ((e.ctrlKey || e.metaKey) && (e.key === "+" || e.key === "=" || e.key === "-")) e.preventDefault();
};

/**
 * Attaches event listeners to an iframe to prevent default zoom actions.
 *
 * @param iframe - The HTMLIFrameElement to which the event listeners will be attached.
 *
 * The function adds two event listeners to the iframe's content window:
 * - A 'wheel' event listener to prevent zooming via mouse wheel.
 * - A 'keydown' event listener to prevent zooming via keyboard shortcuts.
 *
 * Both event listeners are added with the options `{ passive: false, capture: true }`.
 */
const handleLoadIframe = (iframe: HTMLIFrameElement) => {
  const iframeWindow = iframe.contentWindow;

  if (iframeWindow) {
    iframeWindow?.addEventListener("wheel", preventDefaultWheelEvent, { passive: false, capture: true });
    iframeWindow?.addEventListener("keydown", preventDefaultKeyEvent, { passive: false, capture: true });
  }
};
