import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../../hooks/useDebounce/useDebounce";

import "./CustomSlider.css";

export const CustomSlider = ({
  value = "0",
  rangeMax = 100,
  changeProps,
}: {
  value: string;
  rangeMax: number;
  changeProps: (newValue: string) => void;
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [localValue, setLocalValue] = useState(value);
  const [elementWidth, setElementWidth] = useState(0);
  const debouncedValue = useDebounce(localValue, 400);

  useEffect(() => {
    if (localValue !== value) {
      changeProps(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (sliderRef.current) {
      setElementWidth(sliderRef.current.getBoundingClientRect().width);
    }
  }, [sliderRef.current]);

  useEffect(() => {
    setLocalValue(value !== "" ? value : "0");
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  // set in css
  const thumbSize = 14;
  // Calculate the ratio of current value to maximum value (0 to 1)
  const valueRatio = parseInt(localValue) / rangeMax;

  // Calculate the size of the track's filled portion
  // Interpolate between element width and thumb size based on the ratio
  const size = elementWidth * valueRatio + thumbSize * (1 - valueRatio);

  // Calculate the thumb position as a percentage with pixel offset
  const percentagePosition = valueRatio * 100;
  const pixelOffset = thumbSize * valueRatio;
  const thumbPosition = `calc(${percentagePosition}% - ${pixelOffset}px)`;
  return (
    <div className="custom_slider" ref={sliderRef}>
      <input
        type="range"
        min="0"
        max={rangeMax}
        value={localValue}
        onChange={handleChange}
        className="range-slider__range"
      />
      <div className="custom_slider__thumb" style={{ left: thumbPosition }}></div>
      <div className="custom_slider__track">
        <div className="custom_slider__track-before" style={{ width: `${size}px` }}></div>
        <div className="custom_slider__track-after"></div>
      </div>
    </div>
  );
};
