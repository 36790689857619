import useMobileViewport from "../../../hooks/useMobileViewport/useMobileViewport";

import { MobileBreadcrumb } from "./atoms/MobileBreadcrumb";

import { Check } from "../../../../assets/svg/check";
import { Connector } from "../../../../assets/svg/connector";
import { ConnectorNotActive } from "../../../../assets/svg/connector_not_active";

import type { TBreadcrumb } from "./Breadcrumb.types";

import "./Breadcrumb.css";

const stages = ["Select Template", "Site plan", "Marketing plan", "Wrap up"];

export const Breadcrumb: TBreadcrumb = ({ stage, handleOnClick }) => {
  const isMobile = useMobileViewport();

  return isMobile ? (
    <MobileBreadcrumb stage={stage} handleOnClick={handleOnClick} />
  ) : (
    <div className="breadcrumb__wrapper">
      <div className="breadcrumb">
        {stages.map((stageName, index) => {
          const isLastStage = index === stages.length - 1;
          const isCurrentStage = stage === index + 1;
          const isDone = stage > index + 1;
          const type = isCurrentStage ? "current" : isDone ? "done" : "future";
          const isActiveConnector = stage === index + 2;

          return (
            <div key={index} className="breadcrumb__container">
              <div className="breadcrumb__item">
                {isDone ? <Check className="breadcrumb__check" /> : null}
                <div
                  className={`breadcrumb__typography breadcrumb__${type}-stage`}
                  onClick={() => handleOnClick(index)}
                >
                  {!isDone && <span>{index + 1} </span>}
                  {stageName}
                </div>
              </div>
              {!isLastStage ? (
                <div className={`breadcrumb__connector ${type === "done" ? "breadcrumb__connector-done" : ""}`}>
                  {isActiveConnector ? <Connector /> : <ConnectorNotActive />}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
