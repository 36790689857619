import { useEffect, useState } from "react";
import { editorService } from "../../../pages/Alpha_O/modules/editor";
import { detectMacOS } from "../../../../machines/dashboard/helper/detectMacOS";

export const ZoomComponent = () => {
  const [zoom, setZoom] = useState(100);
  const editor = editorService.getEditor();

  // Określenie, który klawisz modyfikujący jest używany
  const isModifierKeyPressed = (e: KeyboardEvent | WheelEvent) => (detectMacOS() ? e.metaKey : e.ctrlKey);

  const handleWheelEvent = (e: WheelEvent) => {
    if (isModifierKeyPressed(e)) {
      const zoom = editor?.Canvas.getZoom() || 100;
      if (e.deltaY > 0) {
        setZoom((prev) => prev - 1);
        editor?.Canvas.setZoom(zoom - 1);
      } else {
        setZoom((prev) => prev + 1);
        editor?.Canvas.setZoom(zoom + 1);
      }
    }
  };

  const handleKeyEvent = (e: KeyboardEvent) => {
    if (isModifierKeyPressed(e)) {
      if (e.key === "+" || e.key === "=") {
        const zoom = editor?.Canvas.getZoom() || 100;
        setZoom((prev) => prev + 1);
        editor?.Canvas.setZoom(zoom + 1);
      }
      if (e.key === "-" || e.key === "_") {
        const zoom = editor?.Canvas.getZoom() || 100;
        setZoom((prev) => prev - 1);
        editor?.Canvas.setZoom(zoom - 1);
      }
      if (e.key === "0") {
        setZoom(100);
        editor?.Canvas.setZoom(100);
      }
    }
  };

  useEffect(() => {
    const canvasWrapper = document.querySelector(".main-section__editor") as HTMLDivElement;
    window.addEventListener("keydown", handleKeyEvent);
    if (editor && canvasWrapper) {
      // handle wheel event on over grapejs canvas
      canvasWrapper.addEventListener("wheel", handleWheelEvent);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyEvent);
      if (canvasWrapper) {
        canvasWrapper.removeEventListener("wheel", handleWheelEvent);
      }
    };
  }, [editor]);

  return <span className="top-bar__zoom">{zoom}%</span>;
};
