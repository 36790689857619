import { fixedComponentDefinition } from "../block-helpers";

import type { CSSStyle } from "../../../../../../../types";
import type { ComponentDefinition } from "grapesjs";

type CrateInputComponentAttrs = {
  id: string;
  name?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  styles?: CSSStyle;
  attributes?: ComponentDefinition["attributes"];
};

type CreateInputComponent = (attrs: CrateInputComponentAttrs) => ComponentDefinition;

/**
 * Creates an input component configuration object.
 *
 * @param id - The unique identifier for the input component.
 * @param name - The name attribute of the input element.
 * @param type - The input type (e.g., "text", "password"). Defaults to "text".
 * @param placeholder - The placeholder text for the input. Defaults to an empty string.
 * @param required - Whether the input is required. Defaults to false.
 * @param styles - Custom styles to apply to the component.
 * @param attributes - Additional attributes to include in the input element.
 * @returns An object representing the input component configuration.
 */
export const createInputComponent: CreateInputComponent = ({
  id,
  name,
  type = "text",
  placeholder = "",
  required = false,
  styles = {},
  attributes = {},
}) => {
  return {
    type: "input",
    ...fixedComponentDefinition,
    style: {
      ...styles,
    },
    attributes: {
      id,
      name,
      type,
      placeholder,
      ...(required ? { required: "true" } : {}),
      ...attributes,
    },
  };
};
