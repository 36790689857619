import { useRef, useState } from "react";

import type { ReactNode } from "react";
import { PropertyLabel, StyleManagerInput } from "../../styles-manager/components";
import { Button } from "../../../../../AlphaO/atoms/Button";

import { Composition } from "../../../../../../assets/svg/Composition";
import { Composition2 } from "../../../../../../assets/svg/Composition2";
import { sectorsConfig } from "../../styles-manager/sectors.config";

//find property named border-style in sectorsConfig
export const getConfigByProperty = (propertyName: string) => {
  for (const sector of sectorsConfig) {
    if (sector.properties) {
      for (const property of sector.properties) {
        if (typeof property !== "string" && property.property === propertyName) {
          return property;
        }
        if (typeof property !== "string" && property.type === "composite" && property.properties) {
          const nestedProperty = property.properties.find((nested) => nested.property === propertyName);
          if (nestedProperty) {
            return nestedProperty;
          }
        }
      }
    }
  }
  return null; // Jeśli nie znaleziono konfiguracji
};

const propertyName = "background-color";

const moreIcon = [
  "border-width",
  "border-color",
  "border-style",
  "transform-origin",
  "overflow",
  "perspective-origin",
].includes(propertyName) ? (
  <Composition2 />
) : (
  <Composition />
);

type BackgroundColorProps = {
  el: ReactNode;
  property: string;
};

type TBackgroundColor = (props: BackgroundColorProps) => ReactNode;

export const BackgroundColor: TBackgroundColor = () => {
  // export const BackgroundColor: TBackgroundColor = ({ el, property }) => {
  const propertyRef = useRef<HTMLDivElement>(null);
  const [canClear, setCanClear] = useState(false);
  const [globalValue, setGlobalValue] = useState("#000");
  const [isCustomVisible, setCustomVisible] = useState(false);
  const allValueEqual = true;

  const handleClearValue = () => {};

  const handleChangeValue = () => {};

  const handleChange = (e: string) => {};

  const onClickHandler = () => {};

  const normalizePropertyName = (input: string): string => {
    return input
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <div className="property-composite" ref={propertyRef}>
        <PropertyLabel
          text={normalizePropertyName(propertyName)}
          modifier={canClear ? "edited" : "primary"}
          clearValue={canClear ? () => handleChangeValue() : undefined}
        />
        <div className="property-composite__custom">
          <div style={{ display: "grid", gridTemplateColumns: "minmax(0, 1fr) auto" }}>
            <StyleManagerInput
              type="text"
              value={globalValue ?? "transparent"}
              handleChange={handleChange}
              hasUnits={false}
              placeholder={"#000"}
              color={true}
              handleColorPicker={onClickHandler}
            />
          </div>
          <Button
            type="icon"
            iconStart={moreIcon}
            onClick={() => setCustomVisible((prev) => !prev)}
            className={`${!allValueEqual || isCustomVisible ? "active" : ""}`}
          />
        </div>
      </div>
      {isCustomVisible && (
        <div className="property-composite__properties">
          <div className={`color-property full-width`} ref={propertyRef}>
            <PropertyLabel
              text={"Top"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
            />
            <StyleManagerInput
              type="text"
              value={globalValue ?? "transparent"}
              handleChange={handleChange}
              hasUnits={false}
              // placeholder={placeholder}
              color={true}
              handleColorPicker={onClickHandler}
              placeholder={""}
            />
          </div>
          <div className={`color-property full-width`} ref={propertyRef}>
            <PropertyLabel
              text={"Right"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
            />
            <StyleManagerInput
              type="text"
              value={globalValue ?? "transparent"}
              handleChange={handleChange}
              hasUnits={false}
              // placeholder={placeholder}
              color={true}
              handleColorPicker={onClickHandler}
              placeholder={""}
            />
          </div>
          <div className={`color-property full-width`} ref={propertyRef}>
            <PropertyLabel
              text={"Bottom"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
            />
            <StyleManagerInput
              type="text"
              value={globalValue ?? "transparent"}
              handleChange={handleChange}
              hasUnits={false}
              // placeholder={placeholder}
              color={true}
              handleColorPicker={onClickHandler}
              placeholder={""}
            />
          </div>
          <div className={`color-property full-width`} ref={propertyRef}>
            <PropertyLabel
              text={"Left"}
              modifier={canClear ? "edited" : "primary"}
              clearValue={canClear ? () => handleClearValue() : undefined}
            />
            <StyleManagerInput
              type="text"
              value={globalValue ?? "transparent"}
              handleChange={handleChange}
              hasUnits={false}
              color={true}
              handleColorPicker={onClickHandler}
              placeholder={"#000"}
            />
          </div>
        </div>
      )}
    </>
  );
};
