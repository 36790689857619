import { BlockProperties } from "grapesjs";

export const blockGrid: BlockProperties = {
  id: "block-grid",
  media: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.16669" y="0.5" width="18" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="22.1667" y="0.5" width="18" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="1.16669" y="21.5" width="18" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="22.1667" y="21.5" width="18" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>`,
  label: "Grid",
  category: "Layout",
  content: {
    type: "cjRow",
    components: [
      {
        type: "cjColumn",
        classes: ["cj-grid-column--2"],
      },
      {
        type: "cjColumn",
        classes: ["cj-grid-column--2"],
      },
      {
        type: "cjColumn",
        classes: ["cj-grid-column--2"],
      },
      {
        type: "cjColumn",
        classes: ["cj-grid-column--2"],
      },
    ],
  },
  select: true,
};
