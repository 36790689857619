import { LoadingDots } from "../LoadingDots";

import type { TButtonNew } from "./ButtonNew.types";

import "./ButtonNew.css";

export const ButtonNew: TButtonNew = ({
  className,
  iconStart,
  iconEnd,
  orange = false,
  danger = false,
  error = false,
  onClick,
  href,
  children,
  disabled,
  type = "button",
  target = "_blank",
  rel = "noopener noreferrer",
  loading = false,
}) => {
  const baseClass = "button";
  const isTextButton = type === "text";

  const classes = [
    baseClass,
    orange && `${baseClass}--orange`,
    danger && `${baseClass}--danger`,
    error && `${baseClass}--error`,
    disabled && `${baseClass}--disabled`,
    isTextButton && `${baseClass}--text`,
    className,
  ]
    .filter(Boolean)
    .join(" ");

  if (href) {
    return (
      <a href={href} className={classes} onClick={onClick} target={target} rel={rel}>
        {iconStart && <span className={`${baseClass}__icon-start`}>{iconStart}</span>}
        <span className={`${baseClass}__label`}>{children}</span>
        {iconEnd && <span className={`${baseClass}__icon-end`}>{iconEnd}</span>}
        {loading && <LoadingDots />}
      </a>
    );
  } else {
    return (
      <button className={classes} onClick={onClick} disabled={disabled} type="button">
        {iconStart && <span className={`${baseClass}__icon-start`}>{iconStart}</span>}
        <span className={`${baseClass}__label`}>{children}</span>
        {iconEnd && <span className={`${baseClass}__icon-end`}>{iconEnd}</span>}
        {loading && <LoadingDots />}
      </button>
    );
  }
};
