import { modalMachineInstance } from "../..";

import { TriggerService } from "../../../../../../../services/TriggerService/TriggerService";

import { ModalHeader } from "../../partials/ModalHeader";
import { SignUpContent } from "../../partials/SignUpContent";
import { ModalFooter } from "../../partials/ModalFooter";

import "./SignUpModal.css";

const triggerService = TriggerService.getInstance();

export const SignUpModal = () => {
  const modalContent = triggerService.getModalContent();
  const handleCancel = () => {
    modalMachineInstance.send("CLOSE");
  };
  return (
    <div className="sign-up-modal new-colors">
      <ModalHeader
        title={modalContent?.title || "Want to publish your website?"}
        description={
          modalContent?.description || "To publish your website, you need to create an account in our service"
        }
        type="info"
        position="center"
      />
      <SignUpContent />
      <ModalFooter onCancel={handleCancel} />
    </div>
  );
};
