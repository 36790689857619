import { ButtonNew } from "../ButtonNew";

import type { TNewTemplateCard } from "./NewTemplateCard.types";

import "./NewTemplateCard.css";

export const NewTemplateCard: TNewTemplateCard = ({
  name,
  thumbnail,
  onUseTemplate,
  onPreview,
  hoverButtons = true,
  hasUserData = false,
}) => {
  return (
    <>
      <div className="new-template-card" tabIndex={0} role="button">
        <div className="new-template-card__thumbnail">
          <img
            className={`new-template-card__thumbnail-img${
              !hoverButtons ? "" : " new-template-card__thumbnail-img--big"
            }`}
            src={thumbnail}
            loading="lazy"
            alt="template thumbnail"
          />
          {hoverButtons && (
            <>
              <div className="new-template-card__dark-background"></div>
              <div className="new-template-card__hover-buttons">
                <ButtonNew className="new-template-card__thumbnail-button" orange onClick={onUseTemplate}>
                  Use this template
                </ButtonNew>
                <ButtonNew className="new-template-card__thumbnail-button" onClick={onPreview}>
                  Preview
                </ButtonNew>
              </div>
            </>
          )}
        </div>
        <div className="new-template-card__info">
          <div className="new-template-card__typography">
            <div className="new-template-card__title">{name}</div>
            <div className="new-template-card__price">Free</div>
          </div>
        </div>
        {!hoverButtons && (
          <div className="new-template-card__visible-buttons">
            <ButtonNew className="new-template-card__thumbnail-button" onClick={onPreview}>
              Preview
            </ButtonNew>
            <ButtonNew className="new-template-card__thumbnail-button" orange onClick={onUseTemplate}>
              {hasUserData ? "Add project" : "Click to edit"}
            </ButtonNew>
          </div>
        )}
      </div>
    </>
  );
};
