import { BlockProperties } from "grapesjs";

export const image: BlockProperties = {
  id: "image",
  media: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_23265_5084)"><path class="dark-fill" d="M22.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V22.3333C1 23.8061 2.19391 25 3.66667 25H22.3333C23.8061 25 25 23.8061 25 22.3333V3.66667C25 2.19391 23.8061 1 22.3333 1Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.33203 10.3335C9.4366 10.3335 10.332 9.43807 10.332 8.3335C10.332 7.22893 9.4366 6.3335 8.33203 6.3335C7.22746 6.3335 6.33203 7.22893 6.33203 8.3335C6.33203 9.43807 7.22746 10.3335 8.33203 10.3335Z" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path d="M24.9993 17L18.3327 10.3333L3.66602 25" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_23265_5084"><rect width="26" height="26" fill="white"/></clipPath></defs></svg>`,
  label: "Image",
  category: "Layout",
  content: {
    type: "image",
  },
  select: true,
};
