import { BlockProperties } from "grapesjs";

export const CJInput: BlockProperties = {
  id: "CJInput",
  media: `<svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V14C39.5 14.8284 38.8284 15.5 38 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" fill="#27272A"/><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V14C39.5 14.8284 38.8284 15.5 38 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" stroke="#71717A"/><line class="light-stroke" x1="4.5" y1="4.5" x2="4.5" y2="11.5" stroke="#D4D4D8" stroke-linecap="round"/></svg>`,
  label: "Input",
  category: "Forms",
  content: {
    tag: "div",
    style: { display: "flex", "flex-direction": "column" },
    components: [
      {
        type: "label",
        content: "Name",
      },
      {
        type: "input",
        attributes: { placeholder: "Enter your name" },
      },
    ],
  },
  select: true,
};
