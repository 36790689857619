import { BlockProperties } from "grapesjs";

export const icon: BlockProperties = {
  id: "icon",
  media: `<svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="dark-fill" d="M14.3333 1L18.4533 9.34667L27.6667 10.6933L21 17.1867L22.5733 26.36L14.3333 22.0267L6.09333 26.36L7.66667 17.1867L1 10.6933L10.2133 9.34667L14.3333 1Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  label: "Icon",
  category: "Layout",
  // content: {
  //   type: "text",
  //   classes: ["material-symbols-outlined"],
  //   tagName: "span",
  //   content: "search",
  // },
  content: {
    type: "icon",
  },
  select: true,
};

// {
//   id: "icon",
//   media: `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39.9993 26.6665L44.1193 35.0132L53.3327 36.3598L46.666 42.8532L48.2393 52.0265L39.9993 47.6932L31.7593 52.0265L33.3327 42.8532L26.666 36.3598L35.8793 35.0132L39.9993 26.6665Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
//   label: "Icon",
//   category: "Layout",
//   content: {
//     type: "svg",
//     attributes: { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", width: "24px", height: "24px" },
//     content: `<path
//       d="M15.2496 8.75116V14.0003L11.7497 10.4997L11.7515 7.00187H8.24986V10.5004L4.75 14.0003V8.75116L10.0029 3.5L15.2496 8.75116Z"
//       fill="white"
//     />
//     <path d="M11.7497 17.5V14.0004H8.27994V17.5H11.7497Z" fill="#D9D9D9" />
//     <path
//       d="M15.2496 8.75116V14.0003L11.7497 10.4997L11.7515 7.00187H8.24986V10.5004L4.75 14.0003V8.75116L10.0029 3.5L15.2496 8.75116Z"
//       fill="#FF4900"
//     />`,
//   },
//   select: true,
// },
