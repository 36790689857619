import { TModalHeader } from "./ModalHeader.types";

import "./ModalHeader.css";
import { Info2 } from "../../../../../../../assets/svg/Info2";
import { AlertTriangle } from "../../../../../../../../assets/svg/alert-triangle";

export const ModalHeader: TModalHeader = ({ title, description, type, position = "start", iconPosition = "start" }) => {
  const headerClassNames = ["modal-header", position === "center" && "modal-header--center"].filter(Boolean).join(" ");

  const iconClassNames = [
    "modal-header__icon",
    iconPosition === "start" && "modal-header__icon--start",
    iconPosition === "center" && "modal-header__icon--center",
    iconPosition === "end" && "modal-header__icon--end",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <header className={headerClassNames}>
      <div className="modal-header__wrapper">
        <h2 className="modal-header__title">{title}</h2>
        {description && <p className="modal-header__description">{description}</p>}
      </div>
      <div className={iconClassNames}>
        {type === "info" && <Info2 />}
        {type === "warning" && (
          <div className="modal-header__warning">
            <AlertTriangle />
          </div>
        )}
      </div>
    </header>
  );
};
