import React from "react";
import { LinkProperty } from "../../partials/LinkProperty";
import { InputProperty } from "../../partials/InputProperty";

import type { Component } from "grapesjs";

type PropertyComponentProps = {
  selectedItem: Component;
  boundingClientRect: DOMRect | undefined;
  handleClosePopup: () => void;
  refetchData: () => void;
};

export const PropertyComponent: React.FC<PropertyComponentProps> = ({
  selectedItem,
  boundingClientRect,
  handleClosePopup,
  refetchData,
}) => {
  if (!selectedItem) return null;

  const children = selectedItem.components();
  const isSingleChildren = children.length === 1;
  const isDoubleChildren = children.length === 2;
  const isButtonChildren = isSingleChildren && children.at(0).get("tagName") === "button";

  if (isButtonChildren) {
    const button = children.at(0);
    return (
      <LinkProperty
        comp={button}
        domRect={boundingClientRect}
        closePopup={handleClosePopup}
        notifyParent={refetchData}
      />
    );
  } else if (isDoubleChildren) {
    const labelComponent = children.find((child) => child.get("type") === "label");
    const otherComponent = children.find((child) => child.get("type") !== "label");
    const isOtherComponentInput = otherComponent.get("tagName") === "input";
    const isOtherComponentTextArea = otherComponent.get("tagName") === "textarea";
    if (labelComponent && otherComponent && (isOtherComponentInput || isOtherComponentTextArea)) {
      return (
        <InputProperty
          comp={selectedItem}
          domRect={boundingClientRect}
          closePopup={handleClosePopup}
          label={`${otherComponent.get("tagName")} Properties`}
          notifyParent={refetchData}
        />
      );
    }
  }

  return null;
};
