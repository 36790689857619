/**
 * Maps special characters to their textual equivalents
 */
const specialCharMap: Record<string, string> = {
  "&": "and",
  "@": "at",
  "#": "number",
  "*": "star",
  "%": "percent",
  "+": "plus",
  "=": "equals",
  "/": "slash",
  "\\": "backslash",
  "?": "question",
  "!": "exclamation",
  ":": "colon",
  ";": "semicolon",
  ",": "comma",
  ".": "dot",
  "'": "apostrophe",
  '"': "quote",
  "|": "pipe",
  ">": "greater-than",
  "<": "less-than",
  "(": "left-parenthesis",
  ")": "right-parenthesis",
  "{": "left-brace",
  "}": "right-brace",
  "[": "left-bracket",
  "]": "right-bracket",
  "-": "-",
  " ": "-",
  "$": "dollar", // NOTE: The '&' character must be enclosed in quotation marks to function correctly. Also, ensure the file is saved without formatting.
  "^": "caret",
  "~": "tilde",
  "`": "backtick",
  _: "_",
  "°": "degrees",
  "²": "squared",
  "³": "cubed",
  "¼": "one-quarter",
  "½": "half",
  "¾": "three-quarters",
  "×": "times",
  "÷": "divided-by",
  "±": "plus-minus",
  "∞": "infinity",
  "≈": "approximately",
  "≠": "not-equal",
  "≤": "less-than-or-equal",
  "≥": "greater-than-or-equal",
  "∆": "delta",
  "∑": "sigma",
  "∏": "pi",
  "√": "square-root",
  "∫": "integral",
  "∂": "partial",
  "∈": "element-of",
  "∉": "not-element-of",
  "∅": "empty-set",
  "∩": "intersection",
  "∪": "union",
  "∴": "therefore",
  "∵": "because",
};

/**
 * Converts a label string into a format suitable for HTML id/for attributes.
 * @param label - The label string to convert.
 * @returns The normalized string.
 */
export function normalizeLabel(label: string): string {
  return label
    .split("") // Split the string into individual characters
    .map((char) => {
      if (specialCharMap[char]) {
        return specialCharMap[char]; // Replace special characters
      } else if (/\s/.test(char)) {
        return "-"; // Replace spaces with dashes
      } else if (/[^a-zA-Z0-9-]/.test(char)) {
        return ""; // Remove any other invalid characters
      }
      return char.toLowerCase(); // Keep alphanumeric characters
    })
    .join("") // Rejoin the string
    .replace(/-+/g, "-"); // Collapse multiple dashes into a single dash
  // .replace(/^-|-$/g, ""); // Trim leading and trailing dashes
}
