import { BlockProperties } from "grapesjs";

export const textArea: BlockProperties = {
  id: "Text area",
  media: `<svg width="46" height="29" viewBox="0 0 46 29" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="45" height="28" rx="3.5" fill="#27272A"/><rect x="0.5" y="0.5" width="45" height="28" rx="3.5" stroke="#71717A"/><line class="light-stroke" x1="4.5" y1="4.5" x2="4.5" y2="9.5" stroke="#D4D4D8" stroke-linecap="round"/><path class="primary-fill" d="M38.2071 26H42.5C42.7761 26 43 25.7761 43 25.5V21.2071C43 20.7617 42.4614 20.5386 42.1464 20.8536L37.8536 25.1464C37.5386 25.4614 37.7617 26 38.2071 26Z" fill="#71717A"/></svg>`,
  label: "Text area",
  category: "Forms",
  content: {
    tag: "div",
    style: { display: "flex", "flex-direction": "column" },
    components: [
      {
        type: "label",
        content: "Name",
      },
      {
        type: "textfield",
      },
    ],
  },
  select: true,
};
