import { useEffect, useState, useRef, useCallback } from "react";
import { styleManagerService } from "../styles-manager/StylesManager.service.ts";

import { TraitManager } from "../trait-manager/TraitManager.tsx";
import { StylesManager } from "../styles-manager/StylesManager.tsx";
import { OptionsDropdown } from "../styles-manager/components/OptionsDropdown/OptionsDropdown.tsx";
import { Appearance } from "../appearance/Appearance.tsx";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Helper function to determine the active tab class
const isActive = (view: number, currentView: number) => (view === currentView ? " right-sidebar__tab--active " : "");

export const RightSideBar = () => {
  const ref = useRef<HTMLUListElement | null>(null);

  // States
  const [view, setView] = useState(0);
  const [stylesManagerState, setStylesManagerState] = useState(styleManagerService.state);

  // Other refs
  const usUnitModalVisible = styleManagerService.unitModalVisible;
  const forcedSwitchViewRef = useRef(false);

  // 1. Subscription to changes in styleManagerService
  useEffect(() => {
    styleManagerService.subscribe(setStylesManagerState);
    return () => {
      styleManagerService.unsubscribe(setStylesManagerState);
    };
  }, []);

  // 2. Scroll handling (closing the dropdown)
  useEffect(() => {
    if (!ref.current) return;
    const handleScroll = () => {
      styleManagerService.closeUnitDropdown();
    };
    ref.current.addEventListener("scroll", handleScroll);
    return () => ref.current?.removeEventListener("scroll", handleScroll);
  }, []);

  // Extracting the component type
  const componentType = stylesManagerState.component?.get("type");

  // 3. Automatically force tab change for type = form | smartNavbar
  useEffect(() => {
    if ((componentType === "form" || componentType === "smartNavbar") && view === 0 && !forcedSwitchViewRef.current) {
      setView(1);
      forcedSwitchViewRef.current = true;
    }
  }, [componentType, view]);

  // 4. Function to switch views (useCallback to avoid creating a new reference each time)
  const handleSwitchView = useCallback(
    (viewas: 0 | 1) => {
      // Reset forcedSwitchViewRef if we are not in form/smartNavbar
      if (componentType !== "form" && componentType !== "smartNavbar") {
        forcedSwitchViewRef.current = false;
      }
      setView(viewas);
    },
    [componentType]
  );

  // 5. Determine if we have appearance
  const isAppearance = componentType === "form";

  // ----
  // NOTE: Only now we check if we have a component to render:
  // ----

  if (!stylesManagerState.component) {
    // Here we RETURN, but ALL hooks above have already been called.
    return <div className="main-section__right-sidebar right-sidebar"></div>;
  }

  // 6. JSX (hooks are already above, so here is only the rendering logic)
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="main-section__right-sidebar right-sidebar">
        <div className="right-sidebar__tabs">
          <button className={"right-sidebar__tab" + isActive(0, view)} onClick={() => handleSwitchView(0)}>
            Styles
          </button>
          <button className={"right-sidebar__tab" + isActive(1, view)} onClick={() => handleSwitchView(1)}>
            Properties
          </button>
        </div>

        {/* View of the "Styles" tab */}
        <div
          style={{
            display: view === 0 ? "grid" : "none",
          }}
          className="styles-wrapper"
        >
          <StylesManager ref={ref} />
        </div>

        {/* View of the "Properties" tab */}
        {view === 1 && (
          <div className="custom-scrollbar" style={{ maxHeight: "100%", overflowY: "auto", overflowX: "clip" }}>
            <TraitManager customScrollbar={false} />
            {isAppearance && <Appearance customScrollbar={false} />}
          </div>
        )}
      </div>
      {usUnitModalVisible && <OptionsDropdown />}
    </DndProvider>
  );
};
