import React, { type SVGProps } from "react";

export const RedWarningTriangle = (props: SVGProps<SVGSVGElement>) => (
    <svg width="48" height="48" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2" y="2.5" width="52" height="52" rx="26" fill="#FEE2E2"/>
    <rect x="2" y="2.5" width="52" height="52" rx="26" stroke="#FEF2F2" stroke-width="4"/>
    <path d="M28 25.5V29.5M28 33.5H28.01M26.2888 20.3592L17.8188 34.4992C17.6442 34.8017 17.5518 35.1445 17.5508 35.4937C17.5498 35.843 17.6403 36.1864 17.8132 36.4898C17.9862 36.7931 18.2355 37.046 18.5365 37.2231C18.8375 37.4002 19.1796 37.4954 19.5288 37.4992H36.4688C36.818 37.4954 37.1601 37.4002 37.4611 37.2231C37.7621 37.046 38.0114 36.7931 38.1844 36.4898C38.3573 36.1864 38.4478 35.843 38.4468 35.4937C38.4458 35.1445 38.3534 34.8017 38.1788 34.4992L29.7088 20.3592C29.5305 20.0653 29.2795 19.8223 28.98 19.6537C28.6805 19.4851 28.3425 19.3965 27.9988 19.3965C27.6551 19.3965 27.3171 19.4851 27.0176 19.6537C26.7181 19.8223 26.4671 20.0653 26.2888 20.3592Z" stroke="#F03838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );


