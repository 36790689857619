import { Check } from "../../../../../../assets/svg/check";
import { MobileConnector } from "../../../../../../assets/svg/mobile_connector";
import { MobileConnectorNotActive } from "../../../../../../assets/svg/mobile_connector_not_active";

import type { TMobileBreadcrumb } from "./MobileBreadcrumb.types";

import "./MobileBreadcrumb.css";

const stages = ["Select Template", "Site plan", "Marketing plan", "Wrap up"];

export const MobileBreadcrumb: TMobileBreadcrumb = ({ stage, handleOnClick }) => {
  return (
    <div className="mobile-breadcrumb__wrapper">
      <div className="mobile-breadcrumb">
        {stages.map((stageName, index) => {
          const isLastStage = index === stages.length - 1;
          const isCurrentStage = stage === index + 1;
          const isDone = stage > index + 1;
          const type = isCurrentStage ? "current" : isDone ? "done" : "future";

          return (
            <>
              {isDone ? (
                <div className="mobile-breadcrumb__check-icon">
                  <Check onClick={() => handleOnClick(index)} />
                </div>
              ) : (
                <div
                  className={`mobile-breadcrumb__typography mobile-breadcrumb__${type}-stage`}
                  onClick={() => handleOnClick(index)}
                >
                  {!isDone && <span className="mobile-breadcrumb__typography-number">{index + 1} </span>}
                  {isCurrentStage ? <span className="mobile-breadcrumb__typography-stage">{stageName}</span> : null}
                </div>
              )}

              {!isLastStage ? (
                stage === index + 2 ? (
                  <div className="mobile-breadcrumb__connector">
                    <MobileConnector />
                  </div>
                ) : (
                  <div className="mobile-breadcrumb__connector">
                    <MobileConnectorNotActive />
                  </div>
                )
              ) : null}
            </>
          );
        })}
      </div>
    </div>
  );
};
