import { Hint } from "../../../../../../assets/svg/hint";
import { Check } from "../../../../../../assets/svg/check";
import { Trash } from "../../../../../../assets/svg/trash";
import { Edit } from "../../../../../../assets/svg/edit";

import type { TCartItem } from "./CartItem.types";

import "./CartItem.css";

export const CartItem: TCartItem = ({
  heading,
  subHeading,
  price,
  scoredOutPrice,
  isSubHeadingGreen,
  billingPeriod,
  tooltip,
  onEdit,
  onDelete,
  metadata,
}) => {
  return (
    <div className="codejet__cart-item">
      <div className="codejet__cart-item__name">
        <h3 className="codejet__typography">{heading}</h3>
        <span className={"codejet__typography" + (isSubHeadingGreen ? " codejet__typography-green" : "")}>
          {subHeading}
        </span>
      </div>
      <div className="codejet__cart-item__right">
        <div className="codejet__typography codejet__cart-item__container">
          <div className="codejet__typography codejet__cart-item__price">
            <strong>${price}</strong>
            {billingPeriod ? `/${billingPeriod}` : ""}
          </div>
          {scoredOutPrice && (
            <div className="codejet__typography codejet__cart-item__old-price">
              <s>${scoredOutPrice}</s>
              {billingPeriod ? `/${billingPeriod}` : ""}
            </div>
          )}
        </div>
        {metadata.name.slice(0, 9) === "Site plan" ? (
          <div className="codejet__cart-icon_wrapper" onClick={onEdit}>
            <Edit className="codejet__cart-icon" />
          </div>
        ) : (
          <button className="codejet__cart-icon_wrapper" onClick={onDelete}>
            <Trash className="codejet__cart-icon" />
          </button>
        )}
        {tooltip && (
          <div className="codejet__cart-item__tooltip">
            <Hint className="codejet__cart-item__hint" />
            {tooltip && (
              <div className="codejet__cart-item__tooltip-content">
                {tooltip.map(({ textBold, textRegular }, index) => {
                  return (
                    <div key={index} className="codejet__typography codejet__cart-item__tooltip-item">
                      <Check className="codejet__cart-item__tooltip-item__icon" />
                      <strong>{textBold} </strong>
                      <span>&nbsp;{textRegular}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
