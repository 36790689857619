import { useState, useEffect } from "react";

function useMobileViewport({ breakPoint = 1232 } = {}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= breakPoint);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1232);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}

export default useMobileViewport;
