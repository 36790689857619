import {
  smartComponentNavbarClass,
  smartComponentNavbarContentClass,
  smartComponentNavbarLogoWrapperClass,
  smartComponentNavbarLogoClass,
  smartComponentNavbarNavClass,
  smartComponentNavbarMenuListClass,
  smartComponentNavbarMenuLinkClass,
  smartComponentNavbarCTAClass,
  smartComponentNavbarCTAContactUSClass,
  smartComponentNavbarCTAContactUSTitleClass,
  smartComponentNavbarCTAContactUSLinkClass,
  smartComponentNavbarMobileOpenClass,
  smartComponentNavbarMobileCloseClass,
  smartComponentNavbarWrapperClass,
  smartComponentNavbarMenuItemClass,
} from "../block-helpers";

export const smartNavbarStyles = `
        .${smartComponentNavbarClass} {
          position: sticky;
          top: 0;
          z-index: 9999;
          width: 100%;
          display: flex;
          justify-content: center;
          background-color: rgba(255, 255, 255, 1);
          border-bottom: 1px solid rgba(0, 0, 0, 1);
        }
        .${smartComponentNavbarContentClass} {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2rem;
          padding: 1rem 1.5rem;
        }
        .${smartComponentNavbarLogoWrapperClass}:empty {
          height: 2.5rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          background-color: #ff4900;
          border-radius: 2px;
          min-width: max-content;
        }
        .${smartComponentNavbarLogoWrapperClass}:empty::before {
          content: "Add content";
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          font-weight: bold;
          font-family:
          -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Open Sans", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
          opacity: 0.5;
        }
        .${smartComponentNavbarLogoClass} {
          display: flex;
        }
        .${smartComponentNavbarNavClass} {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1.5rem;
        }
        .${smartComponentNavbarMenuListClass} {
          display: flex;
          gap: 1.5rem;
          list-style: none;
          padding: 0;
        }
        .${smartComponentNavbarMenuLinkClass} {
          font-family: "Arial";
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 700;
          color: #18181b;
          text-decoration: none;
        }
        .${smartComponentNavbarCTAContactUSClass} {
          display: flex;
          flex-direction: column;
        }
        .${smartComponentNavbarCTAClass} {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        .${smartComponentNavbarCTAClass}:empty {
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0.5rem;
          background-color: #ff4900;
          border-radius: 2px;
        }
        .${smartComponentNavbarCTAClass}:empty::before {
          content: "Add content";
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          font-weight: bold;
          font-family:
          -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Open Sans", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
          opacity: 0.5;
        }
        .${smartComponentNavbarCTAContactUSTitleClass} {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-family: "Arial;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 700;
          color: #09090B;
        }
        .${smartComponentNavbarCTAContactUSLinkClass} {
          font-family: "Arial;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: #09090B;
          text-decoration: none;
        }
        .${smartComponentNavbarMobileOpenClass} {
          color: #18181b;
          display: none;
          border: none;
          background-color: transparent;
          padding: 0.5rem;
        }
        .${smartComponentNavbarMobileCloseClass} {
          color: #18181b;
          display: none;
          border: none;
          background-color: transparent;
          padding: 0.5rem;
        }

        @media (max-width: 768px) {
          .${smartComponentNavbarContentClass} {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
            padding: 0;
          }
          .${smartComponentNavbarClass}.active .${smartComponentNavbarContentClass} {
            height: 100svh;
          }
          .${smartComponentNavbarClass}.active .${smartComponentNavbarMobileOpenClass} {
            display: none;
          }
          .${smartComponentNavbarWrapperClass} {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1.5rem;
          }
          .${smartComponentNavbarNavClass} {
            display: none;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            border-top: 1px solid rgba(0, 0, 0, 1);
            height: 100%;
          }
          .${smartComponentNavbarMenuListClass} {
            flex-direction: column;
            gap: 0;
          }
          .${smartComponentNavbarMenuItemClass} {
            display: flex;
          }
          .${smartComponentNavbarNavClass}.active {
            display: flex;
          }
          .${smartComponentNavbarMobileOpenClass} {
            display: block;
          }
          .${smartComponentNavbarMobileOpenClass}.hidden {
            display: none;
          }
          .${smartComponentNavbarMobileCloseClass} {
            display: none;
          }
          .${smartComponentNavbarMobileCloseClass}.show {
            display: block;
          }
          .${smartComponentNavbarMenuLinkClass} {
            width: 100%;
            font-size: 1.5rem;
            line-height: 2rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 1);
          }
          .${smartComponentNavbarCTAClass}  {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
        }
      `;
