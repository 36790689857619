import { fixedComponentDefinition } from "../block-helpers";

import type { CSSStyle } from "../../../../../../../types";
import type { ComponentDefinition } from "grapesjs";

type CreateTextareaComponentAttrs = {
  id: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  styles?: CSSStyle;
  attributes?: ComponentDefinition["attributes"];
};

type CreateTextareaComponent = (attrs: CreateTextareaComponentAttrs) => ComponentDefinition;

/**
 * Creates a textarea component configuration for rendering.
 *
 * @param id - The unique identifier for the textarea component.
 * @param name - The name attribute for the textarea component.
 * @param placeholder - The placeholder text displayed inside the textarea. Defaults to an empty string.
 * @param required - Specifies whether the textarea is required. Defaults to `false`.
 * @param styles - Additional CSS styles to apply to the textarea component.
 * @param attributes - Additional HTML attributes to apply to the textarea component.
 * @returns The configuration object for the textarea component.
 */
export const createTextareaComponent: CreateTextareaComponent = ({
  id,
  name,
  placeholder = "",
  required = false,
  styles = {},
  attributes = {},
}) => {
  return {
    type: "textfield",
    ...fixedComponentDefinition,
    style: {
      ...styles,
    },
    attributes: {
      id,
      name,
      placeholder,
      ...(required ? { required: "true" } : {}),
      ...attributes,
    },
  };
};
