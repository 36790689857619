import { useEffect } from "react";
import { toast } from "react-toastify";

import { Figma } from "../../../services/Figma";

import { Seo } from "../../atoms/Seo";
import { ButtonNew } from "../../atoms/ButtonNew";
import { WeirdIconWrapper } from "../../atoms/WeirdIconWrapper";

import { Logotype } from "../../../../assets/svg/logotype";
import { CodejetIconNew } from "../../../../assets/svg/codejet_icon_new";
import { FigmaLogo } from "../../../../assets/svg/figma_logo";
import { GoogleLogo } from "../../../../assets/svg/google_logo";

import type { TLogin } from "./Login.types";

import "./Login.css";

let counter = 0;

export const Login: TLogin = ({ toastMsg }) => {
  useEffect(() => {
    if (toastMsg !== undefined && counter === 0) toast.info(toastMsg);
    counter++;
  }, []);
  return (
    <>
      <Seo title="Get started - Codejet" />
      <div className="login">
        <div className="login__wrapper">
          <Logotype />
          <div className="login__content">
            <div className="login__text-block">
              <h1 className="login__header">Login</h1>
              <p className="login__description">Continue to Codejet</p>
            </div>
            <div className="login__button-group">
              <ButtonNew
                className="login__content-button"
                iconStart={<GoogleLogo />}
                href={"api/auth/google/login"}
                target="_self"
              >
                Login with Google
              </ButtonNew>
              <div className="login__signin-separator">
                <div className="login__signin-separator__line"></div>
                <div className="codejet__typography login__separator__or">or</div>
                <div className="login__signin-separator__line"></div>
              </div>
              <ButtonNew
                className="login__content-button"
                iconStart={<FigmaLogo />}
                href={Figma.getCallbackUrl()}
                target="_self"
              >
                Login with Figma
              </ButtonNew>
            </div>
          </div>
          <div className="login__footer">
            <p className="login__footer-text">{"New to Codejet?"}</p>
            <ButtonNew type="text" className="login__footer-link" orange href="https://www.figma.com/signup?locale=en">
              Get Started
            </ButtonNew>
          </div>
        </div>
      </div>
    </>
  );
};
