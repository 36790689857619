import { useRef, useState } from "react";
import { styleManagerService } from "../../../../styles-manager/StylesManager.service";
import { SelectButton } from "../../../../styles-manager/components/SelectButton";

export interface Option {
  id: string;
  value: string;
  label: string;
  name?: string;
  title?: string;
}

interface SelectProps {
  options: Option[];
  className?: string;
  label?: string;
  placeholder?: string;
  onOptionSelect?: (selectedOption: Option) => void;
}

export const PropertySelect = ({ options, className, label, placeholder, onOptionSelect }: SelectProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string | undefined>(undefined);
  const classes = ["trait-manager__trait", className].filter(Boolean).join(" ");

  const hasOptions = options && options.length > 0;

  const handleChange = (newValue: string) => {
    if (value !== newValue) {
      setValue(newValue);
      const selectedOption = options.find((opt) => opt.value === newValue);
      if (selectedOption && onOptionSelect) {
        onOptionSelect(selectedOption);
      }
    }
  };

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    if (hasOptions) {
      const target = ref.current?.querySelector(".select-button") || ref.current;
      const coordinates = target?.getBoundingClientRect();
      if (!coordinates) return;

      styleManagerService.openUnitDropdown(options, value, coordinates, handleChange);
    }
  };

  const currentOption = options.find((opt) => opt.value === value);

  return (
    <div className={classes} ref={ref} style={{ marginBottom: "0.5rem" }}>
      {label && <label className="trait-manager__trait-label">{label}</label>}
      <SelectButton
        placeholder={placeholder}
        currentOption={currentOption?.value || ""}
        options={options}
        onClick={handleUnitDropdown}
      />
    </div>
  );
};
