import { useState, useEffect } from "react";

import { routerInstance } from "../../../../machines/router/router.instance";

import { Button } from "../../atoms/Button";

import { dashboardInstance } from "../../../../machines/dashboard/dashboard.instance";
import { editorTemplatesInstance, editorService } from "../../../pages/Alpha_O_templates/modules/editor";
import { pagesService } from "../../../pages/Alpha_O/modules/pages/pages.service";
import { UserService } from "../../../../services/UserService/UserService";
import { templatesService } from "../../../../machines/templates/templates.instance";
import { projectsInstance } from "../../../../machines/projects/projects.instance";
import { modalMachineInstance } from "../../../pages/Alpha_O/modules/modal";

import { CodejetRocket } from "../../../../assets/svg/CodejetRocket";
import { Dashboard } from "../../../../assets/svg/Dashboard";
import { EyeSolid } from "../../../../assets/svg/EyeSolid";
import { SizeLaptop } from "../../../../assets/svg/SizeLaptop";
import { SizeTablet } from "../../../../assets/svg/SizeTablet";
import { SizeMobile } from "../../../../assets/svg/SizeMobile";

import { TTopBarTEmplates } from "./TopBarTemplates.types";

import "../TopBar/TopBar.css";

const userService = UserService.getInstance();

export const TopBarTemplates: TTopBarTEmplates = ({ isAuthenticated, toggleAddProjectModal }) => {
  const [_, updatePageManagerState] = useState(pagesService.state);
  const editor = editorService.getEditor();
  const device = editor?.getDevice();
  const hasUserData = userService.hasUserData();
  const isAddProjectButtonVisible = routerInstance.getSnapshot().value !== "ALPHA_O_TEMPLATES";

  useEffect(() => {
    pagesService.subscribe((state) => updatePageManagerState(state));
    return () => {
      pagesService.unsubscribe(updatePageManagerState);
    };
  }, []);

  const handleEditOrAddProject = async () => {
    if (!hasUserData) {
      routerInstance.send("ALPHA_O");
      return;
    }

    modalMachineInstance.send("OPEN", { modalId: "create-project" });

    const activeProject = templatesService.getActiveProject();
    const projectId = activeProject?.projectId;

    toggleAddProjectModal();
    if (!projectId) {
      console.error("No active project");
      return;
    }

    try {
      const newProject = await templatesService.duplicateTemplate(projectId);

      if (newProject?.projectId) {
        projectsInstance.send("SELECT_PROJECT", { projectId: newProject.projectId });
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleAddProjectModal();
    }
  };

  const handleDeviceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const deviceType = e.target.value;
    editorTemplatesInstance.send("CHANGE_DEVICE_TYPE", { deviceType });
  };
  const handleMovetoDashboard = () => {
    if (isAuthenticated === false) {
      routerInstance.send("PICK_TEMPLATE");
    } else {
      dashboardInstance.send("TEMPLATES");
    }
  };

  return (
    <div className="top-bar">
      <div className="top-bar__left-column">
        <button key="save" onClick={() => {}} title="Codejet Logo">
          <CodejetRocket />
        </button>
      </div>
      <div className="top-bar__right-column">
        <div>
          <Button type="secondary-small" truncate iconStart={<Dashboard />} onClick={handleMovetoDashboard}>
            Return
          </Button>
        </div>
        <div className="top-bar__device-pick-wrapper">
          <div className="top-bar__device-pick">
            <input
              id="device-desktop"
              type="radio"
              name="device"
              value="Desktop"
              onChange={handleDeviceChange}
              checked={device === "Desktop"}
              className="vs-hidden"
            />
            <label htmlFor="device-desktop">
              <SizeLaptop />
              <span className="top-bar__device-name">Desktop</span>
            </label>
            <input
              id="device-tablet"
              type="radio"
              name="device"
              value="Tablet"
              onChange={handleDeviceChange}
              checked={device === "Tablet"}
              className="vs-hidden"
            />
            <label htmlFor="device-tablet">
              <SizeTablet />
              <span className="top-bar__device-name">Tablet</span>
            </label>
            <input
              id="device-mobile"
              type="radio"
              name="device"
              value="Mobile"
              onChange={handleDeviceChange}
              checked={device === "Mobile"}
              className="vs-hidden"
            />
            <label htmlFor="device-mobile">
              <SizeMobile />
              <span className="top-bar__device-name">Mobile</span>
            </label>
          </div>
        </div>
        {isAddProjectButtonVisible && (
          <div className="top-bar__cta-wrapper">
            <Button type="secondary" size="small" truncate onClick={handleEditOrAddProject}>
              {hasUserData ? "Add project" : "Click to edit"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
