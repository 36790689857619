import React, { type SVGProps } from "react";

export const Email = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="48" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2" y="2.5" width="52" height="52" rx="26" fill="#FFEBD3"/>
    <rect x="2" y="2.5" width="52" height="52" rx="26" stroke="#FFF6EC" stroke-width="4"/>
    <path d="M38 22.5C38 21.4 37.1 20.5 36 20.5H20C18.9 20.5 18 21.4 18 22.5M38 22.5V34.5C38 35.6 37.1 36.5 36 36.5H20C18.9 36.5 18 35.6 18 34.5V22.5M38 22.5L28 29.5L18 22.5" stroke="#FF4900" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> 
   
);



