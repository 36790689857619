import { BlockProperties } from "grapesjs";

export const select: BlockProperties = {
  id: "Select",
  media: `<svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V14C39.5 14.8284 38.8284 15.5 38 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" fill="#27272A"/><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V14C39.5 14.8284 38.8284 15.5 38 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" stroke="#71717A"/><path class="light-stroke" d="M8 8H22" stroke="#D4D4D8" stroke-linecap="round"/><path d="M29 7L31 9L33 7" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  label: "Select",
  category: "Forms",
  content: {
    type: "select",
    components: [
      {
        type: "option",
        tagName: "option",
        content: "Option 1",
        attributes: { value: "option1" },
      },
      {
        type: "option",
        tagName: "option",
        content: "Option 2",
        attributes: { value: "option2" },
      },
    ],
  },
  select: true,
};
