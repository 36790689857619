export const formToastStyle = `\n.swal2-own-container {
    grid-template-columns: auto 1fr auto;
    align-items: start;
    gap: 1rem;
    background-color: #12B76A;
    padding: 1rem;
    border-radius: 0.75rem;
  }
  .swal2-own-icon {
    width: 32px;
    height: 32px;
    margin: 0;
    border: none;
  }
  .swal2-own-title {
    align-self: center;
    display: block-inline;
    width: auto;
    font-family: "Arial", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
  }
  .swal2-own-close-button {
    order: 4;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;
  }`;
