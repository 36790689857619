import { BlockProperties } from "grapesjs";

export const heading: BlockProperties = {
  id: "heading",
  media: `<svg width="44" height="37" viewBox="0 0 44 37" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_23294_2269)"><path d="M0.833313 20H42.8333M0.833313 28H42.8333M0.833313 36H21.8333" stroke="#71717A" stroke-linecap="round"/><path class="light-fill" d="M1.32056 12V0.363647H2.72965V5.54547H8.93419V0.363647H10.3433V12H8.93419V6.79547H2.72965V12H1.32056Z" fill="#D4D4D8"/></g><defs><clipPath id="clip0_23294_2269"><rect width="43" height="37" fill="white" transform="translate(0.333313)"/></clipPath></defs></svg>
    `,
  label: "Heading",
  category: "Layout",
  content: {
    type: "text",
    tagName: "h2",
    content: "Insert your title here",
  },
  select: true,
};
