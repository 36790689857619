import { useEffect, useState } from "react";

import { ConnectFigmaModalService } from "./ConnectFigmaModal.service";
import useOutsideClick from "../../../hooks/useOutsideClick/useOutsideClick";

import { ButtonNew } from "../../atoms/ButtonNew";
import { Header } from "../../atoms/Header";

import { TConnectFigmaModal } from "./ConnectFigmaModal.types";

import { FigmaLogo } from "../../../../assets/svg/figma_logo";

import "./ConnectFigmaModal.css";

const connectFigmaModalService = ConnectFigmaModalService.getInstance();

export const ConnectFigmaModal: TConnectFigmaModal = () => {
  const [_, setState] = useState(false);
  const isOpen = connectFigmaModalService.state;
  const closeModal = () => connectFigmaModalService.closeModal();
  const ref = useOutsideClick<HTMLDivElement>(closeModal);

  useEffect(() => {
    connectFigmaModalService.subscribe(setState);
    return () => connectFigmaModalService.unsubscribe(setState);
  }, []);

  return (
    <div className="connect-figma-modal__wrapper">
      <div className={"connect-figma-modal__backdrop" + (isOpen ? " connect-figma-modal__backdrop--visible" : "")}>
        <div className={"connect-figma-modal" + (isOpen ? " connect-figma-modal__visible" : "")} ref={ref}>
          <Header headingText="Your figma accounted is not connected" />
          <ButtonNew
            iconStart={<FigmaLogo />}
            className="connect-figma-modal__skip-button"
            href="api/figma/get-id"
            target="_self"
          >
            Connect To Figma
          </ButtonNew>
        </div>
      </div>
    </div>
  );
};
