import { modalMachineInstance } from "../..";

import { ModalHeader } from "../../partials/ModalHeader";
import { ModalFooter } from "../../partials/ModalFooter";

import "./SmartComponentModal.css";

export const SmartComponentModal = () => {
  const handleCancel = () => {
    modalMachineInstance.send("CLOSE");
  };
  return (
    <div className="smart-component-modal new-colors">
      <ModalHeader
        title={"Edit added component"}
        description={"Use the right panel to edit the elements of your\nadded component and adjust its appearance"}
        type="info"
        position="start"
        iconPosition="center"
      />
      <ModalFooter onCancel={handleCancel} />
    </div>
  );
};
