import { useEffect, useState } from "react";

import { unauthorizedTemplatesInstance } from "../../../machines/unauthorized-templates/unauthorized-templates.instance";
import { projectsInstance, projectsService } from "../../../machines/projects/projects.instance";
import { templatesService } from "../../../machines/templates/templates.instance";
import { UserService } from "../../../services/UserService/UserService";

import { OnboardingLayout } from "../../molecules/OnboardingLayout";
import { NewTemplateCard } from "../../atoms/NewTemplateCard";
import { PageHeader } from "../../atoms/PageHeader";
import { TemplateCard } from "../../Dashboard/atoms/TemplateCard";
import { Modal } from "../../Dashboard/atoms/ProjectCard/components/Modal";

import useMobileViewport from "../../../hooks/useMobileViewport/useMobileViewport";
import { useTemplates } from "../../../hooks/useTemplates/useTemplates";

const categories = [
  "All",
  "Services",
  "Fitness",
  "Creators",
  "Healthcare",
  "Education",
  "Restaurant",
  "Travel",
  "Nonprofits",
];

const userService = UserService.getInstance();

export const PickTemplate = () => {
  const isMobile = useMobileViewport();
  // const [selectedCategory, setSelectedCategory] = useState<string>("All");
  // const [displayedTemplates, setDisplayedTemplates] = useState(fecthedTemplates);
  const [_, subscribeUserService] = useState(userService.state);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const templates = useTemplates(setIsSkeleton);
  const hasUserData = !!userService.hasUserData();

  // useEffect(() => {
  //   if (selectedCategory === "All") {
  //     setDisplayedTemplates(fecthedTemplates);
  //   } else {
  //     const selectedTemplates = fecthedTemplates.filter((template) => template.category === selectedCategory);

  //     setDisplayedTemplates(selectedTemplates);
  //   }
  // }, [selectedCategory]);

  useEffect(() => {
    userService.subscribe(subscribeUserService);

    return () => {
      userService.unsubscribe(subscribeUserService);
    };
  }, []);

  const pickTemplate = async (projectId: string) => {
    const hasUser = userService.getData()?.USER_ID;
    if (!hasUser) {
      unauthorizedTemplatesInstance.send({ type: "SELECT_TEMPLATE", payload: { projectId } });
      return;
    }

    setIsDuplicate(true);

    try {
      const newProject = await templatesService.duplicateTemplate(projectId);

      if (newProject?.projectId) {
        projectsInstance.send("SELECT_PROJECT", { projectId: newProject.projectId });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDuplicate(false);
    }
  };

  const onPreviewHandler = (projectId: string) => {
    if (hasUserData) {
      templatesService.setActiveProject(projectId);
    }
    unauthorizedTemplatesInstance.send({ type: "PREVIEW_TEMPLATE", payload: { projectId } });
  };

  return (
    <>
      <OnboardingLayout
        seoTitle="My templates - Codejet"
        header="Pick a template"
        subheader="Get started quicker with prebuilt templates"
        isBackButtonVisible={false}
        isTotalSectionVisible={false}
      >
        <div className="unauthorized-templates__wrapper">
          {/* <div className="unauthorized-templates__categories">
            {categories.map((category: string) => {
              const isSelected = category === selectedCategory ? true : false;
              const isActive = isSelected ? " active" : "";

              return (
                <ButtonNew
                  key={category}
                  className={"unauthorized-templates__category-button" + isActive}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </ButtonNew>
              );
            })}
        </div> */}
          {isMobile && <PageHeader header="Pick a template" subheader="Get started quicker with prebuilt templates" />}
          <div className="unauthorized-templates__templates">
            {isSkeleton
              ? Array.from({ length: 4 }).map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TemplateCard key={index} skeleton style={{ animationDelay: `calc(${index} * 250ms)` }} />
                ))
              : templates.map(({ projectId, thumbnail, name }) => (
                  <NewTemplateCard
                    key={projectId}
                    name={name}
                    thumbnail={thumbnail}
                    onUseTemplate={() => pickTemplate(projectId)}
                    onPreview={() => onPreviewHandler(projectId)}
                    hoverButtons={false}
                    hasUserData={hasUserData}
                  />
                ))}

            {isDuplicate && (
              <Modal
                onClose={() => setIsDuplicate(false)}
                onClick={() => setIsDuplicate(false)}
                isLoading={true}
                loaderText="We are adding your project..."
              />
            )}
          </div>
        </div>
      </OnboardingLayout>
    </>
  );
};
