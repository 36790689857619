import { useEffect, useState } from "react";

import { pageActionsServiceInstance } from "./PageActionsModal.service";
import { pagesInstance } from "../../../pages/Alpha_O/modules/pages/pages.instance";
import { UserService } from "../../../../services/UserService/UserService";
import { TriggerService } from "../../../../services/TriggerService/TriggerService";

import { Button } from "../../atoms/Button/Button";
import { Rename } from "../../../../assets/svg/Rename";
import { Delete } from "../../../../assets/svg/Delete";
import { Duplicate } from "../../../../assets/svg/Duplicate";

import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";
import { usePositionInScreen } from "../../../../hooks/usePositionInScreen/usePositionInScreen";

import { TPageActionsModal } from "./PageActionsModal.types";

import "./PageActionsModal.css";

const userService = UserService.getInstance();
const triggerService = TriggerService.getInstance();

export const PageActionsModal: TPageActionsModal = ({ duplicatePage, deletePage }) => {
  const [pageActionsService, subscribePageActionsService] = useState(pageActionsServiceInstance.state);
  const [isPositionCalculated, setIsPositionCalculated] = useState(false);
  const [machineState, setMachineState] = useState(pagesInstance.getSnapshot().value);
  const { isOpen, setIsRenameInputVisible, page, position } = pageActionsService;
  const ref = useOutsideClick<HTMLDivElement>(() => {
    pageActionsServiceInstance.closeModal();
    setIsPositionCalculated(false);
  });
  const pageId = page?.getId();

  const { coordinates } = usePositionInScreen(ref, position, setIsPositionCalculated, 16);

  useEffect(() => {
    pageActionsServiceInstance.subscribe(subscribePageActionsService);

    pagesInstance.onTransition((state) => {
      setMachineState(state.value);
    });

    return () => pageActionsServiceInstance.unsubscribe(subscribePageActionsService);
  }, []);

  const handleDuplicatePage = () => {
    if (machineState === "DUPLICATE_PAGE") return;
    duplicatePage(pageId);
    pageActionsServiceInstance.closeModal();
  };

  const handleDeletePage = () => {
    if (machineState === "DELETE_PAGE") return;
    deletePage(pageId);
    pageActionsServiceInstance.closeModal();
  };

  if (!isOpen) return null;

  return (
    <div
      className={`pages-menu__actions-modal`}
      style={{
        ...coordinates,
        opacity: isPositionCalculated ? 1 : 0,
      }}
      ref={ref}
    >
      <Button
        type="ternary"
        iconStart={<Rename />}
        onClick={() => {
          if (!userService.hasUserData()) {
            triggerService.increment("addPage");
            return;
          }
          pageActionsServiceInstance.closeModal();
          setIsRenameInputVisible && setIsRenameInputVisible(true);
        }}
      >
        Rename
      </Button>
      <Button type="ternary" iconStart={<Duplicate />} onClick={handleDuplicatePage}>
        {machineState === "DUPLICATE_PAGE" ? "Duplicating..." : "Duplicate"}
      </Button>
      <Button type="ternary" onClick={handleDeletePage} iconStart={<Delete />}>
        Delete
      </Button>
    </div>
  );
};
